import axios from '../util/axios.js';
import AuthHeader from './authHeader.js';

const createNewBlog = data => {
  return axios.post ('/api/blogs/create-new-blog', data, {
    headers: AuthHeader (),
  });
};

const getAllBlogs = () => {
  return axios.get ('/api/blogs/get-all-blogs', {
    headers: AuthHeader (),
  });
};

const getUserBlogs = userId => {
  return axios.get ('/api/blogs/get-user-blogs/' + userId, {
    headers: AuthHeader (),
  });
};

const getSingleBlog = id => {
  return axios.get ('/api/blogs/get-single-blog/' + id, {
    headers: AuthHeader (),
  });
};

const updateUserBlog = data => {
  return axios.post ('/api/blogs/update-user-blog', data, {
    headers: AuthHeader (),
  });
};

const deleteUserBlog = data => {
  return axios.post ('/api/blogs/delete-user-blog', data, {
    headers: AuthHeader (),
  });
};

const BlogServices = {
  createNewBlog,
  getAllBlogs,
  getSingleBlog,
  updateUserBlog,
  deleteUserBlog,
  getUserBlogs,
};

export default BlogServices;
