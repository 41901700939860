import { createAsyncThunk } from '@reduxjs/toolkit'
import AuthServices from '../../services/auth.services';
import { getErrorMessage } from '../../util/GetError';

type registerType = {
    username:string,
    email:string,
    password:string
}

type loginType = {
    email:string,
    password:string
}

type loginGoogleType = {
 token:string
}

export const registerUser = createAsyncThunk(
  'auth/register',
  async ({username,email,password}:registerType, { rejectWithValue }) => {
    try {
        await AuthServices.register({username,email,password})
    } catch (error) {
        return rejectWithValue(getErrorMessage(error));
    }
  }
)

export const userLogin = createAsyncThunk(
    'auth/login',
    async ({ email, password }:loginType, { rejectWithValue }) => {
      try {
        console.log(email,password);
        const {data} = await AuthServices.login({email,password});
        console.log(data);
        // store user's token in local storage
        localStorage.setItem('userToken', data.token)
        return data
      } catch (error) {
        return rejectWithValue(getErrorMessage(error));
      }
    }
  )

  // export const userLoginWithToken = createAsyncThunk(
  //   'auth/loginWithNormalToken',
  //   async (token:string, { rejectWithValue }) => {
  //     try {
  //       const {data} = await AuthServices.getDetailsWithToken({token});
  //       // console.log(data);
  //       // store user's token in local storage
  //       return data
  //     } catch (error) {
  //       return rejectWithValue(getErrorMessage(error));
  //     }
  //   }
  // )

  

  export const userGoogleLoginWithToken = createAsyncThunk(
    'auth/loginWithToken',
    async ({ token }:loginGoogleType, { rejectWithValue }) => {
      try {
        const {data} = await AuthServices.loginWithToken({token});
        // console.log(data);
        // store user's token in local storage
        if(data){
          localStorage.setItem('userToken', token)
        }
        return data
      } catch (error) {
        return rejectWithValue(getErrorMessage(error));
      }
    }
  )