export const currencyArr = [
  {
    currency: 'INR',
    name: 'Indian Rupees',
    symbol: '₹',
  },
  {
    currency: 'USD',
    name: 'U.S Dollars',
    symbol: '$',
  },
  {
    currency: 'CAD',
    name: 'Canadian Dollars',
    symbol: 'CA$',
  },
  {
    currency: 'Euro',
    name: 'Euro',
    symbol: '€',
  },
  {
    currency: 'Pound',
    name: 'Pound',
    symbol: '£',
  },
];
