import { useState } from 'react';
import styles from './Landing.module.css';
import {Link} from 'react-router-dom';
import Navbar from '../../components/Navbar/Navbar';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import ContactUs from '../../components/ContactUs/ContactUs';
import Footer from '../../components/Footer/Footer';
import mentorshipIcon from '../../assets/calendar.png';
import mvpIcon from '../../assets/mvp.png';
import careerIcon from '../../assets/career.png';
import studyIcon from '../../assets/study.png';
import tutorIcon from '../../assets/tutor.png';
import resourceIcon from '../../assets/search.png';
import guideIcon from '../../assets/guide.png';
import workIcon from '../../assets/work.png';
import {useAppSelector } from '../../hooks';


function Landing() {
  const [colorChange, setColorchange] = useState(false);
  const {userInfo} = useAppSelector((state)=>state.auth);

  const changeNavbarColor = () =>{
     if(window.scrollY >= 80){
       setColorchange(true);
     }
     else{
       setColorchange(false);
     }
  };
  window.addEventListener('scroll', changeNavbarColor);
  // console.log(userInfo);
  return (
    <>
    <Navbar bgColor={colorChange} textColor={"white"} />
    <div className={styles.landing__main}>
        <div className={styles.landing__main__left}>
          <h1>Find your mentor through Coderscolab!</h1>
          <small>Master your craft with leading mentors at your side. Grow with every online mentoring session and take the next step in your career. </small>
         <div className={styles.btn__wrapper}>
         <Link to="#explore" className="primaryBtn">Explore</Link>
         {userInfo ? null :  <Link to="/login" className="secondaryBtn">Login</Link>}
         </div>
        </div>
       
    </div>
    <div className={styles.joinSection}>
       <div className={styles.joinSection__header}>
       <h1>Join <span className="primaryText">CodersColab</span>, where the best coders collaborate.</h1>
        <p>Connect with top developers and build amazing projects together.</p> 
      </div>
      <div className={styles.joinSection__card}>
         <div className={styles.joinSection__cardInfo}>
          <h3 className='primaryText'>1. Find Expert Mentors</h3>
          <h2>
            Guided Growth: Empowering Through<br/> 1:1 Mentorship
          </h2>
          <p>
          Unlock your potential, accelerate your growth, and embark on a transformative journey with our experienced mentors by your side. 
          Together, we will empower you to reach new heights and make your dreams a reality.
            </p>
         </div>
         <img src="https://cdn.mentorcruise.com/img/home/hero/homehero_2x.webp" alt='img' />
      </div>

      <div className={styles.joinSection__card}>
      <img src="https://cdn.mentorcruise.com/img/home/hero/homehero_2x.webp" alt='img' />
         <div className={styles.joinSection__cardInfo}>
          <h3 className='primaryText'>2. Get Career Guidance</h3>
          <h2>
          PathFinder: Navigating Your Career Journey
          </h2>
          <p>
          Whether you're a recent graduate, considering a career change, or looking to advance within your current field, our experienced career advisors are here to provide guidance and support every step of the way.
            </p>
         </div>
         
      </div>

      <div className={styles.joinSection__card}>
         <div className={styles.joinSection__cardInfo}>
          <h3 className='primaryText'>3. Get Your Projects Completed</h3>
          <h2>
            Stuck in your project? <br/>
            Find your mentor to guide you!
          </h2>
          <p>
            Stop Searching solutions online 
            when you have access to expert developer network! Whether you have a startup concept, a business expansion plan, or a creative project in mind, our team of experienced professionals is here to provide comprehensive support throughout the entire development process.
            </p>
         </div>
         <img src="https://cdn.mentorcruise.com/img/home/hero/homehero_2x.webp" alt='img' />
      </div>

      <Link to="/register" className="primaryBtn"
       style={{marginLeft:'auto',marginRight:'auto',display:'block',textAlign:'center',width:'fit-content'}}>Join Coderscolab</Link>
    
    </div>


   
    <div className={styles.careerCoach}>
      <div className={styles.careerCoach__header}>
          <h1>At your <span className="primaryText">Fingertips</span>: a dedicated career <span className='primaryText'>coach</span>.</h1>
          <p>Want to ace your next job interview? Successfully build your startup? Itching to learn high-demand skills? Work smart with an online mentor or coach by your side to offer expert advice and guidance to match your zeal</p>
      </div>
     
    
      <div className={styles.careerCoach__cardWrapper} >
          <div className={styles.careerCoach__card}>
          <svg width={0} height={0}>
            <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
              <stop offset={0} stopColor="var(--primary)" />
              <stop offset={1} stopColor=" var(--secondary)" />
            </linearGradient>
          </svg>
          <PersonOutlineIcon className={styles.careerCoach__cardIcon} sx={{ fill: "url(#linearColors)" }}/>
          <div className={styles.careerCoach__cardBody}>
          <h3>Find Your Mentor</h3>
          <p>Explore our growing catalogue of experienced mentors until you find the perfect fit.</p>
          </div>
          </div>
          <div className={styles.careerCoach__card}>
          <svg width={0} height={0}>
            <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
              <stop offset={0} stopColor="var(--primary)" />
              <stop offset={1} stopColor=" var(--secondary)" />
            </linearGradient>
          </svg>
          <PendingActionsIcon className={styles.careerCoach__cardIcon} sx={{ fill: "url(#linearColors)" }}/>
          <div className={styles.careerCoach__cardBody}>
          <h3>Apply for Mentorship</h3>
          <p>Fill in an application, and if you're a match with your chosen mentor you can start your free 7-day trial.</p>
          </div>
          </div>
          <div className={styles.careerCoach__card}>
          <svg width={0} height={0}>
            <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
              <stop offset={0} stopColor="var(--primary)" />
              <stop offset={1} stopColor=" var(--secondary)" />
            </linearGradient>
          </svg>
          <RocketLaunchIcon className={styles.careerCoach__cardIcon} sx={{ fill: "url(#linearColors)" }}/>
          <div className={styles.careerCoach__cardBody}>
          <h3>Level up your skills</h3>
          <p>From personal chats to hands-on support, each mentor offers different services to help you skyrocket your career.</p>
          </div>
          </div>
      </div>

      <Link to="/register" className="primaryBtn"
       style={{marginLeft:'auto',marginRight:'auto',display:'block',textAlign:'center',width:'fit-content'}}>Join Coderscolab</Link>
    

    </div>

    <div className={styles.services}>
        <div className={styles.services__heading}>
          <h1 className={styles.services__orange}>Coderscolab</h1>
          <h1 className={styles.services__white}>For</h1>
          <h1 className={styles.services__text}>You</h1>
        </div>
        <div className={styles.services__cardWrapper}>
            <div className={styles.service__card}>
                <div className={styles.service__cardIcon}>
                    <img src={mentorshipIcon} alt="mentorship" />
                </div>
                <div className={styles.service__cardText}>
                    <h3>Mentorship</h3>
                 </div> 
            </div>
            <div className={styles.service__card}>
                <div className={styles.service__cardIcon} style={{backgroundColor:'limegreen'}}>
                    <img src={mvpIcon} alt="mentorship" />
                </div>
                <div className={styles.service__cardText}>
                    <h3>MVP Discussion</h3>
                 </div> 
            </div>


            <div className={styles.service__card}>
                <div className={styles.service__cardIcon} style={{backgroundColor:'yellow'}}>
                    <img src={careerIcon} alt="career" />
                </div>
                <div className={styles.service__cardText}>
                    <h3>Career Guidance</h3>
                 </div> 
            </div>

            <div className={styles.service__card}>
                <div className={styles.service__cardIcon} style={{backgroundColor:'pink'}}>
                    <img src={studyIcon} alt="career" />
                </div>
                <div className={styles.service__cardText}>
                    <h3>Plan Study</h3>
                 </div> 
            </div>

            <div className={styles.service__card}>
                <div className={styles.service__cardIcon} style={{backgroundColor:'orange'}}>
                    <img src={tutorIcon} alt="mvp" />
                </div>
                <div className={styles.service__cardText}>
                    <h3>Online Tutoring</h3>
                 </div> 
            </div>

            <div className={styles.service__card}>
                <div className={styles.service__cardIcon} style={{backgroundColor:'lightcoral'}}>
                    <img src={resourceIcon} alt="mvp" />
                </div>
                <div className={styles.service__cardText}>
                    <h3>Resources Guidance</h3>
                 </div> 
            </div>

            <div className={styles.service__card}>
                <div className={styles.service__cardIcon} style={{backgroundColor:'pink'}}>
                    <img src={guideIcon} alt="mvp" />
                </div>
                <div className={styles.service__cardText}>
                    <h3>Daily Guide</h3>
                 </div> 
            </div>

            <div className={styles.service__card}>
                <div className={styles.service__cardIcon} style={{backgroundColor:'lightskyblue'}}>
                    <img src={workIcon} alt="mvp" />
                </div>
                <div className={styles.service__cardText}>
                    <h3>Work Support</h3>
                 </div> 
            </div>
        </div>
    </div>


    <div className={styles.useCases}>
        <div className={styles.useCases__titleWrapper}>
            <div className={styles.useCases__title}>
                <div className={styles.useCases__titleImg}>
                    <img src={resourceIcon} alt="search" />
                </div>
                <div className={styles.useCases__titleText}>
                    <h2>Use Cases</h2>
                </div>
            </div>
        </div>

        <div className={styles.useCases__listWrapper}>
            <div className={styles.useCases__list}> 
                <div className={styles.useCases__listItem}>
                    <h2>Collaborative Coding Platform</h2>
                </div>
                <div className={styles.useCases__listItem}>
                    <h2>Project Management for Developers</h2>
                </div>
                <div className={styles.useCases__listItem}>
                    <h2>Peer Code Review</h2>
                </div>
                <div className={styles.useCases__listItem}>
                    <h2>Online Coding Bootcamps</h2>
                </div>

                <div className={styles.useCases__listItem}>
                    <h2>Developer Community and Networking</h2>
                </div>

                <div className={styles.useCases__listItem}>
                    <h2>Shadow Team</h2>
                </div>

            </div>  
        </div>
    </div>


    <div className={styles.tryOutSection}>
      <div className={styles.tryOutSection__header}>
      <h1>Not sure if <span className="primaryText">mentorship</span> is right for you? Give it a try with a <span className='primaryText'>one-off session</span>.</h1>
        <p>A quick, easy call with an expert is just one click away with our attractive one-off sessions. Picking a brain, talking through an issue or getting to know an industry expert has never been easier.</p>
      </div>

      <div className={styles.tryOutSection__cardWrapper}>
          <div className={styles.tryOutSection__card}>
              <h3 className='primaryText'>Introductory Call</h3>
              <p>
              If you're looking for a mentor, and you're just not sure about how this all works – this should be for you. In a casual, informal introductory call, a mentor will introduce themselves
              ...<Link to="/sessions/introductory-call">Read More</Link>
              </p>

              <div className={styles.tryOutSection__cardFooter}>
                <b>Approx. 30 Minutes</b>
                <span className='primaryText'>
                  <b>39 USD</b>
                </span>
              </div>
              <Link to="/sessions/introductory-call" style={{marginLeft:'0'}} className={"primaryBtn"}>Explore</Link>

          </div>
          <div className={styles.tryOutSection__card}>
              <h3 className='primaryText'>Study Plan</h3>
              <p>
              Looking to learn a new skill? The vast amount of resources on any topic on the internet can feel overwhelming at times. A mentor can give you an overview of worthwhile
              ...<Link to="/mentors">Read More</Link>
              </p>

              <div className={styles.tryOutSection__cardFooter}>
                <b>Approx. 45 Minutes</b>
                <span className='primaryText'>
                  <b>39 USD</b>
                </span>
              </div>
              <Link to="/mentors" style={{marginLeft:'0'}} className={"primaryBtn"}>Explore</Link>

          </div>

          <div className={styles.tryOutSection__card}>
              <h3 className='primaryText'>Interview Preparation</h3>
              <p>
              Some big interviews coming up? In this 1-hour session, a mentor with hiring experience will act as a technical interviewer and ask you some standard hiring questions
              ...<Link to="/mentors">Read More</Link>
              </p>

              <div className={styles.tryOutSection__cardFooter}>
                <b>Approx. 60 Minutes</b>
                <span className='primaryText'>
                  <b>99 USD</b>
                </span>
              </div>
              <Link to="/mentors" style={{marginLeft:'0'}} className={"primaryBtn"}>Explore</Link>

          </div>
      </div>  

      <br />
      <br />

      <Link to="/sessions" className="primaryBtn">More Sessions</Link>  

    

    </div>

   <ContactUs/>

   <Footer  />

    </>
  )
}

export default Landing