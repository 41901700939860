import React, {useEffect, useState,useRef} from 'react';
import {useNavigate, useParams} from 'react-router';
import {toast} from 'react-toastify';
import UserServices from '../../services/user.services';
import {getErrorMessage} from '../../util/GetError';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import {Link} from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import PeopleIcon from '@mui/icons-material/People';
import styles from '../Profile/Profile.module.css';
import styles2 from './UserDetails.module.css';
import {ContactPage} from '@mui/icons-material';
import Avatar from 'react-avatar';
import { useAppSelector} from '../../hooks';
import { Chip,  CircularProgress,  FormControl, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, Stack, TextField, Tooltip } from '@mui/material';
import { CloseOutlined, ImageOutlined, Visibility, VisibilityOff } from '@mui/icons-material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { timezones } from '../../util/timezone';
import { currencyArr } from '../../util/currency';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


function SingleUserDetails () {
  const params = useParams ();
  let {id} = params;
  const {userInfo} = useAppSelector (state => state.auth);
  const [updatedUsername,setUpdatedUsername] = useState("");
  const [updatedEmail,setUpdatedEmail] = useState("");
  const [showPassword,setShowPassword] = useState("");
  const [phoneNumber,setPhoneNumber] = useState("");
  const [fullName,setFullName] = useState("");
  const [dob,setDob] = useState("");
  const [address,setAddress] = useState("");
  const [password,setPassword] = useState("");
  const [profilePicture,setProfilePicture] = useState("");
  const [shortDescription,setShortDescription] = useState("");
  const [about,setAbout] = useState("");
  const [value, setValue] = useState(0);
  const [skills,setSkills] = useState([]);
  const [currentSkills,setCurrentSkills] = useState("");
  const [currentImageFile,setCurrentImageFile] = useState(null);
  const [isCheckingUsername,setIsCheckingUsername] = useState(false);
  const fileRef = useRef();
  const [uploading,setUploading] = useState(false);
  const [isUsernameAvailable,setIsUsernameAvailable] = useState(true);
  const [socialLinkArr,setSocialLinkArr] = useState([]);
  const [currentLink,setCurrentLink] = useState("");
  const [currentLinkType,setCurrentLinkType] = useState("Linkedin");
  const [isAddingSocialLink,setIsAddingSocialLink] = useState(false);
  const linkTypes = ["Linkedin","Github","Twitter"];

  const [timezone,setTimezone] = useState("");
  const [currency,setCurrency] = useState(null);
  const [isDeleting,setIsDeleting] = useState(false);
  const navigate = useNavigate();
 
  
  useEffect (
    () => {
      let isMounted = true;
      const getUserDetails = async () => {
        try {
          const response = await UserServices.getUserDetailsById (id);
          // console.log (response.data);
          if (response.data) {
       
            setUpdatedUsername(response.data?.username);
            setUpdatedEmail(response.data?.email);
            setPhoneNumber(response.data?.userDetails?.phoneNumber);
            setAddress(response.data?.userDetails?.address);
            setFullName(response.data?.userDetails?.fullName);
            setDob(response.data?.userDetails?.dob);
            setShortDescription(response.data?.userDetails?.shortDescription);
            setAbout(response.data?.userDetails?.about);
            if(response.data?.userDetails?.skills){
              setSkills(response.data?.userDetails.skills);
            }
    
            if(response.data?.userDetails?.timezone){
              setTimezone(response.data?.userDetails?.timezone);
            }
            if(response.data?.userDetails?.currency){
              setCurrency(response.data?.userDetails?.currency);
            }
      
            if(response.data?.userDetails?.profilePicture){
              setProfilePicture(response.data?.userDetails.profilePicture);
            }
    
            if(response.data?.userDetails?.socialLink){
              setSocialLinkArr(response.data?.userDetails?.socialLink);
            }
          }
        } catch (err) {
          console.log (err);
          toast.error (getErrorMessage (err));
        }
      };

      if (isMounted) {
        getUserDetails ();
      }

      return () => {
        isMounted = false;
      };
    },
    [id]
  );



  useEffect(()=>{
    setCurrency(currencyArr[0]);
  },[]);

  const defaultTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  // console.log(defaultTimezone);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(()=>{
    if(defaultTimezone){
      timezones.forEach(item=>{
        if(item.utc.includes(defaultTimezone)){
          setTimezone(item);
        }
      })
    }
  },[defaultTimezone])



  const getUserDetails  = async ()=>{
    try{
      const response = await UserServices.getUserDetailsById(id);
      // console.log(response.data);
      setUpdatedUsername(response.data?.username);
      setUpdatedEmail(response.data?.email);
      setPhoneNumber(response.data?.userDetails?.phoneNumber);
      setAddress(response.data?.userDetails?.address);
      setFullName(response.data?.userDetails?.fullName);
      setDob(response.data?.userDetails?.dob);
      setShortDescription(response.data?.userDetails?.shortDescription);
      setAbout(response.data?.userDetails?.about);
      if(response.data?.userDetails?.skills){
        setSkills(response.data?.userDetails?.skills);
      }
      if(response.data?.userDetails?.profilePicture){
        setProfilePicture(response.data?.userDetails.profilePicture);
      }

      if(response.data?.userDetails?.timezone){
        setTimezone(response.data?.userDetails?.timezone);
      }
      if(response.data?.userDetails?.currency){
        setCurrency(response.data?.userDetails?.currency);
      }

     

      if(response.data?.userDetails.socialLink){
        setSocialLinkArr(response.data?.userDetails.socialLink);
      }

    
    
    }catch(err){
      console.log(err);
      toast.error(getErrorMessage(err));
    }
  }


  // const handleClickOpen = () => {
  //   setIsEditing(true);
  // };

  // const handleClose = () => {
  //   setIsEditing(false);
  // };

  const handleSubmit = async ()=>{
    // console.log("submit");
    let finalSkills = skills;
    if(currentSkills){
      finalSkills.push(currentSkills);
    }
    let data = {
      username:updatedUsername,
      email:updatedEmail,
      userDetails:{
        phoneNumber:phoneNumber,
        address:address,
        fullName:fullName,
        dob:dob,
        skills:finalSkills,
        profilePicture:profilePicture,
        shortDescription:shortDescription,
        about:about,
        socialLink:socialLinkArr,
        timezone:timezone,
        currency:currency
      },
      password:password
    }
    for(let prop in data){
      if(!data[prop]){
        delete data[prop]
      }
    }
    // console.log(data);
   
    try{
      const response = await UserServices.updateUserDetails({id:id,updatedData:data})
      if(response.status===200){
        getUserDetails();
        toast.success("Updated User Details Successfully!");
        setCurrentSkills("");
      }
     
    }catch(err){
      console.log(err);
      toast.error(getErrorMessage(err));

    }
  }






  const handleSkillsAdd = (e)=>{
    let skillVal = e.target.value;
    setCurrentSkills(skillVal)
  }

  const handleDeleteSkill = (val)=>{
    setSkills((prev) => prev.filter((item) => item !== val));
  }

 


const handleOpenFile = ()=>{
  fileRef.current.click();
}
  
const handleFileChange = (e)=>{
  // console.log(e.target.files[0]);
  setCurrentImageFile(e.target.files[0]);
  e.target.value = null;
}
 
const handleAddSkill = ()=>{
  setSkills(prev=>{
    return [...prev,currentSkills];
  });
  setCurrentSkills("");
}

const uploadFile = async ()=>{
  const file = currentImageFile
  setUploading(true);
  // console.log(e.target.files[0]);
  if(file.size > 1048576){
     toast.error("File Size should be less than 1 mb");
      return;
  }
  // console.log(file);

  if(file){
     try{
      var formData = new FormData();
      formData.append('userId',id);
      formData.append(updatedUsername+"_coderscolab",file);
      // console.log(formData);
      const response = await UserServices.updateProfilePic(formData)
      setProfilePicture(response.data);
      // console.log(response.data);
      setUploading(false);
      setCurrentImageFile(null);
      // fileRef.current.target.value = null;
    
     }catch(err){
         console.log(err);
         toast.error(getErrorMessage(err));
         setUploading(false);
     }
  }

}

const onChangeUsername = async (e)=>{
  let username = e.target.value;

  // username.replace (/ /g, '_');
  // console.log(username);
  // username.replace(/\./g,'_');
  username = username.trim().toLowerCase().replace(/\./g,'_')
  setUpdatedUsername(username);
 if(username !==userInfo.username){
  setIsCheckingUsername(true);
  try{
    const response = await UserServices.checkDuplicateUsername({username:username,id:id});
    if(response.status===200){
      setIsCheckingUsername(false);
      setIsUsernameAvailable(true);
    }
  }catch(err){
    console.log(err);
    setIsUsernameAvailable(false);
    setIsCheckingUsername(false);
  }
 }
}

const handleAddLink = ()=>{
  const data = {
    type:currentLinkType,
    link:currentLink
  }
  // console.log(data);
  setSocialLinkArr(prev=>[...prev,data]);
  setCurrentLink("");
  setIsAddingSocialLink(false);

}

const handleSocialLinkChange = (link,index)=>{
  // console.log(link,index);
  
  const newSocialLinks = socialLinkArr.slice();
  newSocialLinks[index] = {
    ...socialLinkArr[index],
    link:link
  };
  // console.log(newSocialLinks);
  setSocialLinkArr(newSocialLinks);

}

// useEffect(()=>{
//   console.log(socialLinkArr)
// },[socialLinkArr])

const handleSocialLinkRemove = (index)=>{
  setSocialLinkArr(socialLinkArr.filter((item,i) => i !== index))
  // let newSocialLink = socialLinkArr;
  // newSocialLink.splice(index,1);
  // console.log(newSocialLink);
  // setSocialLinkArr(newSocialLink)
}

const handleIsAddingSocialLink = ()=>{
  setIsAddingSocialLink(prev=>!prev);
}

const handleSocialLinkTypeChange = (type,index)=>{
  // console.log(type,index);
  const newSocialLinks = socialLinkArr.slice();
  newSocialLinks[index] = {
    ...socialLinkArr[index],
    type:type
  };
  // console.log(newSocialLinks);
  setSocialLinkArr(newSocialLinks);
}

const handleOpenProfile = ()=>{
  window.open(`${window.location.origin}/${updatedUsername}`,'_blank')

}

const handleDeleteUser = async ()=>{
  setIsDeleting(true);
  try{
    const response = await UserServices.deleteUserById(id);
    console.log(response.data);
    if(response.data){
      toast.success("Deleted User Successfully!");
      navigate('/dashboard/admin/all-users');
    }
  }catch(err){
    console.log(err);
    toast.error(getErrorMessage(err));
  }
}

  return (
    <div>
     
      <div className={styles2.top__header}>
      <h1>{updatedUsername ? updatedUsername : 'User'} Details</h1>
        <div>
        <button style={{boxShadow:'none'}} disabled={!updatedUsername || !updatedEmail || !shortDescription || !isUsernameAvailable} className="primaryBtn" onClick={handleSubmit}>Save Changes</button>
        <button style={{boxShadow:'none'}} disabled={isDeleting} className="primaryBtn" onClick={handleDeleteUser}>{isDeleting ? 'Please Wait...': 'Delete User'}</button>
        </div>
        {/* <button className="primaryBtn" onClick={()=>setIsAddSkills(true)}>Edit Skills</button> */}
       </div>
      <Breadcrumbs aria-label="breadcrumb">
        <Link className={styles2.link} to="/dashboard">
          <HomeIcon sx={{mr: 0.5}} fontSize="inherit" />
          Dashboard
        </Link>
        <Link className={styles2.link} to="/dashboard/admin/all-users">
          <PeopleIcon sx={{mr: 0.5}} fontSize="inherit" />
          All Users
        </Link>
        <Typography
          sx={{display: 'flex', alignItems: 'center'}}
          color="text.primary"
        >
          <ContactPage sx={{mr: 0.5}} fontSize="inherit" />
          {updatedUsername ? updatedUsername : 'User'} Details
        </Typography>
      </Breadcrumbs>
     

      <div className={styles2.singleUserDetails}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} 
            textColor="inherit"
  indicatorColor="secondary" aria-label="basic tabs example">
              <Tab label="Profile" {...a11yProps(0)}  />
              <Tab label="Account" {...a11yProps(1)} />
              <Tab label="Settings" {...a11yProps(2)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
         
          <div className={styles.profileImg}>
         <input
 accept="image/*"
 className={styles.input}
 id="contained-button-file"
 type="file"
 onChange={handleFileChange}
 hidden
 ref={fileRef}
/>
         {profilePicture
           ? 
           <label htmlFor="contained-button-file">
              <IconButton onClick={handleOpenFile}>
              <Avatar size="100" round={true} src={profilePicture} />
              <div className={styles.overlay}>
              <Tooltip title="Update Image">
              <ImageOutlined className={styles.uploadIcon} />
              </Tooltip>
              </div>
              </IconButton>
            </label>
          
           : 
           <label htmlFor="contained-button-file">
           
            <IconButton onClick={handleOpenFile}>
            <Avatar
                        size="100"
                        round={true}
                        name={userInfo?.username}
                      />
                    <div className={styles.overlay}>
                    <Tooltip title="Update Image">

                    <ImageOutlined className={styles.uploadIcon} />
                    </Tooltip>
                    </div>
            </IconButton>
          
          </label>
          
          }

          <div>
            {currentImageFile?.name}
          </div>

         
        
        
          {currentImageFile ?
            <button className="primaryBtn" disabled={uploading} onClick={uploadFile}>
              {uploading ? "Uploading....": "Upload"}
          </button>
          :
          <button className="secondaryBtn" onClick={handleOpenFile}>
              Change Image
          </button>
          }
         </div>
          <div className={styles.profileInfo}>

            {/* <table>
            <tbody>
            <tr>
                <th>UserId</th>
                <td>{userInfo?.id}</td>
              </tr>
              <tr>
                <th>Username</th>
                <td>{updatedUsername}</td>
              </tr>
              <tr>
                <th>Email</th>
                <td>{updatedEmail}</td>
              </tr>
              <tr>
                <th>Full Name</th>
                <td>{fullName}</td>
              </tr>
              <tr>
                <th>DOB</th>
                <td>{dob}</td>
              </tr>
              <tr>
                <th>Phone Number</th>
                <td>{phoneNumber}</td>
              </tr>
              <tr>
                <th>Address</th>
                <td>{address}</td>
              </tr>
            </tbody>

            </table> */}
            
  <TextField
          required
          id="outlined-required"
          label="Username"
          style={{margin:'10px',width:'93%'}}
          error={!updatedUsername || !isUsernameAvailable}
          helperText={!updatedUsername ? "Username is required" : !isUsernameAvailable && "Username not available"}
          onChange={onChangeUsername}
          value={updatedUsername}
          InputProps={{
            startAdornment: (<InputAdornment position="start">
                <Chip label=" coderscolab.com/"/>
            
            </InputAdornment>),
           
            endAdornment:(
               <div className={styles.inputAdornment}>
             {isCheckingUsername && <InputAdornment position="start">
                <CircularProgress color="inherit" size={20}/>
            
            </InputAdornment>}
              <Chip style={{marginLeft:'10px'}} label="Preview" onClick={handleOpenProfile}/>
              </div>
              
            )
          }}
        />
        <TextField
          
          id="outlined-basic"
          label="Full Name"
          style={{margin:'10px',width:'45%'}}
        
          onChange={(e)=>setFullName(e.target.value)}
          value={fullName}
        />
         <TextField
      
          id="outlined-basic"
          label="DOB"
      
          style={{margin:'10px',width:'45.5%'}}
        
          onChange={(e)=>setDob(e.target.value)}
          value={dob}
        />

        <TextField
      
      id="outlined-basic"
      label="Short Description"
      required
      style={{margin:'10px',width:'93%'}}
    
      onChange={(e)=>setShortDescription(e.target.value)}
      value={shortDescription}
    />
     





       


       
        
        <TextField
          id="outlined-multiline-flexible"
          label="About Yourself"
          multiline
          style={{margin:'10px',width:'93%'}}
          onChange={(e)=>setAbout(e.target.value)}
          value={about}
          rows={4}
        />
    
 
        <TextField
          id="outlined-multiline-flexible"
          label="Address"
          multiline
          style={{margin:'10px',width:'93%'}}
          onChange={(e)=>setAddress(e.target.value)}
          value={address}
          rows={4}
        />
    
        </div>
       
            <label>Skills</label>
        <br/>
        <Stack  direction={{ xs: 'column', sm: 'row' }} spacing={1}  >
            {skills.map((data,i) => {
          
                return (
                 
                    <Chip
                    key={i}
                    style={{backgroundColor:'var(--primary)',color:'white'}}
                      label={data}
                      onDelete={()=>handleDeleteSkill(data)}
                    />
                 
                );
            })}
            </Stack>
            <br />
        <TextField
          
          id="outlined-basic"
          label="Add Skill"
          style={{margin:'10px',width:'90%'}}
          placeholder="e.g. Reactjs"
          onChange={handleSkillsAdd}
          value={currentSkills}
        
        />
        <button disabled={!currentSkills} style={{boxShadow:'none'}} className="primaryBtn" onClick={handleAddSkill} autoFocus>
            Add Skill
        </button>

        <h3>Social Links</h3>

        {socialLinkArr.map((item,i)=>{
          return(
            <TextField
        
          
          label="Social Link"
          key={i}
          style={{margin:'10px',width:'93%'}}
          onChange={(e)=>handleSocialLinkChange(e.target.value,i)}
          value={item.link}
          InputProps={{
            startAdornment: (<InputAdornment position="start">
                <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={item.type}
                onChange={(e)=>handleSocialLinkTypeChange(e.target.value,i)}
                size="small"
              >
                
               {linkTypes.map((item,key)=>{
                 return(<MenuItem key={key} value={item}>{item}</MenuItem>)
               })} 
               
              </Select>
            
            </InputAdornment>),
            endAdornment:(
              <div className={styles.inputAdornment}>
              <CloseOutlined className={styles.closeIcon} onClick={()=>handleSocialLinkRemove(i)} />
              
              </div>
              
            )
          }}
        />
          )
        })}
        
        {isAddingSocialLink && socialLinkArr.length < linkTypes.length &&
          <TextField
          required
          id="outlined-required"
          label="Social Link"
          style={{margin:'10px',width:'93%'}}
          onChange={(e)=>setCurrentLink(e.target.value)}
          value={currentLink}
          InputProps={{
            startAdornment: (<InputAdornment position="start">
                <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={currentLinkType}
                onChange={(e)=>setCurrentLinkType(e.target.value)}
                size="small"
              >
                
               {linkTypes.map((item,key)=>{
                 return(<MenuItem key={key} value={item}>{item}</MenuItem>)
               })} 
               
              </Select>
            
            </InputAdornment>),
            endAdornment:(
              currentLink && <div className={styles.inputAdornment}>
              <CloseOutlined className={styles.closeIcon} onClick={()=>setCurrentLink("")} />
              <Chip style={{marginLeft:'10px'}} label="Add" onClick={handleAddLink}/>
              </div>
              
            )
          }}
        />
        }

       {socialLinkArr.length < linkTypes.length &&  <button className={styles.socialLinkBtn} onClick={handleIsAddingSocialLink}>{isAddingSocialLink ? 'Remove' : 'Add Social Link'}</button>}

          </TabPanel>
          <TabPanel value={value} index={1}>
              <h2>Account</h2>
            <div className={styles.formItem}>
              <label>
                Email
              </label>
              <TextField
          required
          id="outlined-required"
          label="Email"
          disabled={true}
          style={{margin:'10px',width:'100%'}}
          error={!updatedEmail}
          helperText={!updatedEmail && "Email is required"}
          onChange={(e)=>setUpdatedEmail(e.target.value)}
          value={updatedEmail}
        />
            </div>

     
            
            <div className={styles.formItem}>
            <label>Phone Number</label>
            <TextField
          
          id="outlined-basic"
          label="Phone Number"
          style={{margin:'10px',width:'100%'}}
          onChange={(e)=>setPhoneNumber(e.target.value)}
          value={phoneNumber}
        />
            </div>


            <div className={styles.formItem}>
            <label>Password</label>
              <FormControl sx={{ m: 1,width:'100%' }} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
          <OutlinedInput
            autoComplete="off" 
            id="outlined-adornment-password"
            type={showPassword ? 'text' : 'password'}
            onChange={(e)=>setPassword(e.target.value)}
            value={password}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={()=>setShowPassword(!showPassword)}
                  onMouseDown={(e)=>e.preventDefault()}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          />
        </FormControl>
            </div>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <div className={styles.settings__wrapper}>
                <div className={styles.settings__item}>
                  <label>
                  <LocationOnIcon />
                  <b>Timezone</b></label>
                  <small>It is needed for showing correct time for your availability</small>
                  <div>
                  <Select
                value={timezone}
                onChange={(e)=>setTimezone(e.target.value)}
                size="small"
              >
                
               {timezones.map((item,key)=>{
                 return(<MenuItem key={key} value={item.text}>{item.text}({item.abbr})</MenuItem>)
               })} 
               
              </Select>
                  </div>
                </div>
                <div className={styles.settings__item}>
                  <label>
                  <MonetizationOnIcon  />
                  <b>Sell In</b></label>
                  <small>Display your services in your prefered currency</small>
                  <div>
                  <Select
                value={currency}
                onChange={(e)=>{
                  setCurrency(e.target.value)
                }}
                size="small"
              >
                
               {currencyArr.map((item,key)=>{
                 return(<MenuItem key={key} value={item.name}>{item.symbol}({item.name})</MenuItem>)
               })} 
               
              </Select>
                  </div>
                </div>
            </div>
          </TabPanel>
      </div>
    </div>
  );
}

export default SingleUserDetails;
