import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router';
import BlogServices from '../../services/blogs.services';
import styles from './blog.module.css';
import {toast} from 'react-toastify';
import Navbar from '../../components/Navbar/Navbar';
import {getErrorMessage} from '../../util/GetError';
import moment from 'moment';
import Avatar from 'react-avatar';
import ReactQuill from 'react-quill';
import hljs from 'highlight.js';
import Footer from '../../components/Footer/Footer';
import { Link } from 'react-router-dom';
import { EastOutlined } from '@mui/icons-material';
import { Divider } from 'antd';


const modules = {
    syntax: {highlight: text => hljs.highlightAuto (text).value},
    toolbar: false
  };

function BlogDetails () {
  const [blogDetails, setBlogDetails] = useState ('');
  
  const params = useParams ();

  useEffect (
    () => {
      let isMounted = true;
      const getBlogDetails = async () => {
        try {
          const response = await BlogServices.getSingleBlog (params.id);
        //   console.log (response.data);
          const totalWords = response?.data?.content.split(' ').length;
          const time = (totalWords / 200);
        //   console.log(time);
          let mins = Math.trunc(time);
          const deci = time % 1;
          const sec = parseInt((deci / 0.60)*10);
        //   console.log(sec);
          if(sec > 40){
              mins ++;
          }
        //   console.log(mins);
          setBlogDetails ({...response.data,readTime:`${mins} mins`});
        } catch (err) {
          console.log (err);
          toast.error (getErrorMessage (err));
        }
      };

      if (isMounted) {
        getBlogDetails ();
      }

      return () => {
        isMounted = false;
      };
    },
    [params]
  );

  return (
    <>
    <div className={`blogDetailsPage ${styles.blog__list}`}>
      <Navbar bgColor={false} textColor={'black'} />
      <div className={styles.blogDetails}>
      <div className={styles.cardTags}>
        {blogDetails?.tags?.map(item=><span key={item}>{item}</span>)}
    </div>
    <br />
      <h1>{blogDetails?.title}</h1>
      <div className={styles.blogDetails__info}>
      <div className={styles.blog__author}>
        {blogDetails?.userDetails?.userDetails?.profilePicture ?  <img alt={blogDetails?.title} className={styles.authorImg} src={blogDetails?.userDetails?.userDetails?.profilePicture} size="40" /> :  <Avatar round={true} title={blogDetails?.userDetails?.username} size="40" />}
      
        </div>
        <div className={styles.createdDate}>
          <span>{blogDetails?.userDetails?.userDetails?.fullName ? blogDetails?.userDetails?.userDetails?.fullName : blogDetails?.userDetails?.username}</span>
          <div> {moment(blogDetails?.createdAt).format('MMMM DD, YYYY')} . <span style={{color:'gray'}}>{blogDetails?.readTime} read</span></div>
        </div>
      </div>
     <img src={blogDetails?.imgURL} className={styles.blogDetails__img} alt={blogDetails?.title} />
       
      </div>
      <div className={styles.blog__overview}>
        {blogDetails?.description}
      </div>
      <div className={styles.blog__content}>
      <ReactQuill
              modules={modules}
              theme="snow"
              value={blogDetails?.content}
              readOnly={true}
              style={{border:'none'}}
            />
      </div>
     <div className={styles.blogDetails__bottom}>
     <Link to="/blog" className={styles.morePostLink}>View All Blogs <EastOutlined className={styles.arrowRight} /> </Link>
      <div className={styles.blog__authorAbout}>
            <div className={styles.blog__authorArea}>
            {blogDetails?.userDetails?.userDetails?.profilePicture ?  <img alt={blogDetails?.title} className={styles.authorImg} src={blogDetails?.userDetails?.userDetails?.profilePicture} size="100" /> :  <Avatar round={true} title={blogDetails?.userDetails?.username} size="100" />}
            </div>
            <div className={styles.blog__authorAboutInfo}>
                <h4>About {blogDetails?.userDetails?.userDetails?.fullName ? blogDetails?.userDetails?.userDetails?.fullName : blogDetails?.userDetails?.username}</h4>
                <div className={styles.blog__authorTitle}>{blogDetails?.userDetails?.userDetails?.shortDescription}</div>
                <p>
                    {blogDetails?.userDetails?.userDetails?.about ? blogDetails?.userDetails?.userDetails?.about : 'Information not available for the author'}
                </p>
                <Link to={`/${blogDetails?.userDetails?.username}`} className={styles.morePostLink}>View Profile <EastOutlined className={styles.arrowRight} /></Link>
            </div>
      </div>
     </div>
        <Divider />
     
    </div>
    <Footer  />
    </>
  );
}

export default BlogDetails;
