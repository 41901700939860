import React, {useState, useEffect} from 'react';
import {toast} from 'react-toastify';
import SessionsServices from '../../../services/sessions.services';
import {getErrorMessage} from '../../../util/GetError';
import styles from '../../UserBookings/UserBookings.module.css';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import {Menu,Input, Table, Dropdown} from 'antd';
import moment from 'moment';
import {Link} from 'react-router-dom';

function AllSessions () {
  const [allSessions, setAllSessions] = useState ([]);
  const [filteredSessions,setFilteredSessions] = useState([]);
  const [filteredTypes,setFilteredTypes] = useState([]); 

  useEffect (() => {
    let isMounted = true;
    const getAllSessions = async () => {
      try {
        const response = await SessionsServices.getAllSessions ();
        console.log (response.data);
        setAllSessions (response.data);
        let filterTypes = [];
        response.data?.forEach (o => {
                if (o?.type) {
                filterTypes.push ({text: o.type, value: o.type});
                }
            });
          filterTypes = filterTypes.filter ((item, index, self) => {
            return (
            index ===
            self.findIndex (t => t.text === item.text && t.value === item.value)
            );
        });
        setFilteredTypes(filterTypes);
      } catch (err) {
        console.log (err);
        toast.error (getErrorMessage (err));
      }
    };

    if (isMounted) {
      getAllSessions ();
    }

    return () => {
      isMounted = false;
    };
  }, []);


  const columns = [
    {
      title: 'Created At',
      key:'createdAt',
      sorter: (a, b) => moment(a.createdAt) - moment(b.createdAt),
      defaultSortOrder: 'descend',
      render: (record) => <b>{moment(record.createdAt).format('YYYY-MM-DD hh:mm:ss')}</b>,
    },
    {
      title: 'ID',
      key:'id',
      render: (record) => <Link to={`/dashboard/sessions/edit/${record.id}`}><b>{record.session_id}</b></Link>,
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: (text) => <b>{text}</b>,
    },
    {
      title: 'price',
      key: 'price',
      render: (record) => <b>{record.price}</b>,
    },
    {
      title: 'Type',
      key: 'type',
      filters:filteredTypes,
      onFilter: (value, record) => record?.type.indexOf(value) === 0,
      render: (record) => <b>{record?.type}</b>,
     
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <div className={styles.btnWrapper}>
        {/* <Link to={`/dashboard/payments/${record.id}`}>View Details</Link>
        <Tooltip title={`${record.status ? 'Payment Not Confirmed' : 'Confirm Payment'}`}>
        <button className={styles.tableBtn} onClick={()=>handlePaymentStatusUpdate(record)}>{record?.status ? 'Not Confirm':'Confirm'}</button>
        </Tooltip> */}
        <Dropdown
      overlay={
        <Menu>
          <Menu.Item key="1">
          <Link to={`/dashboard/sessions/edit/${record.id}`}>View Details</Link>
          </Menu.Item>
        </Menu>
      }
      placement="bottomLeft"
      arrow
    >
     <MoreVertOutlinedIcon />
    </Dropdown>

        </div>
      ),
    },

  ];

  const handleSearch = (e)=>{
    let query = e.target.value;
    let filteredList = allSessions.filter((item)=>item?.session_id?.toLowerCase().match(query.toLowerCase()))
    setFilteredSessions(filteredList);
  }

  return (
    <div>
        <div style={{display:'flex',marginBottom:'1rem',justifyContent:'space-between',alignItems:'center'}}>
        <h2>All Sessions</h2>
        <Input style={{maxWidth:'300px'}} placeholder='Search by ID' onChange={handleSearch} />
      </div>
      <Table columns={columns} dataSource={filteredSessions.length > 0 ? filteredSessions : allSessions} />
    </div>
  );
}

export default AllSessions;
