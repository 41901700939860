import React, {useState} from 'react';
import Navbar from '../../components/Navbar/Navbar';
import styles from './About.module.css';
import aboutus1 from '../../assets/aboutus1.jpg';
import aboutus2 from '../../assets/aboutus2.jpg';
import aboutus3 from '../../assets/aboutus3.jpg';
import aboutus4 from '../../assets/aboutus4.jpg';
import team1 from '../../assets/team/jitendra.png';
import team2 from '../../assets/team/aman.jpg';
import team3 from '../../assets/team/tanya.jpg';
import team4 from '../../assets/team/debdutta.jpg';
import team5 from '../../assets/team/pratibha.jpg';
import team6 from '../../assets/team/pushkar.jpg';
import team7 from '../../assets/team/aftan.jpg';
import team8 from '../../assets/team/abhay.png';
import team9 from '../../assets/team/madiha.jpg';
import team10 from '../../assets/team/pranav.jpg';
// import avatar from '../../assets/avatar.webp';
// import avatar2 from '../../assets/avatarF.webp';
import Footer from '../../components/Footer/Footer';

function About () {
  const [colorChange, setColorchange] = useState (false);

  const changeNavbarColor = () => {
    if (window.scrollY >= 80) {
      setColorchange (true);
    } else {
      setColorchange (false);
    }
  };
  window.addEventListener ('scroll', changeNavbarColor);

  return (
    <div>
      <Navbar bgColor={colorChange} />
      <div className={styles.about__header}>
        <div className={styles.about__headerText}>
          <h2>About Us</h2>
          <p>
            CodersColab is one of the leading software company dedicated to connecting coding mentors with clients seeking guidance and support in their coding journey. We understand that mentorship plays a pivotal role in nurturing talent and accelerating growth in the tech industry. That's why we have created a platform that brings together experienced mentors and aspiring coders for transformative mentoring sessions.
          </p>
          <p>
            Our platform is designed to make the mentor-client connection seamless and convenient. Through our user-friendly interface, you can easily browse through our mentor profiles, review their expertise and ratings, and select the mentor who best matches your requirements. Once connected, you can schedule mentoring sessions at your convenience, whether it's a one-time consultation or an ongoing mentoring relationship.
          </p>
          <br />

          <a href="#team" className="primaryBtn primaryBtn__plain">
            Meet Our Team
          </a>
        </div>
        <div className={styles.about__headerImgWrapper}>
          <img src={aboutus1} alt="..." className={styles.about__headerImg1} />
          <img src={aboutus2} alt="..." className={styles.about__headerImg2} />
        </div>
      </div>
      <div className={styles.our__mission}>
        <div className={styles.our__missionImgWrapper}>
          <img src={aboutus3} alt="..." />
          <img src={aboutus4} alt="..." />
        </div>
        <div className={styles.our__missionText}>
          <h2>Our Mission</h2>
          <p>
            At CodersColab, we are committed to your success. Our mission is to empower coders like you to realize their full potential and achieve their coding goals. We measure our success by the growth and achievements of our clients, and we take pride in being a part of their journey towards excellence. Join CodersColab today and unlock a world of knowledge, guidance, and inspiration. Together, let's elevate your coding skills and pave the way for a successful and fulfilling career in the world of software development.

          </p>
          <p>
            We believe that personalized mentorship is the key to unlocking the full potential of every coder. When you join CodersColab, you gain access to a vast network of mentors who are ready to support you in your coding journey. Whether you're a beginner looking to grasp the basics or an experienced developer aiming to master a new programming language, our mentors will tailor their guidance to meet your specific needs and aspirations.
          </p>
          <p>
            At CodersColab, we value collaboration and continuous learning. Our mentoring sessions go beyond just providing technical assistance. Our mentors act as trusted advisors, helping you develop problem-solving skills, enhancing your critical thinking abilities, and guiding you towards best practices in software development. We encourage an open and interactive learning environment where clients can ask questions, seek feedback, and engage in meaningful discussions.
          </p>

        </div>

      </div>

      <div className={styles.our__teamWrapper}>
        <div id="team" className={styles.allTeam}>
          <h1>Our Team</h1>
          <p style={{textAlign: 'center'}}>
            Our team is quickly able to asses the needs of our clients and find the perfect solution
            to their IT requirements.
          </p>
          <div className={styles.card__group}>
            <div className={styles.card}>
              <div
                className={styles.card__img}
                style={{
                  backgroundImage: `url(${team1})`,
                }}
              />
              <div className={styles.card__body}>
                <h4>Jitendra Kumar</h4>
                <small>Founder,CEO</small>
              </div>
            </div>

            <div className={styles.card}>
              <div
                className={styles.card__img}
                style={{
                  backgroundImage: `url(${team2})`,
                }}
              />
              <div className={styles.card__body}>
                <h4>Aman Kumar</h4>
                <small>Co-Founder and CFO</small>
              </div>
            </div>

            <div className={styles.card}>
              <div
                className={styles.card__img}
                style={{
                  backgroundImage: `url(${team3})`,
                  backgroundPosition: 'center',
                }}
              />
              <div className={styles.card__body}>
                <h4>Tanya Gupta</h4>
                <small>Chief Management Officer (CMO)</small>
              </div>
            </div>

            <div className={styles.card}>
              <div
                className={styles.card__img}
                style={{
                  backgroundImage: `url(${team4})`,
                  backgroundPosition: 'center',
                }}
              />
              <div className={styles.card__body}>
                <h4>Debdutta Dey</h4>
                <small>Sr. Talent Acquisition & Management Executive</small>
              </div>
            </div>

            <div className={styles.card}>
              <div
                className={styles.card__img}
                style={{
                  backgroundImage: `url(${team5})`,
                }}
              />
              <div className={styles.card__body}>
                <h4>Pratibha Sabharwal</h4>
                <small>Sr. AI & ML Engineer</small>
              </div>
            </div>

            <div className={styles.card}>
              <div
                className={styles.card__img}
                style={{
                  backgroundImage: `url(${team6})`,
                }}
              />
              <div className={styles.card__body}>
                <h4>Pushkar Nandgaonkar</h4>
                <small>ML Engineer & content Manager</small>
              </div>
            </div>

            <div className={styles.card}>
              <div
                className={styles.card__img}
                style={{
                  backgroundImage: `url(${team7})`,
                }}
              />
              <div className={styles.card__body}>
                <h4>Aftan Ahmad</h4>
                <small>Sr. Full Stack Developer (MERN) </small>
              </div>
            </div>

            <div className={styles.card}>
              <div
                className={styles.card__img}
                style={{
                  backgroundImage: `url(${team8})`,
                }}
              />
              <div className={styles.card__body}>
                <h4>Abhay Tiwari</h4>
                <small>Android Developer</small>
              </div>
            </div>

            <div className={styles.card}>
              <div
                className={styles.card__img}
                style={{
                  backgroundImage: `url(${team9})`,
                }}
              />
              <div className={styles.card__body}>
                <h4>Madiha Sehar</h4>
                <small>
                  Full Stack Developer(Angular
                  / React + Java Spring Boot)
                </small>
              </div>
            </div>

            <div className={styles.card}>
              <div
                className={styles.card__img}
                style={{
                  backgroundImage: `url(${team10})`,
                }}
              />
              <div className={styles.card__body}>
                <h4>Pranav Sanakr</h4>
                <small>ML Engineer</small>
              </div>
            </div>

          </div>
        </div>

        {/* <div
          id="team"
          className={styles.allTeam}
          style={{background: 'whitesmoke', color: 'black'}}
        >
          <h1>Our Contract Basis Employees</h1>

          <div className={styles.card__group}>
            <div className={styles.card}>
              <div
                className={styles.card__img}
                style={{
                  backgroundImage: `url(${avatar})`,
                  filter: 'none',
                }}
              />
              <div className={styles.card__body}>
                <h4>Ganesh Sharma</h4>
                <small>ML Engineer</small>
              </div>
            </div>

            <div className={styles.card}>
              <div
                className={styles.card__img}
                style={{
                  backgroundImage: `url(${avatar2})`,
                  filter: 'none',
                }}
              />
              <div className={styles.card__body}>
                <h4>Kajal Rastogi</h4>
                <small>Chat Management and Support</small>
              </div>
            </div>

            <div className={styles.card}>
              <div
                className={styles.card__img}
                style={{
                  backgroundImage: `url(${avatar})`,
                  filter: 'none',
                }}
              />
              <div className={styles.card__body}>
                <h4>Abhinav Jagtap</h4>
                <small>Python developers and Mentors</small>
              </div>
            </div>

            <div className={styles.card}>
              <div
                className={styles.card__img}
                style={{
                  backgroundImage: `url(${avatar})`,
                  filter: 'none',
                }}
              />
              <div className={styles.card__body}>
                <h4>Shivam Agarwal</h4>
                <small>ML Engineer and Mentors</small>
              </div>
            </div>

            <div className={styles.card}>
              <div
                className={styles.card__img}
                style={{
                  backgroundImage: `url(${avatar})`,
                  filter: 'none',
                }}
              />
              <div className={styles.card__body}>
                <h4>Athrwa Deshmukh</h4>
                <small>ML Engineer and Mentors</small>
              </div>
            </div>

            <div className={styles.card}>
              <div
                className={styles.card__img}
                style={{
                  backgroundImage: `url(${avatar})`,
                  filter: 'none',
                }}
              />
              <div className={styles.card__body}>
                <h4>Arvind Rathore</h4>
                <small>Sr. Mobile App Developers</small>
              </div>
            </div>

            <div className={styles.card}>
              <div
                className={styles.card__img}
                style={{
                  backgroundImage: `url(${avatar})`,
                  filter: 'none',
                }}
              />
              <div className={styles.card__body}>
                <h4>Pankaj Kharetwal</h4>
                <small>Full Stack Developer (MEAN & MERN)</small>
              </div>
            </div>

            <div className={styles.card}>
              <div
                className={styles.card__img}
                style={{
                  backgroundImage: `url(${avatar})`,
                  filter: 'none',
                }}
              />
              <div className={styles.card__body}>
                <h4>Rahul Jha</h4>
                <small>
                  Sr. Java developer (Angular / React + Spring boot)
                </small>
              </div>
            </div>

            <div className={styles.card}>
              <div
                className={styles.card__img}
                style={{
                  backgroundImage: `url(${avatar})`,
                  filter: 'none',
                }}
              />
              <div className={styles.card__body}>
                <h4>Faizan Koul</h4>
                <small>Sr. AI and ML engineers</small>
              </div>
            </div>

          </div>
        </div> */}
      </div>

      <Footer />
    </div>
  );
}

export default About;
