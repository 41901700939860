import {Link} from 'react-router-dom';
import Navbar from '../../components/Navbar/Navbar';
import styles from './filters.module.css';
import EastOutlinedIcon from '@mui/icons-material/EastOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import {useState, useEffect} from 'react';
import UserServices from '../../services/user.services';
import avatarImg from '../../assets/avatar.webp';
import Footer from '../../components/Footer/Footer';

function DataScience () {
  const [popularDevelopers, setPopularDevelopers] = useState ([]);

  useEffect (() => {
    let isMounted = true;
    const getPopularDevelopers = async () => {
      try {
        const response = await UserServices.getPopularDevelopers (
          'data-science'
        );
        console.log (response.data);
        setPopularDevelopers (response.data);
      } catch (err) {
        console.log (err);
      }
    };
    if (isMounted) {
      getPopularDevelopers ();
    }

    return () => {
      isMounted = false;
    };
  }, []);
  return (
    <>
      <div className={styles.page__header}>
      <Navbar bgColor={false} textColor={'black'} />
      <div className={styles.page__main}>
        <div className={styles.page__mainContent}>
          <h2>Find a Data Science Mentor</h2>
          <p>
            Embark on your data science journey with confidence and accelerate your growth with the guidance of our experienced mentors. Whether you are a beginner looking to establish a solid foundation or an experienced practitioner seeking to expand your skills, our mentorship program will empower you to become a proficient and successful data scientist.
            {' '}
          </p>

          <div style={{display: 'flex', justifyContent: 'center'}}>
            <Link
              to="/mentors?filter=data-science"
              className={'primaryBtn'}
              style={{
                boxShadow: 'none',
                display: 'flex',
                alignItems: 'center',
                width: 'fit-content',
                justifyContent: 'space-between',
                fontSize: '17px',
                borderRadius: '5px',
              }}
            >
              <span>Find Data Science Mentors</span>
              <span>
                <EastOutlinedIcon className={styles.linkIcon} />
              </span>
            </Link>

            <Link
              to="/mentors"
              className={'secondaryBtn'}
              style={{
                boxShadow: 'none',
                display: 'flex',
                alignItems: 'center',
                width: 'fit-content',
                justifyContent: 'space-between',
                fontSize: '17px',
                borderRadius: '5px',
              }}
            >
              <span>
                <SearchOutlinedIcon style={{marginRight: '10px'}} />
              </span>
              <span>Browse All</span>

            </Link>
          </div>
        </div>
      </div>
      {popularDevelopers && popularDevelopers.length > 0 && <div className={styles.popular__devSection}>
           
           
            <div className={styles.popular__devHeader}>
                <h1>Popular Data Science Developers</h1>
            </div>
            <div className={styles.popular__devCardWrapper}>
                {popularDevelopers.map((mentor,i)=>{
                    return(
                        <div className={styles.popular__devCard} key={i}>

                            <div className={styles.popular__devCardImg}>
                                <img src={mentor?.userDetails?.profilePicture ? mentor?.userDetails?.profilePicture : avatarImg} alt="img" />
                            </div>
                            <div className={styles.popular__devCardText}>
                                <h2>{mentor?.userDetails?.fullName ? mentor?.userDetails?.fullName : mentor?.username}</h2>
                                <h4>{mentor?.userDetails?.shortDescription}</h4>
                                <p>{mentor?.userDetails?.about?.slice(0,1000)}...</p>
                                <div className={styles.mentor__skills}>
                                    {mentor?.userDetails?.skills?.map((item)=>{
                                        return(<div className={styles.mentor__skillBadge}>{item}</div>)
                                    })}
                                </div>
                                <div className={styles.btnWrapper}>
                                <Link  style={{
                                        boxShadow: 'none',
                                        width: 'fit-content',
                                        fontSize: '17px',
                                        borderRadius: '5px',
                                    }} to={`/${mentor?.username}`} className={"secondaryBtn"}>See Profile</Link>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
           
                
            
      </div>
            }

           
     
    </div>
    <Footer />
    </>
  );
}

export default DataScience;
