import React, {useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import UserServices from '../../services/user.services';
import {getErrorMessage} from '../../util/GetError';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import moment from 'moment';
import {Link} from 'react-router-dom';
import {Chip, Tooltip} from '@mui/material';

function AllUsers () {
  const [allUsers, setAllUsers] = useState ([]);
  const [page, setPage] = React.useState (0);
  const [rowsPerPage, setRowsPerPage] = React.useState (10);

  const columns = [
    {id: 'createdAt', label: 'Created At', minWidth: 170},
    {id: 'id', label: 'id', minWidth: 170},
    {id: 'username', label: 'Username', minWidth: 100},
    {
      id: 'email',
      label: 'Email',
      minWidth: 170,
    },
    {
      id: 'role',
      label: 'Role',
    },
  ];

  const handleChangePage = (event, newPage) => {
    setPage (newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage (+event.target.value);
    setPage (0);
  };
  useEffect (() => {
    let isMounted = true;

    const getAllUsers = async () => {
      try {
        const response = await UserServices.getAllUsers ();
        // console.log (response.data);
        if (response.data) {
          let data = [];
          response.data.forEach (item => {
            let finalData = {
              createdAt: moment (item.createdAt).format (
                'MMMM Do YYYY, h:mm:ss a'
              ),
              id: item.id,
              username: item.username,
              email: item.email,
              role: item.Role.role,
            };
            data.push (finalData);
          });
          // console.log (data);
          setAllUsers (data);
        }
      } catch (err) {
        console.log (err);
        toast.error (getErrorMessage (err));
      }
    };
    if (isMounted) {
      getAllUsers ();
    }

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <div>
      <h1>All Users</h1>
      <Paper sx={{width: '100%', overflow: 'hidden'}}>
        <TableContainer sx={{maxHeight: 440}}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map (column => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{minWidth: column.minWidth}}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {allUsers
                .slice (page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map (row => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                    >
                      {columns.map (column => {
                        const value = row[column.id];
                        return column.id === 'id'
                          ? <TableCell key={column.id} align={column.align}>
                              <Link
                                style={{
                                  color: 'var(--primary)',
                                  textDecoration: 'none',
                                }}
                                to={`/dashboard/admin/user-details/${row[column.id]}`}
                              >
                                <Tooltip title="View Details">
                                  <b>
                                    {column.format && typeof value === 'number'
                                      ? column.format (value)
                                      : value}
                                  </b>
                                </Tooltip>
                              </Link>
                            </TableCell>
                          : column.id === 'role'
                              ? <TableCell key={column.id} align={column.align}>
                                  <Chip
                                    style={{
                                      backgroundColor: 'var(--primary)',
                                      color: 'white',
                                      minWidth: '100px',
                                    }}
                                    label={
                                      column.format && typeof value === 'number'
                                        ? column.format (value)
                                        : value
                                    }
                                  />

                                </TableCell>
                              : <TableCell key={column.id} align={column.align}>
                                  {column.format && typeof value === 'number'
                                    ? column.format (value)
                                    : value}
                                </TableCell>;
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={allUsers.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

    </div>
  );
}

export default AllUsers;
