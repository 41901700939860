import React, {useState} from 'react';
import hljs from 'highlight.js';
import 'highlight.js/styles/monokai-sublime.css';
import ReactQuill, {Quill} from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ImageResize from 'quill-image-resize-module-react';
import styles from './blog.module.css';
import {Button,Breadcrumb, Upload, Select} from 'antd';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import {TextField} from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import {toast} from 'react-toastify';
import {useAppSelector} from '../../hooks';
import UserServices from '../../services/user.services';
import {getErrorMessage} from '../../util/GetError';
import BlogServices from '../../services/blogs.services';
import { Link } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import {  ArticleOutlined } from '@mui/icons-material';

const {Dragger} = Upload;
Quill.register ('modules/imageResize', ImageResize);

const modules = {
  syntax: {highlight: text => hljs.highlightAuto (text).value},
  toolbar: {
    container: [
      [{font: []}],
      [{header: [1, 2, 3, 4, 5, 6, false]}],
      ['bold', 'italic', 'underline', 'strike'],
      [{color: []}, {background: []}],
      [{script: 'sub'}, {script: 'super'}],
      ['blockquote', 'code-block'],
      [{list: 'ordered'}, {list: 'bullet'}],
      [{indent: '-1'}, {indent: '+1'}, {align: []}],
      ['link', 'image', 'video'],
      ['clean'],
    ],
    handlers: {
      image: imageHandler,
    },
  },
  imageResize: {
    parchment: Quill.import ('parchment'),
    modules: ['Resize', 'DisplaySize'],
  },
};

function imageHandler () {
  const tooltip = this.quill.theme.tooltip;
  const originalSave = tooltip.save;
  const originalHide = tooltip.hide;

  tooltip.save = function () {
    const range = this.quill.getSelection (true);
    const value = this.textbox.value;
    if (value) {
      this.quill.insertEmbed (range.index, 'image', value, 'user');
    }
  };
  // Called on hide and save.
  tooltip.hide = function () {
    tooltip.save = originalSave;
    tooltip.hide = originalHide;
    tooltip.hide ();
  };
  tooltip.edit ('image');
  tooltip.textbox.placeholder = 'Embed URL';
}

function TabPanel (props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index &&
        <Box sx={{p: 3}}>
          {children}
        </Box>}
    </div>
  );
}

function a11yProps (index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function CreateBlog () {
  const [editorState, setEditorState] = useState ('');
  const [title, setTitle] = useState ('');
  const [description, setDescription] = useState ('');
  const [imgURL, setImgUrl] = useState ('');
  const [fileList, setFileList] = useState ([]);
  const [value, setValue] = useState (0);
  const [loading, setLoading] = useState (false);
  const [uploadBtnDisable, setUploadBtnDisable] = useState (false);
  const {userInfo} = useAppSelector (state => state.auth);
  const [tags,setTags] = useState([]);

  const tagsOption = [
    {label:'Technology',value:'technology'},
    {label:'Tourism',value:'tourism'},
    {label:'Programming',value:'programming'},
    {label:'Design',value:'design'},
    {label:'Fashion',value:'fashion'},
    {label:'Education',value:'education'}
  ]

  
  const items = [
    {
      title:  <>
      <HomeIcon sx={{mr: 0.5}} fontSize="inherit" />
      <Link to="/dashboard">Dashboard</Link>
      </>,
      key: 'Dashboard',
    },
    {
        title: <>
        <ArticleOutlined sx={{mr: 0.5}} fontSize="inherit"/>
        <Link to="/dashboard/blog">
          My Blogs
        </Link>
        </>,
        key:'MyBlogs'
    },
    {
        title: <span>Create Blog</span>,
        key:'CreateBlog'
    },

  ]

  const handleCreateBlog = async () => {
    // console.log (editorState);
    setLoading (true);
    let data = {
      userId: userInfo.id,
      title: title,
      description: description,
      imgURL: imgURL,
      content: editorState,
      tags:tags
    };
    console.log (data);
    try {
      const response = await BlogServices.createNewBlog (data);
      console.log (response.data);
      if (response.data) {
        toast.success ('New Blog Created Successfully!');
      }
      setLoading (false);
    } catch (err) {
      console.log (err);
      toast.error (getErrorMessage (err));
      setLoading (false);
    }
  };

  const handleChange = (event, newValue) => {
    setValue (newValue);
  };

  const handleFileUpload = async () => {
    console.log ('uploading: ', fileList);
    const file = fileList[0];
    setLoading (true);

    if (file) {
      try {
        var formData = new FormData ();
        formData.append ('userId', userInfo.id);
        formData.append (
          title ? title : file.name.split ('.')[0] + '__coderscolab',
          file
        );
        // console.log (formData);
        const response = await UserServices.updateProfilePic (formData);
        setImgUrl (response.data);
        console.log (response.data);
        setLoading (false);
        setFileList ([]);
        // fileRef.current.target.value = null;
      } catch (err) {
        console.log (err);
        toast.error (getErrorMessage (err));
        setLoading (false);
      }
    }
  };

  const beforeUpload = file => {
    console.log (file);
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';

    if (!isJpgOrPng) {
      toast.error ('You can only upload JPG/PNG file!');
      setUploadBtnDisable (true);
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      toast.error ('Image must smaller than 2MB!');
      setUploadBtnDisable (true);
    }

    if (file && isJpgOrPng && isLt2M) {
      setFileList ([file]);
    }
    return false;
  };

   const handleTagChange = (value) => {
    console.log(value);
    setTags(value);

  }; 
  return (
    <div>
      <h1>Create Your Blog</h1>
     <div className={styles.blog__formHeader}>
     <Breadcrumb items={items} />
      <Button
              size="large"
              loading={loading}
              disabled={!title || !description || !userInfo || !editorState || !imgURL}
              type="primary"
              onClick={handleCreateBlog}
            >
              Create
            </Button>
     </div>
      <Box sx={{width: '100%'}}>
        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
          <Tabs
            textColor="inherit"
            indicatorColor="secondary"
            value={value}
            onChange={handleChange}
            aria-label="create-blogs"
          >
            <Tab label="Basic Details" {...a11yProps (0)} />
            <Tab label="Blog Content" {...a11yProps (1)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <div>
            <h4>1. Add Basic Details</h4>
            <div>
              <TextField
                id="outlined-basic"
                label="Title"
                style={{margin: '10px', width: '45%'}}
                onChange={e => setTitle (e.target.value)}
                value={title}
              />
            </div>
            <div>
              <TextField
                id="multiline-outlined-basic"
                label="Description"
                multiline
                rows={4}
                style={{margin: '10px', width: '45%'}}
                onChange={e => setDescription (e.target.value)}
                value={description}
              />
            </div>
            <div   style={{
              margin: '10px',
              width: '45%',
            }}>
            <label>Add Some Tags: </label><br/>
            <Select
            mode="tags"
            style={{
              width: '100%',
            }}
            size="large"
            placeholder="e.g. Technology"
            onChange={handleTagChange}
            options={tagsOption}
          />
            </div>
            <div style={{width: '47%', padding: '10px'}}>
              <label>Select Thumnail Image: </label>
              <Dragger
                beforeUpload={beforeUpload}
                listType="picture-card"
                onRemove={() => {
                  setFileList ([]);
                }}
                fileList={fileList}
                name="file"
              >
                <p className="ant-upload-drag-icon">
                {imgURL ? <img src={imgURL} alt={title} style={{width:'70px'}} /> : <UploadFileIcon style={{fontSize: '4rem'}} />}
                </p>
                <p className="ant-upload-text">
                  Click or drag Image file to this area to upload
                </p>
                <p className="ant-upload-hint">
                  Make sure you select either JPG or PNG image file only
                </p>
              </Dragger>
              <br />
              <Button
                type="primary"
                onClick={handleFileUpload}
                loading={loading}
                disabled={
                  !fileList ||
                    fileList.length === 0 ||
                    loading ||
                    uploadBtnDisable
                }
              >
                Upload
              </Button>

            </div>
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <div className={styles.editor__area}>
            <h4>2. Add Blog Content</h4>
            <ReactQuill
              modules={modules}
              theme="snow"
              onChange={setEditorState}
              placeholder="Content goes here..."
            />

          
          </div>
        </TabPanel>
      </Box>

    </div>
  );
}

export default CreateBlog;
