import axios from '../util/axios.js';
import AuthHeader from './authHeader.js';

const createNewPayment = data => {
  return axios.post ('/api/payments/create-new-payment', data, {
    headers: AuthHeader (),
  });
};

const getAllPayments = () => {
  return axios.get ('/api/payments/get-all-payments', {
    headers: AuthHeader (),
  });
};

const getPaymentIntent = client_secret => {
  return axios.get ('/api/payments/intent/' + client_secret, {
    headers: AuthHeader (),
  });
};

const getAllUserPayments = id => {
  return axios.post (
    '/api/payments/get-user-payments',
    {userId: id},
    {
      headers: AuthHeader (),
    }
  );
};

const getSinglePaymentById = id => {
  return axios.post (
    '/api/payments/get-payment-by-id',
    {id: id},
    {
      headers: AuthHeader (),
    }
  );
};

const getSinglePaymentByBookingId = id => {
  return axios.post (
    '/api/payments/get-payment-by-booking-id',
    {id: id},
    {
      headers: AuthHeader (),
    }
  );
};

const updatePaymentStatus = data => {
  return axios.post ('/api/payments/update-payment-status', data, {
    headers: AuthHeader (),
  });
};

const PaymentServices = {
  createNewPayment,
  getAllPayments,
  getPaymentIntent,
  getAllUserPayments,
  getSinglePaymentById,
  updatePaymentStatus,
  getSinglePaymentByBookingId,
};

export default PaymentServices;
