
import {useLayoutEffect,useState,useEffect} from 'react';
import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Landing from './pages/Landing/Landing';
import Login from './pages/Login/Login';
import { Route, Routes } from 'react-router';
import Register from './pages/Register/Register';
import UserDashboard from './pages/Dashboard/UserDashboard';
import UserDashboardItem from './pages/Dashboard/UserDashboardItem';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import OAuthRedirecting from './pages/Login/OAuthRedirecting';
import LoggedInRoute from './routing/LoggedInRoute';
import NotFound from './pages/NotFound/NotFound';
import Profile from './pages/Profile/Profile';
import { useAppDispatch } from './hooks';
import { useGetUserDetailsQuery } from './services/authApi';
import { setCredentials } from './redux/features/authSlice';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import PublicProfile from './pages/PublicProfile/PublicProfile';
import AllUsers from './pages/admin/AllUsers';
import RouteForAdmin from './routing/AdminRoutes';
import SingleUserDetails from './pages/admin/SingleUserDetails';
import Sessions from './pages/Sessions/Sessions';
import CreateSession from './pages/Sessions/CreateSession';
import EditSession from './pages/Sessions/EditSession';
import BookingSession from './pages/BookingSession/BookingSession';
import PayNow from './pages/BookingSession/PayNow';
import PaymentSuccess from './components/CheckoutForm/PaymentSuccess';
import AllBookings from './pages/admin/AllBookings/AllBookings';
import AllPayments from './pages/admin/AllPayments/AllPayments';
import AllSessions from './pages/admin/AllSessions/AllSessions';
import UserPayments from './pages/UserPayments/UserPayments';
import UserBookings from './pages/UserBookings/UserBookings';
import BookingDetailsView from './pages/BookingDetailsView/BookingDetailView';
import PaymentDetails from './pages/PaymentDetails/PaymentDetails';
import About from './pages/About/About';
import Contact from './pages/Contact/Contact';
import Mentors from './pages/mentors/Mentors';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import TermsOfServices from './pages/TermsOfServices/TermsOfServices';
import WebDevelopment from './pages/filters/web-development';
import Design from './pages/filters/design';
import MachineLearning from './pages/filters/machine-learning';
import DataScience from './pages/filters/data-science';
import MyBlogs from './pages/MyBlogs/MyBlogs';
import UpdateBlog from './pages/MyBlogs/UpdateBlog';
import CreateBlog from './pages/MyBlogs/CreateBlog';
import BlogList from './pages/PublicBlog/BlogList';
import BlogDetails from './pages/PublicBlog/BlogDetails';


const darkTheme = createTheme({
  palette: {
    mode: 'light',
  },
});



function App() {
  const dispatch = useAppDispatch ();
  const [loading, setLoading] = useState(true); 
  
  // automatically authenticate user if token is found
  const {data} = useGetUserDetailsQuery ('userDetails', {
    // perform a refetch every 15mins
    pollingInterval: 90000,
  });
  // console.log (data);
 

  useEffect(()=>{
    setTimeout(() => setLoading(false), 1500);
  },[])



 useLayoutEffect (
    () => {
      // console.log(data);
      if (data) dispatch (setCredentials (data));
    },
    [data, dispatch]
  );

 if(loading){
   return null;
 }

  return (
    <ThemeProvider theme={darkTheme}>
 
    <div className="App">
      <Routes>
        <Route path='*' element={<NotFound />} />
        <Route path="/" element={ <Landing/> } />
        <Route path="/:username" element={ <PublicProfile/> } />
        <Route path="/:username/:sessionId" element={ <BookingSession/> } />
        <Route path="/mentors/filters/web-development" element={ <WebDevelopment/> } />
        <Route path="/mentors/filters/data-science" element={ <DataScience/> } />
        <Route path="/mentors/filters/machine-learning" element={ <MachineLearning/> } />
        <Route path="/mentors/filters/design" element={ <Design/> } />
        <Route path="/mentors" element={ <Mentors/> } />
       
        <Route path="/privacy-policy" element={ <PrivacyPolicy/> } />
        <Route path="/terms-of-services" element={ <TermsOfServices/> } />
        <Route path="/payment/:sessionId/:bookingId/:clientSecret" element={ <PayNow/> } />
        <Route path="/payment/success/:bookingId/:userId" element={ <PaymentSuccess/> } />
        <Route path="login" element={ <Login/> } />
        <Route path="register" element={ <Register/> } />
        <Route path="about" element={ <About/> } />
        <Route path="contact" element={ <Contact/> } />
        <Route path="blog" element={ <BlogList/> } />
        <Route path="blog/details/:id" element={ <BlogDetails/> } />
        <Route path="/oauth-redirect" element={ <OAuthRedirecting/> } />
        <Route path='/dashboard' element={<LoggedInRoute><UserDashboard /></LoggedInRoute>}>
            <Route index element={<LoggedInRoute><UserDashboardItem/></LoggedInRoute>} />
            <Route path='profile' element={<LoggedInRoute><Profile /></LoggedInRoute>} />
            <Route path='sessions' element={<LoggedInRoute><Sessions /></LoggedInRoute>} />
            <Route path='sessions/create' element={<LoggedInRoute><CreateSession /></LoggedInRoute>} />
            <Route path='sessions/edit/:id' element={<LoggedInRoute><EditSession /></LoggedInRoute>} />
            <Route path='payments' element={<LoggedInRoute><UserPayments /></LoggedInRoute>} />
            <Route path='blog' element={<LoggedInRoute><MyBlogs /></LoggedInRoute>} />
            <Route path='blog/create' element={<LoggedInRoute><CreateBlog /></LoggedInRoute>} />
            <Route path='blog/update/:id' element={<LoggedInRoute><UpdateBlog /></LoggedInRoute>} />
            <Route path='payments/:id' element={<LoggedInRoute><PaymentDetails /></LoggedInRoute>} />
            <Route path='bookings' element={<LoggedInRoute><UserBookings /></LoggedInRoute>} />
            <Route path='bookings/:id' element={<LoggedInRoute><BookingDetailsView /></LoggedInRoute>} />
            <Route path='admin/all-users' element={<LoggedInRoute><RouteForAdmin><AllUsers /></RouteForAdmin></LoggedInRoute>}/>
            <Route path='admin/user-details/:id' element={<LoggedInRoute><RouteForAdmin><SingleUserDetails /></RouteForAdmin></LoggedInRoute>}/>
            <Route path='admin/all-bookings' element={<LoggedInRoute><RouteForAdmin><AllBookings /></RouteForAdmin></LoggedInRoute>}/>
            <Route path='admin/all-payments' element={<LoggedInRoute><RouteForAdmin><AllPayments /></RouteForAdmin></LoggedInRoute>}/>
            <Route path='admin/all-sessions' element={<LoggedInRoute><RouteForAdmin><AllSessions /></RouteForAdmin></LoggedInRoute>}/>
        
        </Route>
      </Routes>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        />
    </div>
  </ThemeProvider>);
}

export default App;
