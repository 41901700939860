import React,{useState,useEffect} from 'react';
import styles from './Login.module.css';
import avatar1 from '../../assets/man.jpg';
import avatar2 from '../../assets/woman.jpg';
import google from '../../assets/google.png';
import { Facebook, Instagram, LinkedIn, Twitter, Visibility, VisibilityOff } from '@mui/icons-material';
import { CircularProgress, IconButton, InputAdornment, TextField,Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import {useNavigate} from 'react-router';
import { toast } from 'react-toastify';
import { getErrorMessage } from '../../util/GetError';
import { userLogin } from '../../redux/actions/authActions';
import { setSuccess } from '../../redux/features/authSlice';
import { useAppDispatch,useAppSelector } from '../../hooks';
import UserServices from '../../services/user.services';
function Login() {
const [showPassword, setShowPassword] = useState(false);
const [isLoading, setIsLoading] = useState(false);
const [email,setEmail] = useState("");
const [password,setPassword] = useState("");
const handleClickShowPassword = () => setShowPassword(!showPassword);
const handleMouseDownPassword = () => setShowPassword(!showPassword);
const [isOtpSent,setIsOtpSent] = useState(false);
const {userInfo,success,error} = useAppSelector(state=>state.auth);
const [otp,setOtp] = useState("");
const navigate = useNavigate();
const dispatch = useAppDispatch()

const handleLogin = async ()=>{
  let data = {
    email:email,
    password:password
  }
  // console.log("login");
  setIsLoading(true);
  try{
     await dispatch(userLogin(data))
    // console.log(response);
    setIsLoading(false);
    
  }catch(err){
    console.log(err);
    toast.error(getErrorMessage(err));
    setIsLoading(false);
  }

}

const handleSendOtp = async ()=>{
    try{
      const response = await UserServices.sendOtpForVerification(email);
      if(response.data){
        setIsOtpSent(true);
        toast.info("Please Check OTP on your mail!");
      }
    }catch(err){
      console.log(err);
      toast.error(getErrorMessage(err));
    }
}

useEffect(()=>{
  if(success && userInfo){
    toast.success('Logged in successfully!');
    dispatch(setSuccess(false))
    navigate('/dashboard');
  }
},[success,navigate,dispatch,userInfo])

useEffect(()=>{
  if(error){
    toast.error(getErrorMessage(error));
    
  }
},[error])



const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>)=>{
  // console.log(e.target.value);
  setEmail(e.target.value)
}

const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>)=>{
  // console.log(e.target.value);
  setPassword(e.target.value)
}


const handleOtpChange =  (e: React.ChangeEvent<HTMLInputElement>)=>{
  // console.log(e.target.value);
  setOtp(e.target.value)
}


const handleVerifyOtp = async ()=>{
  setIsLoading(true);
  try{
    const response = await UserServices.verifyOtp({email:email,otp:otp});
    console.log(response.data);
    if(response.data){
      toast.success("Account verified successfully!");
      toast.success("You can login now!");
      setIsOtpSent(false);
      setIsLoading(false);
      
    }
  }catch(err){
    console.log(err);
    setIsLoading(false);
    toast.error(getErrorMessage(err));
  }
}


const handleGoogleLogin = async ()=>{
  try{
      window.open("https://coderscolab.com/google", "_self")
    // console.log(response);
  }catch(err){
    console.log(err);
    toast.error(getErrorMessage(err));
  }
}
  return (
    <div className={styles.login__wrapper}>
      <div className={styles.login__form}>
          <div className={styles.login__logoWrapper}>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: {xs: 'none', md: 'flex'},
              fontFamily: 'monospace',
              fontWeight: 700,
              color: 'var(--foreTextColor)',
              textDecoration: 'none',
              fontSize:'1.5rem',
              marginTop:'-7px'

            }}
          >
           
            <span>CODERSCOLAB<span className={styles.logo__dot}>.</span></span>
          </Typography>
          </div>
          <h3>Login</h3>
          {isOtpSent ? 
          <div className={styles.form}>
            <div className={styles.form__el}>
             
             <TextField
             label="Enter OTP"
             inputProps={{style: {color:'white'}}}                
             onChange={handleOtpChange}
             value={otp}
             size="small" 
             sx={{width:'100%',margin:'7px'}}
           />
             </div>
             <div className={styles.form__el} style={{paddingLeft:'7px',paddingTop:'7px'}}>
                <button disabled={isLoading || !otp } style={{borderRadius:'5px',width:'102%',margin:0}} className="primaryBtn" onClick={handleVerifyOtp}>{isLoading ? <CircularProgress color="inherit" size="1rem" /> : 'Verify OTP'}</button>
              
              </div>
          </div>
          :
          <div className={styles.form}>
              <div className={styles.form__el}>
             
              <TextField
              label="Email"
              type={"email"}
              inputProps={{style: {textTransform: 'lowercase',color:'white'}}}                
              onChange={handleEmailChange}
              value={email}
              size="small" 
              sx={{width:'100%',margin:'10px'}}
            />
              </div>
              <div className={styles.form__el}>
              <TextField
                label='Password'
               
                sx={{width:'100%',margin:'10px'}}
               
                size={"small"}
                value={password}
                onChange={handlePasswordChange}
                type={showPassword ? "text" : "password"} // <-- This is where the magic happens
                InputProps={{
                  style: {color:'white'},
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility style={{color:'white'}} /> : <VisibilityOff style={{color:'white'}} />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
             
              </div>
              <div className={styles.form__el} style={{paddingLeft:'10px',paddingTop:'10px'}}>
                <button disabled={isLoading || !email || !password} style={{borderRadius:'5px',width:'45%',margin:'10px'}} className="primaryBtn" onClick={handleLogin}>{isLoading ? <CircularProgress color="inherit" size="1rem" /> : 'Login'}</button>
                <button disabled={!email} style={{borderRadius:'5px',width:'45%',margin:'10px'}} className="secondaryBtn" onClick={handleSendOtp}>Verify Account</button>
              </div>

              <div className={styles.form__el} style={{paddingLeft:'10px'}}>
                 <span>Not Registered Yet? </span>
                 <Link to='/register'>Create an Account</Link>
                
              </div>
              
              <div className={styles.form__el} style={{paddingLeft:'10px',textAlign:'center'}}>
              <h5 style={{color:'gray'}}>Or</h5>
                <div className={styles.googleLoginWrapper} onClick={handleGoogleLogin}>
                  <img src={google} alt="g" />
                  <h4>Sign in with Google</h4>
                </div>
              </div>

          </div>
}
      </div>
      <div className={styles.login__right}>
          <div className={styles.company__info}>
         
           <h2>Welcome Back!</h2>
           <small>Codercolab is a trusted website to give you the right mentors for helping out in your career.</small>
          </div>
          <div className={styles.social__icons}>
            <a href="/">
              <Facebook />
            </a>
            <a href="/">
              <Instagram />
            </a>
            <a href="/">
              <Twitter />
            </a>
            <a href="/">
              <LinkedIn />
            </a>
          </div>
          <div className={styles.login__rightBox}>
              <img src={avatar1} alt="av1"/>
              <h2><span style={{color:'var(--primary)'}}>Get</span> Help from <span style={{color:'var(--primary)'}}>Experts</span></h2>
          </div>
          <div className={`${styles.login__rightBox} ${styles.login__rightBox2}`}>
              <img src={avatar2} alt="av1"/>
              <h2><span style={{color:'var(--primary)'}}>Solve</span> your doubts </h2>
          </div>
      </div>
    </div>
  )
}

export default Login