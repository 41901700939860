import React, {useState, useEffect} from 'react';
import {toast} from 'react-toastify';
import BookingServices from '../../../services/booking.services';
import {getErrorMessage} from '../../../util/GetError';
import styles from '../../UserBookings/UserBookings.module.css';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import {Menu,Input, Table, Dropdown} from 'antd';
import moment from 'moment';
import {Link} from 'react-router-dom';

function AllBookings () {
  const [allBookings, setAllBookings] = useState ([]);
  const [filteredBookings,setFilteredBookings] = useState([]);
  const [filteredTypes,setFilteredTypes] = useState([]); 

  useEffect (() => {
    let isMounted = true;
    const getAllBookings = async () => {
      try {
        const response = await BookingServices.getAllBookings ();
        console.log (response.data);
        setAllBookings (response.data);
        let filterTypes = [];
        response.data?.forEach (o => {
                if (o?.Session?.title) {
                filterTypes.push ({text: o.Session.title, value: o.Session.title});
                }
            });
          filterTypes = filterTypes.filter ((item, index, self) => {
            return (
            index ===
            self.findIndex (t => t.text === item.text && t.value === item.value)
            );
        });
        setFilteredTypes(filterTypes);
      } catch (err) {
        console.log (err);
        toast.error (getErrorMessage (err));
      }
    };

    if (isMounted) {
      getAllBookings ();
    }

    return () => {
      isMounted = false;
    };
  }, []);


  const columns = [
    {
      title: 'Created At',
      key:'createdAt',
      sorter: (a, b) => moment(a.createdAt) - moment(b.createdAt),
      defaultSortOrder: 'descend',
      render: (record) => <b>{moment(record.createdAt).format('YYYY-MM-DD hh:mm:ss')}</b>,
    },
    {
      title: 'ID',
      key:'id',
      render: (record) => <Link to={`/dashboard/bookings/${record.id}`}><b>{record.booking_id}</b></Link>,
    },
    {
      title: 'Booking Date',
      dataIndex: 'date',
      key: 'date',
      render: (record) => <b>{moment(record.date).format('YYYY-MM-DD')}</b>,
    },
    {
      title: 'Time',
      key: 'time',
      render: (record) => <b>{record.time}</b>,
    },
    {
      title: 'Session',
      key: 'session',
      filters:filteredTypes,
      onFilter: (value, record) => record.Session?.title.indexOf(value) === 0,
      render: (record) => <b>{record?.Session?.title}</b>,
     
    },
    {
      title: 'Client Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <div className={styles.btnWrapper}>
        {/* <Link to={`/dashboard/payments/${record.id}`}>View Details</Link>
        <Tooltip title={`${record.status ? 'Payment Not Confirmed' : 'Confirm Payment'}`}>
        <button className={styles.tableBtn} onClick={()=>handlePaymentStatusUpdate(record)}>{record?.status ? 'Not Confirm':'Confirm'}</button>
        </Tooltip> */}
        <Dropdown
      overlay={
        <Menu>
          <Menu.Item key="1">
          <Link to={`/dashboard/bookings/${record.id}`}>View Details</Link>
          </Menu.Item>
        </Menu>
      }
      placement="bottomLeft"
      arrow
    >
     <MoreVertOutlinedIcon />
    </Dropdown>

        </div>
      ),
    },

  ];

  const handleSearch = (e)=>{
    let query = e.target.value;

    let filteredList = allBookings.filter((item)=>item?.booking_id?.toLowerCase().match(query.toLowerCase()))
  setFilteredBookings(filteredList);
  }

  return (
    <div>
        <div style={{display:'flex',marginBottom:'1rem',justifyContent:'space-between',alignItems:'center'}}>
        <h2>All Bookings</h2>
        <Input style={{maxWidth:'300px'}} placeholder='Search by ID' onChange={handleSearch} />
      </div>
      <Table columns={columns} dataSource={filteredBookings.length > 0 ? filteredBookings : allBookings} />
    </div>
  );
}

export default AllBookings;
