import React from 'react';

function Avatar({src, size}) {
  return (
    <img
      src={src}
      alt="..."
      style={{width:`${size}px`, height:`${size}px`, borderRadius: `${size}px`}}
    />
  );
}

export default Avatar;
