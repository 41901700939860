import React, {useEffect} from 'react';
import styles from './CheckoutForm.module.css';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {useSearchParams} from 'react-router-dom';
import PaymentServices from '../../services/payments.services';
import {useParams} from 'react-router';
import BookingServices from '../../services/booking.services';

function PaymentSuccess () {
  const [searchParams] = useSearchParams ();
  const params = useParams ();
  const {bookingId,userId} = params;

  useEffect (
    () => {
      let isMounted = true;
      console.log (searchParams);
      const clientSecret = searchParams.get ('payment_intent');
      console.log (clientSecret);
      console.log (bookingId);
      const savePaymentDetails = async () => {
        try {
          if (clientSecret) {
            const paymentIntentResponse = await PaymentServices.getPaymentIntent (
              clientSecret
            );
            console.log (paymentIntentResponse);
            const currentPaymentIntent = paymentIntentResponse.data;
            if(paymentIntentResponse){
              const updatedBooking = await BookingServices.updateUserBookings({id:bookingId,updatedData:{paymentCompleted:true}});
              console.log(updatedBooking.data);
            }

            let paymentData ={
              amount:parseFloat(currentPaymentIntent?.amount)/100,
              currency:currentPaymentIntent?.currency,
              transaction_id:clientSecret,
              paymentMethod:currentPaymentIntent.paymentMethod,
              type:"Stripe",
              BookingId:bookingId,
              UserId:userId,
              status:true
            }
            console.log(paymentData);
            const paymentResponse = await PaymentServices.createNewPayment(paymentData);
            console.log(paymentResponse.data);
          }
        } catch (err) {
          console.log (err);
        }
      };

      if (clientSecret && bookingId && isMounted && userId) {
        savePaymentDetails ();
      }



      return () => {
        isMounted = false;
      };
    },
    [searchParams, bookingId,userId]
  );
  return (
    <div className={styles.paymentSuccessCard}>
      <CheckCircleOutlineIcon
        style={{fontSize: '6rem'}}
        className={styles.paymentSuccessCardIcon}
      />
      <h2>Payment Successful</h2>
      <p>You will get the payment details shortly on your provided email</p>
      <br />
      <a href="/dashboard" className={styles.btn}>Go to Dashboard </a>
    </div>
  );
}

export default PaymentSuccess;
