import {useEffect} from 'react'
import { useSearchParams } from 'react-router-dom';
import {useNavigate} from 'react-router';
import { toast } from 'react-toastify';
import { getErrorMessage } from '../../util/GetError';
import { userGoogleLoginWithToken } from '../../redux/actions/authActions';
import { useAppDispatch,useAppSelector } from '../../hooks';


function OAuthRedirecting() {
    const params = useSearchParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const user = useAppSelector((state)=>state.auth.userInfo);
    const error = useAppSelector((state)=>state.auth.error);

    useEffect(()=>{
        const token = params[0].get('token');
        const getUserDetails = async (token:string)=>{
            await dispatch(userGoogleLoginWithToken({token}));
         
        }
        if(token){
          getUserDetails(token);
        }
    },[params,navigate,dispatch]);
    useEffect(()=>{
      if(user){
        navigate('/dashboard');
      }
    },[user,navigate]);
    useEffect(()=>{
      if(error){
        console.log(error);
        toast.error(getErrorMessage(error));
        navigate('/login');
      }
    },[error,navigate])
  return (
    <div>
        Redirecting....
    </div>
  )
}

export default OAuthRedirecting