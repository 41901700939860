import axios from '../util/axios.js';
import AuthHeader from './authHeader.js';

type userId = {
    id:string
}

type duplicateUserType = {
    id:string,
    username:string
}

type otpVerifyData = {
    email:string,
    otp:string
}

interface PostFileFormDataBody{
    [key: string]: string | Blob;
  }

type userUpdateDetails = {
    updatedData:{
        username?:string,
        email?:string,
        phoneNumber?:string,
        address?:string,
        password?:string
    },
    id:string
   
}



const getUserDetailsById = (id:userId)=>{
    return axios.get ('/api/user/details/'+id,{
        headers:AuthHeader()
    })
}

const updateUserDetails = (data:userUpdateDetails)=>{
    return axios.post('/api/user/update-user-details',data,{
        headers:AuthHeader()
    })
}

const updateProfilePic = (data:PostFileFormDataBody) => {
    // console.log(data);
    return axios.post ('/api/user/upload-profile-pic', data, {
      headers: AuthHeader (),
    });
  };

const deleteUserById = (id:string)=>{
    return axios.post ('/api/user/delete-user', {userId:id}, {
        headers: AuthHeader (),
      });
}

const checkDuplicateUsername = (data:duplicateUserType)=>{
    return axios.post ('/api/user/check-duplicate-username', data, {
        headers: AuthHeader (),
      });
}

const getUserDetailsByUsername = (username:string)=>{
    return axios.post ('/api/user/public-user-details',{username:username},{
        headers:AuthHeader()
    })
}

const getAllUsers = ()=>{
    return axios.get ('/api/user/get-all-users',{
        headers:AuthHeader()
    })
   
}

const verifyOtp = (data:otpVerifyData)=>{
    return axios.post ('/api/user/verify-otp',data,{
        headers:AuthHeader()
    })
}


const getAllMentors = ()=>{
    return axios.get('/api/user/get-all-mentors',{
        headers:AuthHeader()
    });
}

const getPopularDevelopers = (category:string)=>{
    return axios.post('/api/user/get-popular-mentors',{category:category},{
        headers:AuthHeader()
    });
}

const sendOtpForVerification = (email:string)=>{
    return axios.post('/api/user/send-otp-on-login',{email:email},{
        headers:AuthHeader()
    });
    
}

const UserServices = {
    getUserDetailsById,
    updateUserDetails,
    updateProfilePic,
    checkDuplicateUsername,
    getUserDetailsByUsername,
    getAllUsers,
    deleteUserById,
    getAllMentors,
    getPopularDevelopers,
    verifyOtp,
    sendOtpForVerification
}

export default UserServices;