import React from 'react';
import {Navigate} from 'react-router';
import {useSelector} from 'react-redux';

function RouteForAdmin({children}) {
  const {userInfo} = useSelector (state => state.auth);
  const {role} = userInfo.role;
  return role === 'admin' ? children : <Navigate to="/dashboard" />;
}

export default RouteForAdmin;
