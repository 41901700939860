import { createSlice } from '@reduxjs/toolkit';
import { registerUser, userGoogleLoginWithToken, userLogin } from '../actions/authActions';


// initialize userToken from local storage
const userToken = localStorage.getItem('userToken')
  ? localStorage.getItem('userToken')
  : null

 

type initialStateType = {
  loading:boolean,
  userInfo:object | null,
  userToken:string | null,
  error:string | null | unknown,
  success:boolean
}

const initialState:initialStateType = {
  loading: false,
  userInfo: null, // for user object
  userToken: userToken, // for storing the JWT
  error: null,
  success: false, // for monitoring the registration process.
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      // ...logout reducer
      state.userInfo=null
      state.userToken = null
      localStorage.removeItem('userToken')
    
    },
    setCredentials: (state, { payload }) => {
      state.userInfo = payload
    },
    setSuccess:(state,{payload})=>{
      state.success = payload
    },
  },
 extraReducers: (builder)=>{

  //login user reducer
  builder.addCase(userLogin.pending, (state, {payload}) => {
    state.loading = true
   state.error = null
 })
  builder.addCase(userLogin.fulfilled, (state, { payload }) => {
    // console.log(payload);
    state.loading = false
    state.success = true // login successful
    state.userInfo = payload
    state.userToken = payload.token
  })
 
  builder.addCase(userLogin.rejected, (state, {payload}) => {
    state.loading = false
    state.error = payload
  });


  // //login with token
  //   builder.addCase(userLoginWithToken.pending, (state, {payload}) => {
  //     state.loading = true
  //    state.error = null
  //  })
  //   builder.addCase(userLoginWithToken.fulfilled, (state, { payload }) => {
  //     // console.log(payload);
  //     state.loading = false
  //     state.success = true // login successful
  //     state.userInfo = payload
  //     state.userToken = payload.token
  //   })
   
  //   builder.addCase(userLoginWithToken.rejected, (state, {payload}) => {
  //     state.loading = false
  //     state.error = payload
  //   });

    //login with google token user reducer
    builder.addCase(userGoogleLoginWithToken.pending, (state, {payload}) => {
      state.loading = true
     state.error = null
   })
    builder.addCase(userGoogleLoginWithToken.fulfilled, (state, { payload }) => {
      console.log(payload);
      state.loading = false
      state.success = true // login successful
      state.userInfo = payload.userInfo
      state.userToken = payload.token
    })
   
    builder.addCase(userGoogleLoginWithToken.rejected, (state, {payload}) => {
      state.loading = false
      state.error = payload
    })


   //register user reducer
    builder.addCase(registerUser.pending, (state, {payload}) => {
      state.loading = true
     state.error = null
   })
    builder.addCase(registerUser.fulfilled, (state, { payload }) => {
      state.loading = false
      state.success = true // registration successful
    })
   
    builder.addCase(registerUser.rejected, (state, {payload}) => {
      state.loading = false
      state.error = payload
    })
  },
})
export const { logout, setCredentials,setSuccess } = authSlice.actions
export default authSlice.reducer