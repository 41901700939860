import React from 'react'
import notFound from '../../assets/pageNotFound.svg';
import {Link} from 'react-router-dom';
function NotFound() {
  return (
    <div className="unauthorized">
        <img src={notFound} alt="Not Found" />
        <p>Page Not Found</p>
        <Link to="/" className="primaryBtn">Home</Link>
    </div>
  )
}

export default NotFound