import React, {useEffect, useState} from 'react';
import styles from './Sessions.module.css';
import {useSearchParams} from 'react-router-dom';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import {useNavigate} from 'react-router';
import ContactsIcon from '@mui/icons-material/Contacts';
import MessageIcon from '@mui/icons-material/Message';
import PeopleIcon from '@mui/icons-material/People';
import RequestPageIcon from '@mui/icons-material/RequestPage';
import {OutlinedInput, TextField,CircularProgress, Tooltip, Checkbox} from '@mui/material';
import { useAppSelector } from '../../hooks';
import { currencyArr } from '../../util/currency';
import {DatePicker,TimePicker} from 'antd';
import { toast } from 'react-toastify';
import { getErrorMessage } from '../../util/GetError';
import SessionServices from '../../services/sessions.services';
import { Create, HelpOutlined, PlusOneOutlined } from '@mui/icons-material';
import {Modal} from 'antd';



function CreateSession () {
  const params = useSearchParams ();
  const [sessionType, setSessionType] = useState ('');
  const [defaultSessionType, setDefaultSessionType] = useState ('');
  const [title, setTitle] = useState ('');
  const [currency,setCurrency] = useState('');
  const {userInfo} = useAppSelector (state => state.auth);
  const [price,setPrice] = useState('200');
  const [isCustomPrice,setIsCustomPrice] = useState(false);
  const [duration,setDuration] = useState('30');
  const [isCustomDuration,setIsCustomDuration] = useState(false);
  const [webinarDate,setWebinarDate] = useState("");
  const [webinarTime,setWebinarTime] = useState("");
  const [loading,setLoading] = useState(false);
  const [isEditEnabled,setIsEditEnabled] = useState(null);
  const [inviteeQuestions,setInviteeQuestions] = useState([]);
  const [currentInviteeQuestion,setCurrentInviteeQuestion] = useState("");
  const [isAddingInviteeQuiz,setIsAddingInviteeQuiz] = useState(false);
  const [currentInviteeQuizRequired,setCurrentInviteeQuizRequired] = useState(false);
  const [editQuiz,setEditQuiz] = useState("");
  const [isEditQuizRequired,setIsEditQuizRequired] = useState(false);
  const [isEditQuizModal,setIsEditQuizModal] = useState(false);
  const [currentEditQuiz,setCurrentEditQuiz] = useState("");


  
  const navigate = useNavigate ();
  useEffect (
    () => {
      if (params) {
        let type = params[0].get ('type');
        // console.log (type);
        setDefaultSessionType (type);
      }
    },
    [params]
  );

  useEffect (
    () => {
      setSessionType (defaultSessionType);
    },
    [defaultSessionType]
  );

  useEffect(()=>{
    if(userInfo?.userDetails?.currency){
      let currencyItem = currencyArr.find((item)=>{
        return item.name === userInfo?.userDetails?.currency
      });
      if(currencyItem){
        setCurrency(currencyItem)
      }
    }
  },[userInfo])

  const handleBack = () => {
    navigate ('/dashboard/sessions');
  };
  const handlePrice = (value)=>{
    if(value==='custom'){
      setPrice('');
      setIsCustomPrice(prev=>!prev);
      return;
    }
    setIsCustomPrice(false);
    setPrice(value)
  }

  const handleDuration = (value)=>{
    if(value==='custom'){
      setDuration('');
      setIsCustomDuration(prev=>!prev);
      return;
    }
    setIsCustomDuration(false);
    setDuration(value)
  }

  const handleChangeSessionType = type => {
    setSessionType (type);
  };

  const handleDate = (date,dateString)=>{
    // console.log(date, dateString);
    setWebinarDate(dateString)
  }

  const handleTime = (time,timeString)=>{
    // console.log(time, timeString);
    setWebinarTime(timeString)
  }

  const handleCreateSession = async ()=>{
    let finalData = {
      title:title,
      price:price,
      webinarDate:webinarDate,
      webinarTime:webinarTime,
      type:sessionType,
      duration:duration,
      UserId:userInfo.id,
      inviteeQuestions:inviteeQuestions
    }
    // console.log(finalData);
    setLoading(true);
    try{
      const response = await SessionServices.createNewSession(finalData);
      console.log(response.data);
      toast.success("Session Added Successfully!");
      setLoading(false);
      setIsEditEnabled(response.data.id);

    }catch(err){
      console.log(err);
      setLoading(false);
      toast.error(getErrorMessage(err));
    }
  }

  const handleEdit = ()=>{
   if(isEditEnabled){
    navigate('/dashboard/sessions/edit/'+isEditEnabled);
   }

  }

  const handleAddQuestionModal = ()=>{
    setIsAddingInviteeQuiz(true);
  }

  const handleAddInviteeQuestion = ()=>{
    let data = {
      id:inviteeQuestions.length + 1,
      quiz:currentInviteeQuestion,
      isRequired:currentInviteeQuizRequired
    }
    setInviteeQuestions((prev)=>[...prev,data]);
    handleCancelInviteeQuestion();
  }
  const handleCancelInviteeQuestion = ()=>{
    setCurrentInviteeQuestion("");
    setCurrentInviteeQuizRequired(false);
    setIsAddingInviteeQuiz(false);
  }

  const handleEditQuiz = (item)=>{
    setCurrentEditQuiz(item);
    setEditQuiz(item.quiz);
    setIsEditQuizRequired(item.isRequired);
    setIsEditQuizModal(true);

  }

  const handleAddUpdatedQuiz = ()=>{
    let data = {
      ...currentEditQuiz,
      quiz:editQuiz,
      isRequired:isEditQuizRequired
    }
    let foundQuizIndex = inviteeQuestions.findIndex(item=>item.id===data.id);
    let updatedQuiz = {...inviteeQuestions[foundQuizIndex],...data};
    const newQuiz = [...inviteeQuestions];
    newQuiz[foundQuizIndex] = updatedQuiz;
    // console.log(newQuiz);
    setInviteeQuestions(newQuiz);
   handleCancelEditQuizModal();
   
  }

  const handleCancelEditQuizModal = ()=>{
    setEditQuiz("");
    setIsEditQuizRequired(false);
    setCurrentEditQuiz('');
    setIsEditQuizModal(false);
  }
  return (
    <div className={styles.createSessionWrapper}>
      <div className={styles.createSessionHeader}>
        <KeyboardBackspaceIcon
          onClick={handleBack}
          className={styles.backIcon}
        />
        <h1>Add New Session</h1>
      </div>

      <div className={styles.createSessionBody}>
        <h3>Select Type</h3>
        <div className={styles.sessionTypeWrapper}>
          <div
            onClick={() => handleChangeSessionType ('video')}
            className={`${styles.sessionType} ${sessionType === 'video' && styles.activeSessionType}`}
          >
            <div className={styles.sessionTypeHeader}>
              <ContactsIcon className={styles.sessionTypeIcon} />
              <h3>1:1 Call</h3>
            </div>
            <small>Share your time over a meeting</small>

          </div>
          <div
            onClick={() => handleChangeSessionType ('textQuery')}
            className={`${styles.sessionType} ${sessionType === 'textQuery' && styles.activeSessionType}`}
          >
            <div className={styles.sessionTypeHeader}>
              <MessageIcon className={styles.sessionTypeIcon} />
              <h3>Text Query</h3>
            </div>
            <small>Answer a Question</small>

          </div>

          <div
            onClick={() => handleChangeSessionType ('webinar')}
            className={`${styles.sessionType} ${sessionType === 'webinar' && styles.activeSessionType}`}
          >
            <div className={styles.sessionTypeHeader}>
              <PeopleIcon className={styles.sessionTypeIcon} />
              <h3>Webinar</h3>
            </div>
            <small>Host a Session for Masses</small>

          </div>

          <div
            onClick={() => handleChangeSessionType ('document')}
            className={`${styles.sessionType} ${sessionType === 'document' && styles.activeSessionType}`}
          >
            <div className={styles.sessionTypeHeader}>
              <RequestPageIcon className={styles.sessionTypeIcon} />
              <h3>Document</h3>
            </div>
            <small>Sell Digital Products</small>

          </div>

          <div
            onClick={() => handleChangeSessionType ('package')}
            className={`${styles.sessionType} ${sessionType === 'package' && styles.activeSessionType}`}
          >
            <div className={styles.sessionTypeHeader}>
              <RequestPageIcon className={styles.sessionTypeIcon} />
              <h3>Package</h3>
            </div>
            <small>Offer Multiple Services Together</small>

          </div>
        </div>

        <div className={styles.sessionFormItem}>
          <label>
            Title
          </label>
          <OutlinedInput
            value={title}
            onChange={e => setTitle (e.target.value)}
            placeholder="e.g. Code Walkthrough"
            size="small"
          />
        </div>

        {(sessionType==='video' || sessionType==='webinar') &&
        <>
        <div className={styles.sessionFormItem}>
          <label>
            Call Duration (mins)
          </label>
          <div className={styles.sessionFormItem__priceWrapper}>
            <div onClick={()=>handleDuration('15')} className={`${styles.sessionFormItem__priceItem} ${duration==='15' && styles.activePrice}`}>
                 15 Mins
            </div>

            <div onClick={()=>handleDuration('30')} className={`${styles.sessionFormItem__priceItem} ${duration==='30' && styles.activePrice}`}>
            30 Mins
            </div>

            <div onClick={()=>handleDuration('45')} className={`${styles.sessionFormItem__priceItem} ${duration==='45' && styles.activePrice}`}>
                45 Mins
            </div>

            <div onClick={()=>handleDuration('60')} className={`${styles.sessionFormItem__priceItem} ${duration==='60' && styles.activePrice}`}>
                60 Mins
            </div>

            <div onClick={()=>handleDuration('custom')} className={`${styles.sessionFormItem__priceItem} ${isCustomDuration && styles.activePrice}`}>
                Custom
            </div>

          </div>
         
        </div>

       { isCustomDuration && 
            <div  className={styles.sessionFormItem}>
            <label>
              Custom Duration
            </label>
            <TextField
           type="number"
            value={duration}
            onChange={e => setDuration (e.target.value)}
            placeholder="e.g. 40"
            size="small"
          />
            </div>}
        </>
          
        }

        {sessionType !=='package' &&
        <>
        <div className={styles.sessionFormItem}>
          <label>
            Price ({currency?.name})
          </label>
          <div className={styles.sessionFormItem__priceWrapper}>
            <div onClick={()=>handlePrice('100')} className={`${styles.sessionFormItem__priceItem} ${price==='100' && styles.activePrice}`}>
                {currency?.symbol} 100
            </div>

            <div onClick={()=>handlePrice('200')} className={`${styles.sessionFormItem__priceItem} ${price==='200' && styles.activePrice}`}>
                {currency?.symbol} 200
            </div>

            <div onClick={()=>handlePrice('500')} className={`${styles.sessionFormItem__priceItem} ${price==='500' && styles.activePrice}`}>
                {currency?.symbol} 500
            </div>

            <div onClick={()=>handlePrice('1000')} className={`${styles.sessionFormItem__priceItem} ${price==='1000' && styles.activePrice}`}>
                {currency?.symbol} 1000
            </div>

            <div onClick={()=>handlePrice('custom')} className={`${styles.sessionFormItem__priceItem} ${isCustomPrice && styles.activePrice}`}>
                Custom
            </div>

          </div>
         
        </div>

       {isCustomPrice && 
            <div  className={styles.sessionFormItem}>
            <label>
              Custom Price
            </label>
            <TextField
           type="number"
            value={price}
            onChange={e => setPrice (e.target.value)}
            placeholder="e.g. 350"
            size="small"
          />
            </div>}
        
        </>

      
          
        }

        <div className={styles.sessionFormItem}>
          <label><span>Invitee Questions</span> <span><Tooltip title="These questions will be asked from the client when they are booking this session"><HelpOutlined/></Tooltip></span></label>
          {inviteeQuestions.map((item)=>{
            return(
              <TextField style={{marginBottom:'13px'}} InputProps={{
            endAdornment: <Create style={{cursor:'pointer'}} onClick={()=>handleEditQuiz(item)} />,
          }} value={item.quiz} size="small" disabled />
            )
          })}
          <button className="secondaryBtn" onClick={handleAddQuestionModal} style={{boxShadow:'none',borderRadius:'5px',display:'flex',alignItems:'center',justifyContent:'center'}} > <PlusOneOutlined /> <span>Add Question</span></button>

        </div>

         {sessionType==='webinar' &&
          <div className={styles.sessionFormItemGroup}>
          <div className={styles.sessionFormItem}>
            <label>Date</label>
            <DatePicker size='large' onChange={handleDate} />
          </div>
          <div className={styles.sessionFormItem}>
            <label>Time</label>
            <TimePicker  size='large' onChange={handleTime} />
          </div>
          </div>
         }

        {isEditEnabled ?  <button className='primaryBtn' style={{boxShadow:'none',borderRadius:'5px'}} onClick={handleEdit}>Next:Customize</button> : <button className='primaryBtn' disabled={!title || !sessionType || loading} style={{boxShadow:'none',borderRadius:'5px'}} onClick={handleCreateSession}>{loading ? <CircularProgress color="inherit" /> : "Add Session"}</button>}
       
      </div>

      <Modal title="Invitee Question"
       open={isAddingInviteeQuiz} onOk={handleAddInviteeQuestion} onCancel={handleCancelInviteeQuestion}>
      <div className={styles.sessionFormItem}>
      <label>
        Question
      </label>
      <TextField placeholder="e.g. What is the Call About?" size="small" value={currentInviteeQuestion} onChange={(e)=>setCurrentInviteeQuestion(e.target.value)} />
      </div>
     <div>
        <Checkbox checked={currentInviteeQuizRequired} onChange={(e)=>setCurrentInviteeQuizRequired(e.target.checked)} />
        <label>Required</label>
     </div>

      </Modal>

      <Modal title="Edit Invitee Question"
       open={isEditQuizModal} onOk={handleAddUpdatedQuiz} onCancel={handleCancelEditQuizModal}>
      <div className={styles.sessionFormItem}>
      <label>
        Edit Question
      </label>
      <TextField placeholder="e.g. What is the Call About?" size="small" value={editQuiz} onChange={(e)=>setEditQuiz(e.target.value)} />
      </div>
     <div>
        <Checkbox checked={isEditQuizRequired} onChange={(e)=>setIsEditQuizRequired(e.target.checked)} />
        <label>Required</label>
     </div>

      </Modal>
      
    </div>
  );
}

export default CreateSession;
