import React, {useState, useEffect} from 'react';
import styles from './Sessions.module.css';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import callImg from '../../assets/video-call.png';
import AddIcon from '@mui/icons-material/Add';
import {useNavigate} from 'react-router';
import {toast} from 'react-toastify';
import {getErrorMessage} from '../../util/GetError';
import SessionServices from '../../services/sessions.services';
import {useAppSelector} from '../../hooks';
import { currencyArr } from '../../util/currency';
import { RemoveRedEyeOutlined, VisibilityOffOutlined } from '@mui/icons-material';
import {Link} from 'react-router-dom';

function TabPanel (props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index &&
        <Box sx={{p: 3}}>
          <Typography>{children}</Typography>
        </Box>}
    </div>
  );
}
function a11yProps (index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function Sessions () {
  const [value, setValue] = useState (0);
  const [callSessions, setCallSessions] = useState ([]);
  const {userInfo} = useAppSelector (state => state.auth);
  const [currency,setCurrency] = useState("");
  const [textQueries, setTextQueries] = useState ([]);
  const [webinars, setWebinars] = useState ([]);
  const [packages, setPackages] = useState ([]);
  const [documents, setDocuments] = useState ([]);
  const [loading,setLoading] = useState(true);

  const navigate = useNavigate ();
  const handleChange = (event, newValue) => {
    setValue (newValue);
  };

  useEffect(()=>{
    setTimeout(()=>setLoading(false),1500);
  },[])

  useEffect(()=>{
    if(userInfo?.userDetails?.currency){
      let currencyItem = currencyArr.find((item)=>{
        return item.name === userInfo?.userDetails?.currency
      });
      if(currencyItem){
        setCurrency(currencyItem)
      }
    }
  },[userInfo])

  useEffect (
    () => {
      let isMounted = true;
      const getAllSessions = async () => {
        try {
          const response = await SessionServices.getAllUserSessions (
            userInfo.id
          );
          console.log (response.data);
          let callSessionsArr = response.data?.filter (
            item => item.type === 'video'
          );
          let textArr = response.data?.filter (
            item => item.type === 'textQuery'
          );
          let webinarArr = response.data?.filter (
            item => item.type === 'webinar'
          );
          let documentArr = response.data?.filter (
            item => item.type === 'document'
          );
          let packageArr = response.data?.filter (
            item => item.type === 'package'
          );
          setCallSessions (callSessionsArr);
          setWebinars(webinarArr);
          setTextQueries(textArr);
          setDocuments(documentArr);
          setPackages(packageArr);
          

        } catch (err) {
          console.log (err);
          toast.error (getErrorMessage (err));
        }
      };
      if (isMounted && userInfo) {
        getAllSessions ();
      }
    },
    [userInfo]
  );

  const handleCreateSession = (type) => {
    navigate ('/dashboard/sessions/create?type='+type);
  };

  if(loading){
    return null;
  }
  return (
    <div className={styles.sessions__wrapper}>
      <div className={styles.sessions__topheader}>
      <h1>Sessions</h1>
      <button
        className={styles.primaryBtn}
        onClick={()=>handleCreateSession('video')}
      >
        <AddIcon /><span>Add Session</span>
      </button>
      </div>

      <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="1:1 Call" {...a11yProps (0)} />
          <Tab label="Text Query" {...a11yProps (1)} />
          <Tab label="Package" {...a11yProps (2)} />
          <Tab label="Digital Product" {...a11yProps (3)} />
          <Tab label="Webinar" {...a11yProps (4)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <div className={styles.service__area}>
          {callSessions.length === 0
            ? <div className={styles.service__item}>
                <img src={callImg} alt="..." />
                <h2>Create a 1:1 Call Session</h2>
                <small>You can create a 1:1 call session here</small>
                <button
                  className={styles.primaryBtn}
                  onClick={()=>handleCreateSession('video')}
                >
                  <AddIcon /><span>Create</span>
                </button>
              </div>
            : callSessions.map((item)=>{
              return (<div className={styles.sessionCard}>
                <div className={styles.sessionCard__header}>
                <h3>{item?.title}</h3>
                <div>
                  {item?.isHidden ? <span className={styles.sessionCard__iconWrapper}><VisibilityOffOutlined className={styles.sessionCard__icon}/><span>Hidden</span></span> : <span className={styles.sessionCard__iconWrapper}><RemoveRedEyeOutlined className={styles.sessionCard__icon}/> <span>Public</span></span>}
                </div>
                </div>
                <div className={styles.sessionCard__info}>
                  {item?.duration} Mins | {currency.symbol}{item?.price}
                </div>
                <div className={styles.sessionCard__footer}>
                  <Link to={`/dashboard/sessions/edit/${item.id}`} className={styles.sessionCard__btn}>Edit</Link>
                </div>
              </div>)

            })}
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
      <div className={styles.service__area}>
          {textQueries.length === 0
            ? <div className={styles.service__item}>
                <img src={callImg} alt="..." />
                <h2>Create a Text Query Session</h2>
                <small>Answer questions of clients</small>
                <button
                  className={styles.primaryBtn}
                  onClick={()=>handleCreateSession('textQuery')}
                >
                  <AddIcon /><span>Create</span>
                </button>
              </div>
            : textQueries.map((item)=>{
              return (<div className={styles.sessionCard}>
                <div className={styles.sessionCard__header}>
                <h3>{item?.title}</h3>
                <div>
                  {item?.isHidden ? <span className={styles.sessionCard__iconWrapper}><VisibilityOffOutlined className={styles.sessionCard__icon}/><span>Hidden</span></span> : <span className={styles.sessionCard__iconWrapper}><RemoveRedEyeOutlined className={styles.sessionCard__icon}/> <span>Public</span></span>}
                </div>
                </div>
                <div className={styles.sessionCard__info}>
                   {currency.symbol}{item?.price}
                </div>
                <div className={styles.sessionCard__footer}>
                  <Link to={`/dashboard/sessions/edit/${item.id}`} className={styles.sessionCard__btn}>Edit</Link>
                </div>
              </div>)

            })}
        </div>
      </TabPanel>
      <TabPanel value={value} index={2}>
      <div className={styles.service__area}>
          {packages.length === 0
            ? <div className={styles.service__item}>
                <img src={callImg} alt="..." />
                <h2>Create Package Session</h2>
                <small>You can offer multiple services in a package</small>
                <button
                  className={styles.primaryBtn}
                  onClick={()=>handleCreateSession('package')}
                >
                  <AddIcon /><span>Create</span>
                </button>
              </div>
            : packages.map((item)=>{
              return (<div className={styles.sessionCard}>
                <div className={styles.sessionCard__header}>
                <h3>{item?.title}</h3>
                <div>
                  {item?.isHidden ? <span className={styles.sessionCard__iconWrapper}><VisibilityOffOutlined className={styles.sessionCard__icon}/><span>Hidden</span></span> : <span className={styles.sessionCard__iconWrapper}><RemoveRedEyeOutlined className={styles.sessionCard__icon}/> <span>Public</span></span>}
                </div>
                </div>
                <div className={styles.sessionCard__info}>
                  {item?.duration} Mins | {currency.symbol}{item?.price}
                </div>
                <div className={styles.sessionCard__footer}>
                  <Link to={`/dashboard/sessions/edit/${item.id}`} className={styles.sessionCard__btn}>Edit</Link>
                </div>
              </div>)

            })}
        </div>
      </TabPanel>
      <TabPanel value={value} index={3}>
      <div className={styles.service__area}>
          {documents.length === 0
            ? <div className={styles.service__item}>
                <img src={callImg} alt="..." />
                <h2>Create a Digital Product </h2>
                <small>You can sell a digital product or document</small>
                <button
                  className={styles.primaryBtn}
                  onClick={()=>handleCreateSession('document')}
                >
                  <AddIcon /><span>Create</span>
                </button>
              </div>
            : documents.map((item)=>{
              return (<div className={styles.sessionCard}>
                <div className={styles.sessionCard__header}>
                <h3>{item?.title}</h3>
                <div>
                  {item?.isHidden ? <span className={styles.sessionCard__iconWrapper}><VisibilityOffOutlined className={styles.sessionCard__icon}/><span>Hidden</span></span> : <span className={styles.sessionCard__iconWrapper}><RemoveRedEyeOutlined className={styles.sessionCard__icon}/> <span>Public</span></span>}
                </div>
                </div>
                <div className={styles.sessionCard__info}>
                  {item?.duration} Mins | {currency.symbol}{item?.price}
                </div>
                <div className={styles.sessionCard__footer}>
                  <Link to={`/dashboard/sessions/edit/${item.id}`} className={styles.sessionCard__btn}>Edit</Link>
                </div>
              </div>)

            })}
        </div>
      </TabPanel>
      <TabPanel value={value} index={4}>
      <div className={styles.service__area}>
          {webinars.length === 0
            ? <div className={styles.service__item}>
                <img src={callImg} alt="..." />
                <h2>Create a Webinar</h2>
                <small>You can create webinar for specific events</small>
                <button
                  className={styles.primaryBtn}
                  onClick={()=>handleCreateSession('webinar')}
                >
                  <AddIcon /><span>Create</span>
                </button>
              </div>
            : webinars.map((item)=>{
              return (<div className={styles.sessionCard}>
                <div className={styles.sessionCard__header}>
                <h3>{item?.title}</h3>
                <div>
                  {item?.isHidden ? <span className={styles.sessionCard__iconWrapper}><VisibilityOffOutlined className={styles.sessionCard__icon}/><span>Hidden</span></span> : <span className={styles.sessionCard__iconWrapper}><RemoveRedEyeOutlined className={styles.sessionCard__icon}/> <span>Public</span></span>}
                </div>
                </div>
                <div className={styles.sessionCard__info}>
                  {item?.duration} Mins | {currency.symbol}{item?.price}
                </div>
                <div className={styles.sessionCard__footer}>
                  <Link to={`/dashboard/sessions/edit/${item.id}`} className={styles.sessionCard__btn}>Edit</Link>
                </div>
              </div>)

            })}
        </div>
      </TabPanel>
    </div>
  );
}

export default Sessions;
