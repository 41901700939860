// ProtectedRoute.js
import {useAppSelector} from '../hooks';
import {Link} from 'react-router-dom';
import unauthorized from '../assets/unauthorized.jpg';

const LoggedInRoute = ({children}) => {
  const {userInfo} = useAppSelector (state => state.auth);

  // show unauthorized screen if no user is found in redux store
  if (!userInfo) {
    return (
      <div className="unauthorized">
        <br />
        <img src={unauthorized} alt="not authorized" />
        <br />
        <span>
          <Link to="/login" className="primaryBtn">Login</Link> to gain access
        </span>
      </div>
    );
  }

  return children;
};
export default LoggedInRoute;
