import React,{useState,useEffect} from 'react'
import { toast } from 'react-toastify';
import { useAppSelector } from '../../hooks';
import SessionServices from '../../services/sessions.services';
import { getErrorMessage } from '../../util/GetError';
import styles from './Dashboard.module.css';

function UserDashboardItem() {
  const [finalResult,setFinalResult] = useState({});
  const {userInfo} = useAppSelector (state => state.auth);
  useEffect (
    () => {
      let isMounted = true;
      const getAllLength = async () => {
        try {
          const response = await SessionServices.getUserSessionsLength (
            userInfo?.id
          );
          console.log (response.data);
          setFinalResult(response.data);
          

        } catch (err) {
          console.log (err);
          toast.error (getErrorMessage (err));
        }
      };
      if (isMounted && userInfo) {
        getAllLength ();
      }
    },
    [userInfo]
  );


  return (
    <div className={styles.dashboardItem}>
      <div className={styles.dashboardCard__wrapper}>
        <div className={styles.dashboardItemCard}
         style={{
          backgroundImage: 'linear-gradient(315deg, var(--primary) 0%, var(--secondary) 74%)',
        }}
        >
          <h2>{finalResult?.sessions}</h2>
          <p>Sessions</p>
        </div>

        <div className={styles.dashboardItemCard}
         style={{
          backgroundImage: 'linear-gradient(109.6deg, rgb(255, 194, 48) 11.2%, rgb(255, 124, 0) 100.2%)',
        }}
        >
          <h2>{finalResult?.bookings}</h2>
          <p>Bookings</p>
        </div>


      </div>
    </div>
  )
}

export default UserDashboardItem