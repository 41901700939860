import React, {useEffect, useState} from 'react';
import {
  PaymentElement,
  LinkAuthenticationElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import styles from './CheckoutForm.module.css';
import logo from '../../assets/logoC.png';
import CircularProgress from '@mui/material/CircularProgress';
import {toast} from 'react-toastify';
import {AddressElement} from '@stripe/react-stripe-js';



export default function CheckoutForm (props) {
  const stripe = useStripe ();
  const elements = useElements ();

  const [email, setEmail] = useState ('');
  const [message, setMessage] = useState (null);
  const [isLoading, setIsLoading] = useState (false);
  
  useEffect (
    () => {
      if (message) {
        toast.info (message);
      }
    },
    [message]
  );

  useEffect (
    () => {
      if (!stripe) {
        return;
      }

      const clientSecret = props.clientSecret;
      console.log (clientSecret);

      if (!clientSecret) {
        return;
      }

      stripe.retrievePaymentIntent (clientSecret).then (({paymentIntent}) => {
        console.log (paymentIntent);
       
        switch (paymentIntent.status) {
          case 'succeeded':
            setMessage ('Payment succeeded!');
            break;
          case 'processing':
            setMessage ('Your payment is processing.');
            break;
          case 'requires_payment_method':
            break;
          default:
            setMessage ('Something went wrong.');
            break;
        }
      });
    },
    [stripe, props.clientSecret]
  );

  const handleSubmit = async e => {
    e.preventDefault ();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading (true);

    const {error} = await stripe.confirmPayment ({
      elements,

      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `https://coderscolab.com/payment/success/${props.bookingId}/${props.userId}`,
      },
      
    });
  

    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage (error.message);
    } else {
      setMessage ('An unexpected error occurred.');
    }
    // console.log(paymentIntent);

    setIsLoading (false);
  };

  const paymentElementOptions = {
    layout: 'tabs',
  };

  return (
    <div className={styles.checkoutFormWrapper}>
      <div className={styles.checkoutInfo}>
        <div className={styles.checkoutInfo__img}>
          <img src={logo} alt="logo" />
          <h3>Coderscolab</h3>
         
        </div>
        <div className={styles.checkoutInfoCard}> 
              <h4>{props.sessionDetails?.title}</h4>
              <h2>{props.currency?.symbol}{props.sessionDetails?.price}</h2>
        </div>
      </div>
      <div className={styles.checkoutForm__area}>
        <form id="payment-form" onSubmit={handleSubmit}>
          <LinkAuthenticationElement
            id="link-authentication-element"
            value={email}
            onChange={e => setEmail (e.target.value)}
          />
          <PaymentElement
            id="payment-element"
            options={paymentElementOptions}
          />
          <h4>Address</h4>
          <AddressElement options={{mode: 'billing'}} />
          <button
            disabled={isLoading || !stripe || !elements}
            id="submit"
            className="primaryBtn"
            style={{
              borderRadius: '5px',
              boxShadow: 'none',
              width: '100%',
              margin: '0',
              marginTop: '20px',
            }}
          >
            <span id="button-text">
              {isLoading
                ? <CircularProgress style={{width: '30px'}} color="inherit" />
                : 'Pay now'}
            </span>
          </button>
          {/* Show any error or success messages */}
          {message && <div id="payment-message">{message}</div>}
        </form>
      </div>
    </div>
  );
}
