import axios from '../util/axios.js';
import AuthHeader from './authHeader.js';

const getAllUserSessions = userId => {
  return axios.post (
    '/api/sessions/get-user-sessions',
    {userId: userId},
    {
      headers: AuthHeader (),
    }
  );
};

const getSessionById = id => {
  return axios.get ('/api/sessions/get-session-by-id/' + id, {
    headers: AuthHeader (),
  });
};

const updateSessionById = data => {
  return axios.put ('/api/sessions/update-user-session', data, {
    headers: AuthHeader (),
  });
};

const deleteSessionById = id => {
  return axios.post (
    '/api/sessions/delete-session-by-id',
    {id: id},
    {
      headers: AuthHeader (),
    }
  );
};

const createNewSession = data => {
  return axios.post ('/api/sessions/create-new-session', data, {
    headers: AuthHeader (),
  });
};

const getAllSessions = () => {
  return axios.get ('/api/sessions/get-all-sessions', {
    headers: AuthHeader (),
  });
};

const getUserSessionsLength = userId => {
  return axios.post (
    '/api/sessions/get-user-sessions-length',
    {userId: userId},
    {
      headers: AuthHeader (),
    }
  );
};

const SessionServices = {
  getAllUserSessions,
  getSessionById,
  updateSessionById,
  deleteSessionById,
  createNewSession,
  getAllSessions,
  getUserSessionsLength,
};

export default SessionServices;
