import React,{useState} from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import styles from './terms.module.css';

function TermsOfServices () {
const [colorChange, setColorchange] = useState (false);

  const changeNavbarColor = () => {
    if (window.scrollY >= 80) {
      setColorchange (true);
    } else {
      setColorchange (false);
    }
  };

  window.addEventListener ('scroll', changeNavbarColor);

  return (
  <>
        <Navbar  bgColor={colorChange} />
      <div className={styles.terms__container}>
      <h1>CodersColab - Terms of Service</h1>

  <h2>1. Account Registration</h2>
  <p>
    1.1. In order to use certain features of the Services, you must register for an account with CodersColab. By creating an
    account, you represent and warrant that all information provided is accurate and complete.
  </p>
  <p>
    1.2. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur
    under your account. You agree to notify us immediately of any unauthorized use of your account or any other breach of
    security.
  </p>

  <h2>2. Services</h2>
  <p>
    2.1. CodersColab provides a platform for connecting clients with mentors, enabling clients to seek mentorship and guidance
    in various coding and programming fields.
  </p>
  <p>
    2.2. The mentors on CodersColab are independent contractors and are not employed by Sofstack. We do not endorse or guarantee
    the quality, accuracy, or reliability of the mentors or their advice. You acknowledge and agree that any interactions with
    mentors are at your own risk.
  </p>
  <p>
    2.3. CodersColab reserves the right to modify, suspend, or discontinue any part of the Services at any time without prior
    notice.
  </p>

  <h2>3. Client-Mentor Engagement</h2>
  <p>
    3.1. CodersColab provides a platform for clients and mentors to connect, communicate, and collaborate. The engagement
    between clients and mentors, including the scope of work, deliverables, and compensation, is the sole responsibility of
    the parties involved.
  </p>
  <p>
    3.2. CodersColab does not act as an employer, agent, or representative of either the client or the mentor. We do not have
    control over the quality, timing, legality, or any aspect of the services provided by mentors.
  </p>
  <p>
    3.3. Clients and mentors are solely responsible for any agreements, contracts, or obligations they enter into with each
    other. CodersColab shall not be liable for any disputes, claims, or damages arising out of or in connection with such
    agreements.
  </p>

  <h2>4. User Conduct</h2>
  <p>
    4.1. You agree to use the Services in compliance with all applicable laws and regulations.
  </p>
  <p>
    4.2. You will not:
  </p>
  <ul>
    <li>Violate any intellectual property rights or proprietary rights of CodersColab or any third party.</li>
    <li>Use the Services to upload, transmit, or distribute any content that is unlawful, defamatory, abusive, obscene, or
      otherwise objectionable.</li>
    <li>Engage in any fraudulent or deceptive activities.</li>
    <li>Attempt to gain unauthorized access to the Services or interfere with the proper functioning of the Services.</li>
    <li>Use the Services in a manner that could damage, disable, overburden, or impair our servers or networks.</li>
  </ul>

  <h2>5. Intellectual Property</h2>
  <p>
    5.1. The Website, Services, and all related content, including but not limited to text, graphics, logos, and images, are
    the intellectual property of Sofstack Technology Solutions Pvt. Ltd. or its licensors. You may not use, reproduce,
    distribute, or modify any of the intellectual property without prior written consent from Sofstack.
  </p>
    </div>
    <Footer />
  </>
  );
}

export default TermsOfServices;
