import {
  DescriptionOutlined,
  EventOutlined,
  Home,
  MessageOutlined,
  PersonOutline,
} from '@mui/icons-material';
import {Box} from '@mui/material';
import {Link} from 'react-router-dom';
import styles from './Navbar.module.css';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {useAppDispatch, useAppSelector} from '../../hooks';
import {useGetUserDetailsQuery} from '../../services/authApi';
import {setCredentials, logout} from '../../redux/features/authSlice';
import {useNavigate} from 'react-router';
import { useEffect, useState } from 'react';
const pages = [
    {label: 'All Mentors', href: '/mentors'},
    {label: 'Blog', href: '/blog'},
    {label: 'Contact', href: '/contact'},
    {label: 'About', href: '/about'},
  ];
  
function DashboardNavbar () {
    const [anchorElNav, setAnchorElNav] =useState (null);
    const {userInfo} = useAppSelector (state => state.auth);

    const navigate = useNavigate ();
    const dispatch = useAppDispatch ();
    // automatically authenticate user if token is found
    const {data} = useGetUserDetailsQuery ('userDetails', {
      // perform a refetch every 15mins
      pollingInterval: 900000,
    });
    // console.log (data);
  
    useEffect (
      () => {
        // console.log(data);
        if (data) dispatch (setCredentials (data));
      },
      [data, dispatch]
    );
  
    const handleOpenNavMenu = event => {
      setAnchorElNav (event.currentTarget);
    };
  
    const handleCloseNavMenu = () => {
      setAnchorElNav (null);
    };
  

  
    const handleSettingClicked = setting => {
      if (setting === 'Logout') {
        dispatch (logout ());
        navigate ('/login');
      } else if (setting === 'Dashboard') {
        navigate ('/dashboard');
      }
    };
  return (
    <>
        <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
             
              fontFamily: 'monospace',
              fontWeight: 700,
              color:'black',
              textDecoration: 'none',
              fontSize:'1.5rem',
              marginTop:'-10px'

            }}
          >
           
            <span>CODERSCOLAB<span className={styles.logo__dot}>.</span></span>
          </Typography>
         <Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'},justifyContent:'flex-end'}}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="black"
            >
              <MenuIcon style={{color: 'black'}} />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean (anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: {xs: 'block', md: 'none'},
              }}
            >
              {pages.map (page => (
                <MenuItem key={page.label} onClick={handleCloseNavMenu}>
                  <Link
                    to={page.href}
                    style={{textDecoration: 'none', color: 'black'}}
                  >
                    {page.label}
                  </Link>
                </MenuItem>
              ))}
              {userInfo
                ? <MenuItem onClick={() => handleSettingClicked ('Logout')}>
                    Logout
                  </MenuItem>
                : <>
                <MenuItem onClick={handleCloseNavMenu}>
                    <Link
                      to={'/register'}
                      style={{textDecoration: 'none', color: 'var(--primary)'}}
                    >
                      Register
                    </Link>
                  
                  </MenuItem>
                  <MenuItem onClick={handleCloseNavMenu}>
                  <Link
                      to={'/login'}
                      style={{textDecoration: 'none', color: 'var(--primary)'}}
                    >
                      Login
                    </Link>
                  
                  </MenuItem>
                </>
                
                  }

            </Menu>
          </Box>
          <Box
      sx={{
        display: {xs: 'none', md: 'flex'},
        
      }}
    >
      <div className={styles.dashboardNav}>
        <Link to="/">
          <Home className={styles.navIcon} />
          Home
        </Link>
        <Link to="/mentors">
          <EventOutlined className={styles.navIcon} />
          <span>All Mentors</span>
        </Link>
        <Link to="/blog">
          <DescriptionOutlined className={styles.navIcon} />
          <span>Blog</span>
        </Link>
        <Link to="/contact">
          <MessageOutlined className={styles.navIcon} />
          <span>Contact</span>
        </Link>
        <Link to="/about">
          <PersonOutline className={styles.navIcon} />
          <span>About</span>
        </Link>
      </div>

      

    </Box>
    </>
  
  );
}

export default DashboardNavbar;
