import React, {useState} from 'react';
import styles from './ContactUs.module.css';
import {toast} from 'react-toastify';

function ContactUs() {
  const [name, setName] = useState ('');
  const [email, setEmail] = useState ('');
  const [msg, setMsg] = useState ('');

  const handleSubmit = () => {
    let data = {
      name: name,
      email: email,
      message: msg,
    };

    try {
      console.log (data);
    } catch (err) {
      console.log (err);
      toast.error (err.message);
    }
  };
  return (
    <div className={styles.contactUs}>
      <h1>
        Still Have Queries? Write Us Here
      </h1>
      <div className={styles.contactForm}>
        <div className={styles.contactFormItem__inline}>
          <input
            className={styles.contactFormItem}
            value={name}
            onChange={e => setName (e.target.value)}
            type="text"
            placeholder="Enter Your Name"
          />
          <input
            className={styles.contactFormItem}
            value={email}
            onChange={e => setEmail (e.target.value)}
            type="email"
            placeholder="Enter Your Email"
          />
        </div>
        <textarea
          className={styles.contactFormItem}
          value={msg}
          onChange={e => setMsg (e.target.value)}
          placeholder="Enter your message"
        />
        <button type="button" className="primaryBtn" onClick={handleSubmit}>
          Submit
        </button>
      </div>
    </div>
  );
}

export default ContactUs;
