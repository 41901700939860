import {useEffect, useState} from 'react';
import {List, ListItem, ListItemButton, ListItemIcon} from '@mui/material';
import {Link} from 'react-router-dom';
import styles from './SideNav.module.css';
import {useLocation} from 'react-router-dom';
import {useAppSelector} from '../../hooks';

function SideNav({linkArr}) {
  const location = useLocation ();
  const [activePath, setActivePath] = useState ('/dashboard');
  const {userInfo} = useAppSelector (state => state.auth);
  const role = userInfo?.role?.role;

  useEffect (
    () => {
      // console.log ('Location changed!', location.pathname);
      setActivePath (location.pathname);
    },
    [location]
  );
  return (
    <List>
      {linkArr.map (
        (item, index) =>
          item.isAdmin && role === 'admin'
            ? <ListItem
                key={index}
                disablePadding
                className={`${styles.menuItem} ${activePath === item.href ? styles.active : ''}`}
              >
                <ListItemButton>
                  <ListItemIcon
                    className={`${styles.menuIcon} ${activePath === item.href ? styles.activeIcon : ''}`}
                  >
                    {item.icon}
                  </ListItemIcon>

                  <Link
                    to={item.href}
                    className={`${styles.sidenav__link} ${activePath === item.href ? styles.activeLinkText : ''}`}
                  >
                    {item.label}
                  </Link>
                </ListItemButton>
              </ListItem>
            : !item.isAdmin && item.isBoth ? 
                <ListItem
                  key={index}
                  disablePadding
                  className={`${styles.menuItem} ${activePath === item.href ? styles.active : ''}`}
                >
                  <ListItemButton>
                    <ListItemIcon
                      className={`${styles.menuIcon} ${activePath === item.href ? styles.activeIcon : ''}`}
                    >
                      {item.icon}
                    </ListItemIcon>

                    <Link
                      to={item.href}
                      className={`${styles.sidenav__link} ${activePath === item.href ? styles.activeLinkText : ''}`}
                    >
                      {item.label}
                    </Link>
                  </ListItemButton>
                </ListItem>
                 : 
                 !item.isAdmin && role !== 'admin' && 
                <ListItem
                  key={index}
                  disablePadding
                  className={`${styles.menuItem} ${activePath === item.href ? styles.active : ''}`}
                >
                  <ListItemButton>
                    <ListItemIcon
                      className={`${styles.menuIcon} ${activePath === item.href ? styles.activeIcon : ''}`}
                    >
                      {item.icon}
                    </ListItemIcon>

                    <Link
                      to={item.href}
                      className={`${styles.sidenav__link} ${activePath === item.href ? styles.activeLinkText : ''}`}
                    >
                      {item.label}
                    </Link>
                  </ListItemButton>
                </ListItem>
      )}
    </List>
  );
}

export default SideNav;
