import { Divider } from 'antd';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import Avatar from 'react-avatar';
import { Link } from 'react-router-dom';
import {toast} from 'react-toastify';
import Footer from '../../components/Footer/Footer';
import Navbar from '../../components/Navbar/Navbar';
import BlogServices from '../../services/blogs.services';
import {getErrorMessage} from '../../util/GetError';
import styles from './blog.module.css';

function BlogList () {
  const [allBlogs, setAllBlogs] = useState ([]);

  useEffect (() => {
    let isMounted = true;

    const getAllBlogs = async () => {
      try {
        const response = await BlogServices.getAllBlogs ();
        // console.log (response.data);
        setAllBlogs (response.data);
      } catch (err) {
        console.log (err);
        toast.error (getErrorMessage (err));
      }
    };

    if (isMounted) {
      getAllBlogs ();
    }

    return () => {
      isMounted = false;
    };
  }, []);
  return (
  <>
      <div className={styles.blog__list}>
      <Navbar bgColor={false} textColor={'black'} />
      <div className={styles.blogs__cardGroup}>
        {allBlogs.map((blog,i)=>{
            
            return(
                <div className={`${styles.blog__card} ${i >= 2 ? styles.blog__cardsm : '' }`} key={blog.id}>
                    <Link to={`/blog/details/${blog.id}`}>
                    <img className={styles.blogCard__banner} src={blog.imgURL} alt={blog?.title} />
                    </Link>
                    <div className={styles.cardTags}>
                        {blog?.tags?.map(item=><span key={item}>{item}</span>)}
                    </div>
                    <div className={styles.blog__cardInfo}>
                    <Link to={`/blog/details/${blog.id}`}>
                    <h3>{blog?.title}</h3>
                    </Link>
                    </div>
                   
                    <div className={styles.blog__cardFooter}>
                        <div className={styles.blog__author}>
                        {blog?.userDetails?.userDetails?.profilePicture ?  <img alt={blog?.title} className={styles.authorImg} src={blog?.userDetails?.userDetails?.profilePicture} size="30" /> :  <Avatar round={true} title={blog?.userDetails?.username} size="30" />}
                        <span>{blog?.userDetails?.userDetails?.fullName ? blog?.userDetails?.userDetails?.fullName : blog?.userDetails?.username}</span>
                        </div>
                        <div className={styles.createdDate}>
                            {moment(blog.createdAt).format('MMMM DD,YYYY')}
                        </div>
                    </div>
                </div>
            )
        })}

      </div>
      
    </div>
    <Divider />
      <Footer />
  </>
  );
}

export default BlogList;
