import React, {useState} from 'react';
import styles from './Contact.module.css';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import AccessAlarmOutlinedIcon from '@mui/icons-material/AccessAlarmOutlined';

function Contact () {
  const [colorChange, setColorchange] = useState (false);

  const changeNavbarColor = () => {
    if (window.scrollY >= 80) {
      setColorchange (true);
    } else {
      setColorchange (false);
    }
  };
  window.addEventListener ('scroll', changeNavbarColor);

  return (
    <div>
      <Navbar bgColor={colorChange} />
      <div className={styles.contact__main}>
        <div className={styles.contactText}>
          <h2>Contact Us</h2>
          <div className={styles.contact__info}>
            <div className={styles.contact__infoItem}>
              <LocalPhoneIcon className={styles.contact__infoIcon} />
              <span className={styles.contact__infoText}>
                (+91) 011-408-45766{' '}
              </span>
            </div>

            <div className={styles.contact__infoItem}>
              <EmailOutlinedIcon className={styles.contact__infoIcon} />
              <span className={styles.contact__infoText}>
                contact@coderscolab.com
              </span>
            </div>

            <div className={styles.contact__infoItem}>
              <LocationOnOutlinedIcon className={styles.contact__infoIcon} />
              <span className={styles.contact__infoText}>
                G-69, Sector 63, Noida - 201301, India
              </span>
            </div>

            <div className={styles.contact__infoItem}>
              <AccessAlarmOutlinedIcon className={styles.contact__infoIcon} />
              <span className={styles.contact__infoText}>
                10 : 00  AM -  06 : 00 PM IST (Mon - Fri){' '}
              </span>
            </div>

          </div>

        </div>
        <div className={styles.contactForm}>
          <h2>Fill the Below Form</h2>
          <form>
            <div className={styles.contactFormItem}>
              <label>
                Your Name
              </label>
              <input type="text" placeholder="e.g. John Doe" />

            </div>

            <div className={styles.contactFormItem}>
              <label>
                Your Email
              </label>
              <input type="email" placeholder="e.g. john@gmail.com" />
            </div>

            <div className={styles.contactFormItem}>
              <label>
                Your Phone
              </label>
              <input type="text" placeholder="e.g. +91 9881912121" />
            </div>
            <div className={styles.contactFormItem}>
              <label>
                Your Message
              </label>
              <textarea
                rows="5"
                placeholder="e.g. I want to discuss a project"
              />
            </div>
            <button
              className="primaryBtn"
              style={{
                marginLeft: 'auto',
                marginRight: 'auto',
                display: 'block',
                fontSize: '17px',
                padding: '15px 50px',
              }}
            >
              Submit
            </button>
          </form>

        </div>
      </div>

      <Footer />

    </div>
  );
}

export default Contact;
