import React, { useEffect, useState } from 'react';
import styles from './blog.module.css';
import AddIcon from '@mui/icons-material/Add';
import {Link} from 'react-router-dom';
import {Divider} from '@mui/material';
import BlogServices from '../../services/blogs.services';
import { useAppSelector } from '../../hooks';
import notfound from '../../assets/no-results.png';
import { DeleteOutlined, EditOutlined } from '@mui/icons-material';
import { Tooltip } from 'antd';
import { toast } from 'react-toastify';
import { getErrorMessage } from '../../util/GetError';

function MyBlogs () {
  const [userBlogs,setUserBlogs] = useState([]);
  const {userInfo} = useAppSelector(state=>state.auth);

  const getUserBlogs = async ()=>{
    try{
      const response = await BlogServices.getUserBlogs(userInfo.id);
      console.log(response.data);
      setUserBlogs(response.data);
    }catch(err){
      console.log(err);
      toast.error(getErrorMessage(err));
    }
  }


  useEffect(()=>{
    let isMounted = true;
    const getUserBlogs = async ()=>{
      try{
        const response = await BlogServices.getUserBlogs(userInfo.id);
        console.log(response.data);
        setUserBlogs(response.data);
      }catch(err){
        console.log(err);
        toast.error(getErrorMessage(err));
      }
    }

    if(isMounted){
      getUserBlogs();
    }

    return ()=>{
      isMounted = false;
    }

    
  },[userInfo]);


  const handleDeleteBlog = async (id)=>{
    try{
      const response = await BlogServices.deleteUserBlog({userId:userInfo.id,id:id});
      console.log(response.data);
      if(response.data){
        toast.success("Blog Deleted Successfully!");
        getUserBlogs();
      }
    }catch(err){
      console.log(err);
      toast.error(getErrorMessage(err));
    }
  }
  return (
    <div className={styles.blogs__wrapper}>
      <div className={styles.blogs__header}>
        <h2>My Blogs</h2>
        <div className={styles.add__btnWrapper}>
          <Link to="/dashboard/blog/create" className={'primaryBtn'}>
            <span>Create Blog </span><AddIcon />
          </Link>
        </div>
      </div>

      <Divider />

      <div className={styles.blogs__cardGroup}>
          {userBlogs.length > 0 ?
          userBlogs.map((blog)=>{
            return(
              <div className={styles.blog__card}>
                <img src={blog?.imgURL} alt={blog?.title} />
               <div className={styles.blog__cardBody}>
               <h5>{blog?.title}</h5>
                <p>{blog?.description.slice(0,110)}...</p>
               </div>
               <div className={styles.card__footer}>
                  <Link to={"/dashboard/blog/update/"+ blog?.id} className={styles.card__footerItem}>
                      <Tooltip title="Edit Blog?">
                        <EditOutlined className={styles.card__footerIcon} />
                      </Tooltip>
                  </Link>
                  <div  className={styles.card__footerItem} onClick={()=>handleDeleteBlog(blog?.id)}>
                      <Tooltip title="Delete Blog?">
                        <DeleteOutlined style={{color:'red'}} className={styles.card__footerIcon} />
                      </Tooltip>
                  </div>
               </div>
              </div>
            )
          })
          :
          <div className={styles.notFound}>
                <img src={notfound} alt="..." />
                <h3>No Blogs Created</h3>
           </div>
          }
      </div>
    </div>
  );
}

export default MyBlogs;
