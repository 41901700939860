import {Input,Menu, Table, Tag, Dropdown} from 'antd';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {toast} from 'react-toastify';
import {useAppSelector} from '../../hooks';
import PaymentServices from '../../services/payments.services';
import {getErrorMessage} from '../../util/GetError';
import styles from '../UserBookings/UserBookings.module.css';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import GppMaybeOutlinedIcon from '@mui/icons-material/GppMaybeOutlined';

function UserPayments () {
  const [AllPayments, setAllPayments] = useState ([]);
  const {userInfo} = useAppSelector (state => state.auth);
  const [filterTypeArr,setFilterTypeArr] = useState([]);
  const [filterMethodArr,setFilterMethodArr] = useState([]);
  const [filteredPayments,setFilteredPayments] = useState([]);
  const [currentTab, setCurrentTab] = useState ('all');
  const [onlinePayments,setOnlinePayments] = useState([]);
  const [offlinePayments,setOfflinePayments] = useState([]);
  
  const getUserPayments = async () => {
    try {
      const response = await PaymentServices.getAllUserPayments (
        userInfo.id
      );
      console.log (response.data);
        let filterTypes = [];
        let filterMethod = [];
        response.data?.forEach (o => {
                if (o.type) {
                filterTypes.push ({text: o.type, value: o.type});
                }

                if(o.paymentMethod){
                    filterMethod.push({text: o.paymentMethod, value: o.paymentMethod})
                }
            });
            // console.log("NAME FILTER : ",assignToArr);
            filterTypes = filterTypes.filter ((item, index, self) => {
                return (
                index ===
                self.findIndex (t => t.text === item.text && t.value === item.value)
                );
            });

            filterMethod = filterMethod.filter ((item, index, self) => {
                return (
                index ===
                self.findIndex (t => t.text === item.text && t.value === item.value)
                );
            });

      let offlineP = response.data?.filter(item=>item.type==='offline');
      let onlineP = response.data?.filter(item=>item.type!=='offline');
      setOnlinePayments(onlineP);
      setOfflinePayments(offlineP);

      setAllPayments (response.data);
      setFilterTypeArr(filterTypes);
      setFilterMethodArr(filterMethod);
    } catch (err) {
      console.log (err);
      toast.error (getErrorMessage (err));
    }
  };

  useEffect (
    () => {
      let isMounted = true;

      const getUserPayments = async () => {
        try {
          const response = await PaymentServices.getAllUserPayments (
            userInfo.id
          );
          console.log (response.data);
            let filterTypes = [];
            let filterMethod = [];
            response.data?.forEach (o => {
                    if (o.type) {
                    filterTypes.push ({text: o.type, value: o.type});
                    }

                    if(o.paymentMethod){
                        filterMethod.push({text: o.paymentMethod, value: o.paymentMethod})
                    }
                });
                // console.log("NAME FILTER : ",assignToArr);
                filterTypes = filterTypes.filter ((item, index, self) => {
                    return (
                    index ===
                    self.findIndex (t => t.text === item.text && t.value === item.value)
                    );
                });

                filterMethod = filterMethod.filter ((item, index, self) => {
                    return (
                    index ===
                    self.findIndex (t => t.text === item.text && t.value === item.value)
                    );
                });

          let offlineP = response.data?.filter(item=>item.type==='offline');
          let onlineP = response.data?.filter(item=>item.type!=='offline');
          setOnlinePayments(onlineP);
          setOfflinePayments(offlineP);

          setAllPayments (response.data);
          setFilterTypeArr(filterTypes);
          setFilterMethodArr(filterMethod);
        } catch (err) {
          console.log (err);
          toast.error (getErrorMessage (err));
        }
      };

      if (isMounted && userInfo) {
        getUserPayments ();
      }

      return () => {
        isMounted = false;
      };
    },
    [userInfo]
  );


  const handlePaymentStatusUpdate = async (record)=>{
    let updated = {
      id:record.id,
      status:record?.status ? false : true
    }
    console.log(updated);
    try{
      const response = await PaymentServices.updatePaymentStatus(updated);
      console.log(response.data);
      toast.success("Payment Status Updated!");
      getUserPayments();
    }catch(err){
      console.log(err);
      toast.error(getErrorMessage(err));
    }
  }



  const columns = [
    {
      title: 'Created At',
      key:'createdAt',
      sorter: (a, b) => moment(a.createdAt) - moment(b.createdAt),
      defaultSortOrder: 'descend',
      render: (record) => <b>{moment(record.createdAt).format('YYYY-MM-DD hh:mm:ss')}</b>,
    },
    {
      title: 'Transaction ID',
      key:'transaction_id',
      render: (record) => <Link to={`/dashboard/payments/${record.id}`}><b>{record.transaction_id}</b></Link>,
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: 'Currency',
      dataIndex: 'currency',
      key: 'currency',
    },
    {
      title: 'Payment Method',
      dataIndex: 'paymentMethod',
      key: 'paymentMethod',
      filters:filterMethodArr,
      onFilter: (value, record) => record.paymentMethod.indexOf(value) === 0,
    },
    {
      title: 'Type',
      key: 'type',
      dataIndex: 'type',
      filters:filterTypeArr,
      onFilter: (value, record) => record.type.indexOf(value) === 0,
      render: (_, {type}) => (
        <Tag color={'cyan'}>
          {type.toUpperCase ()}
        </Tag>
      ),
    },
    {
      title: 'Payment Status',
      key: 'status',
      dataIndex: 'status',
      width:'150px',
      render: (_, record) => (
        <span className={styles.paymentStatus}>
          {record.status ? <VerifiedOutlinedIcon style={{color:'green',marginRight:'6px'}} /> : <GppMaybeOutlinedIcon style={{color:'red',marginRight:'6px'}} />}
          {record.status ? 'Verified' : 'Not Verified'}

        </span>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <div className={styles.btnWrapper}>
        {/* <Link to={`/dashboard/payments/${record.id}`}>View Details</Link>
        <Tooltip title={`${record.status ? 'Payment Not Confirmed' : 'Confirm Payment'}`}>
        <button className={styles.tableBtn} onClick={()=>handlePaymentStatusUpdate(record)}>{record?.status ? 'Not Confirm':'Confirm'}</button>
        </Tooltip> */}
        <Dropdown
      overlay={
        <Menu>
          <Menu.Item key="1">
          <Link to={`/dashboard/payments/${record.id}`}>View Details</Link>
          </Menu.Item>
          <Menu.Item key="2" onClick={()=>handlePaymentStatusUpdate(record)}>
          {record?.status ? 'Not Confirm':'Confirm'}
          </Menu.Item>
        </Menu>
      }
      placement="bottomLeft"
      arrow
    >
     <MoreVertOutlinedIcon />
    </Dropdown>

        </div>
      ),
    },

  ];

  const handleSearch = (e)=>{
      let query = e.target.value;

      let filteredList = AllPayments.filter((item)=>item?.transaction_id?.toLowerCase().match(query.toLowerCase()))
    setFilteredPayments(filteredList);
    }

    const handleCurrentTab = (newValue) => {
      setCurrentTab(newValue);
     
    };
  
 
  return (
    <div>
      <div style={{display:'flex',marginBottom:'1rem',justifyContent:'space-between',alignItems:'center'}}>
        <h2>All Payments</h2>
        <Input style={{maxWidth:'300px'}} placeholder='Search by Transaction ID' onChange={handleSearch} />
      </div>
      <div className={styles.tabsWrapper}>
        <div
          className={`${styles.tab} ${currentTab === 'all' && styles.activeTab}`}
          onClick={() => handleCurrentTab ('all')}
        >
          All
        </div>
        <div
          className={`${styles.tab} ${currentTab === 'online' && styles.activeTab}`}
          onClick={() => handleCurrentTab ('online')}
        >
          Online
        </div>

        <div
          className={`${styles.tab} ${currentTab === 'offline' && styles.activeTab}`}
          onClick={() => handleCurrentTab ('offline')}
        >
          Offline
        </div>
    
      </div>
      <br />
      <Table columns={columns} dataSource={filteredPayments.length > 0 ? filteredPayments : currentTab==='all' ? AllPayments : currentTab==='online' ? onlinePayments : currentTab==='offline' ? offlinePayments : []} />
    </div>
  );
}

export default UserPayments;
