import {useParams} from 'react-router';
import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import CheckoutForm from '../../components/CheckoutForm/CheckoutForm';
import { useEffect, useState } from 'react';
import {toast} from 'react-toastify';
import SessionServices from '../../services/sessions.services';
import { currencyArr } from '../../util/currency';
import {getErrorMessage} from '../../util/GetError';

const stripePromise = loadStripe (
  'pk_test_51BTUDGJAJfZb9HEBwDg86TN1KNprHjkfipXmEDMb0gSCassK5T3ZfxsAbcgKVmAIXF7oZ6ItlZZbXO6idTHE67IM007EwQ4uN3'
);

function PayNow () {
    const [sessionDetails,setSessionDetails] = useState();
    const [currency,setCurrency] = useState("");
  const params = useParams ();
  const clientSecret = params.clientSecret;
  useEffect (
    () => {
      //   console.log (params);
      let isMounted = true;
      const getSessionDetails = async () => {
        try {
          const response = await SessionServices.getSessionById (
            params.sessionId
          );
          //   console.log (response.data);
          setSessionDetails (response.data);
        } catch (err) {
          console.log (err);
          toast.error (getErrorMessage (err));
        }
      };
      if (params && isMounted) {
        getSessionDetails ();
      }

      return () => {
        isMounted = false;
      };
    },
    [params]
  );

  useEffect(()=>{
    if(sessionDetails?.User?.userDetails?.currency){
      let currencyItem = currencyArr.find((item)=>{
        return item.name === sessionDetails?.User?.userDetails?.currency
      });
      if(currencyItem){
        setCurrency(currencyItem)
      }
    }
  },[sessionDetails]);

  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <div>
      {clientSecret &&
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm userId={sessionDetails?.UserId} bookingId={params.bookingId} clientSecret={clientSecret} sessionDetails={sessionDetails} currency={currency} />
        </Elements>}
    </div>
  );
}

export default PayNow;
