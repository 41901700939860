import { CircularProgress, OutlinedInput,Switch,Checkbox, TextField, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { toast } from 'react-toastify';
import { useAppSelector } from '../../hooks';
import SessionServices from '../../services/sessions.services';
import { currencyArr } from '../../util/currency';
import { getErrorMessage } from '../../util/GetError';
import styles from './Sessions.module.css';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { DatePicker, TimePicker } from 'antd';
import dayjs from 'dayjs';
import { Create, HelpOutlined, PlusOneOutlined } from '@mui/icons-material';
import {Modal} from 'antd';

function EditSession() {
    const params = useParams();
    const [sessionDetails,setSessionDetails] = useState("");
    const [sessionType, setSessionType] = useState ('');
    const [title, setTitle] = useState ('');
    const [currency,setCurrency] = useState('');
    const {userInfo} = useAppSelector (state => state.auth);
    const [price,setPrice] = useState('');
    const [isCustomPrice,setIsCustomPrice] = useState(false);
    const [duration,setDuration] = useState('');
    const [isCustomDuration,setIsCustomDuration] = useState(false);
    const [webinarDate,setWebinarDate] = useState("");
    const [webinarTime,setWebinarTime] = useState("");
    const [loading,setLoading] = useState(false);
    const [description,setDescription] = useState("");
    const [isHidden,setIsHidden] = useState(false);
    const [inviteeQuestions,setInviteeQuestions] = useState([]);
    const [currentInviteeQuestion,setCurrentInviteeQuestion] = useState("");
    const [isAddingInviteeQuiz,setIsAddingInviteeQuiz] = useState(false);
    const [currentInviteeQuizRequired,setCurrentInviteeQuizRequired] = useState(false);
    const [editQuiz,setEditQuiz] = useState("");
    const [isEditQuizRequired,setIsEditQuizRequired] = useState(false);
    const [isEditQuizModal,setIsEditQuizModal] = useState(false);
    const [currentEditQuiz,setCurrentEditQuiz] = useState("");
    const navigate = useNavigate();


    const getSessionDetails = async()=>{
        try{
            const response =  await SessionServices.getSessionById(params.id);
            console.log(response.data);
            setSessionDetails(response.data);
            setTitle(response.data?.title);
            setPrice(response.data?.price);
            setDuration(response.data?.duration);
            setWebinarDate(response.data?.webinarDate);
            setWebinarTime(response.data?.webinarTime);
            setSessionType(response.data?.type);
            setDescription(response.data?.description);
            setIsHidden(response.data?.isHidden);
            if(response.data.inviteeQuestions && response.data.inviteeQuestions?.length > 0){
              // console.log(response.data?.inviteeQuestions);
              setInviteeQuestions(response.data?.inviteeQuestions);
            }
        }catch(err){
            console.log(err);
            toast.error(getErrorMessage(err));
        }
    }

    useEffect(()=>{
        let isMounted = true;
        const getSessionDetails = async()=>{
            try{
                const response =  await SessionServices.getSessionById(params.id);
                console.log(response.data);
                setSessionDetails(response.data);
                setTitle(response.data?.title);
                setPrice(response.data?.price);
                setDuration(response.data?.duration);
                setWebinarDate(response.data?.webinarDate);
                setWebinarTime(response.data?.webinarTime);
                setSessionType(response.data?.type);
                setDescription(response.data?.description);
                setIsHidden(response.data?.isHidden);
               
                if(response.data.inviteeQuestions && response.data.inviteeQuestions?.length > 0){
                  // console.log(response.data?.inviteeQuestions);
                  setInviteeQuestions(response.data?.inviteeQuestions);
                }
            }catch(err){
                console.log(err);
                toast.error(getErrorMessage(err));
            }
        }
        if(params.id && isMounted){
            getSessionDetails();
        }

        return ()=>{
            isMounted = false;
        }
    },[params]);

    useEffect(()=>{
        if(userInfo?.userDetails?.currency){
          let currencyItem = currencyArr.find((item)=>{
            return item.name === userInfo?.userDetails?.currency
          });
          if(currencyItem){
            setCurrency(currencyItem)
          }
        }
      },[userInfo])

    const handleBack = () => {
        navigate ('/dashboard/sessions');
      };

      const handlePrice = (value)=>{
        if(value==='custom'){
          setPrice('');
          setIsCustomPrice(prev=>!prev);
          return;
        }
        setIsCustomPrice(false);
        setPrice(value)
      }
    
      const handleDuration = (value)=>{
        if(value==='custom'){
          setDuration('');
          setIsCustomDuration(prev=>!prev);
          return;
        }
        setIsCustomDuration(false);
        setDuration(value)
      }

    const handleUpdateSession = async ()=>{
        // console.log("updating"); 
        setLoading(true);
        let data = {
             title:title,
             description:description,
             price:price,
             duration:duration,
             webinarDate:webinarDate,
             webinarTime:webinarTime,
             isHidden:isHidden,
             inviteeQuestions:inviteeQuestions
        }
        for(let prop in data){
            if(!data[prop]){
              delete data[prop]
            }
          }

          try{
            const response = await SessionServices.updateSessionById({id:params.id,updatedData:data})
            if(response.status===200){
              getSessionDetails();
              toast.success("Updated Session Details Successfully!");
              setLoading(false);
              
            }
           
          }catch(err){
            console.log(err);
            toast.error(getErrorMessage(err));
            setLoading(false);
      
          }
    }


    const handleDate = (date,dateString)=>{
        // console.log(date, dateString);
        setWebinarDate(dateString)
      }
    
      const handleTime = (time,timeString)=>{
        // console.log(time, timeString);
        setWebinarTime(timeString)
      }

      
  const handleAddQuestionModal = ()=>{
    setIsAddingInviteeQuiz(true);
  }

  const handleAddInviteeQuestion = ()=>{
    let data = {
      id:inviteeQuestions.length + 1,
      quiz:currentInviteeQuestion,
      isRequired:currentInviteeQuizRequired
    }
    setInviteeQuestions((prev)=>[...prev,data]);
    handleCancelInviteeQuestion();
  }
  const handleCancelInviteeQuestion = ()=>{
    setCurrentInviteeQuestion("");
    setCurrentInviteeQuizRequired(false);
    setIsAddingInviteeQuiz(false);
  }

  const handleEditQuiz = (item)=>{
    setCurrentEditQuiz(item);
    setEditQuiz(item.quiz);
    setIsEditQuizRequired(item.isRequired);
    setIsEditQuizModal(true);

  }

  const handleAddUpdatedQuiz = ()=>{
    let data = {
      ...currentEditQuiz,
      quiz:editQuiz,
      isRequired:isEditQuizRequired
    }
    let foundQuizIndex = inviteeQuestions.findIndex(item=>item.id===data.id);
    let updatedQuiz = {...inviteeQuestions[foundQuizIndex],...data};
    const newQuiz = [...inviteeQuestions];
    newQuiz[foundQuizIndex] = updatedQuiz;
    // console.log(newQuiz);
    setInviteeQuestions(newQuiz);
   handleCancelEditQuizModal();
   
  }

  const handleCancelEditQuizModal = ()=>{
    setEditQuiz("");
    setIsEditQuizRequired(false);
    setCurrentEditQuiz('');
    setIsEditQuizModal(false);
  }


  return (
    <div className={styles.createSessionWrapper}>
    <div className={styles.createSessionHeader}>
      <KeyboardBackspaceIcon
        onClick={handleBack}
        className={styles.backIcon}
      />
      <h1>Edit Session</h1>
    </div>

    <div className={styles.createSessionBody}>
      

      <div className={styles.sessionFormItem}>
        <label>
          Title
        </label>
        <OutlinedInput
          value={title}
          onChange={e => setTitle (e.target.value)}
          placeholder="e.g. Code Walkthrough"
          size="small"
        />
      </div>

      <div className={styles.sessionFormItem}>
          <label>Description</label>
          <TextField
          id="outlined-textarea"
          placeholder="Details"
          multiline
          rows={4}
          value={description}
          onChange={e => setDescription (e.target.value)}
        />
      </div>

      <div className={styles.sessionFormItem}>
          <label>Hidden</label>
          <Switch
            checked={isHidden}
            onChange={(e)=>setIsHidden(e.target.checked)}
            inputProps={{ 'aria-label': 'controlled' }}
            />
      </div>

      {(sessionType==='video' || sessionType==='webinar') &&
      <>
      <div className={styles.sessionFormItem}>
        <label>
          Call Duration (mins)
        </label>
        <div className={styles.sessionFormItem__priceWrapper}>
          <div onClick={()=>handleDuration('15')} className={`${styles.sessionFormItem__priceItem} ${duration==='15' && styles.activePrice}`}>
               15 Mins
          </div>

          <div onClick={()=>handleDuration('30')} className={`${styles.sessionFormItem__priceItem} ${duration==='30' && styles.activePrice}`}>
          30 Mins
          </div>

          <div onClick={()=>handleDuration('45')} className={`${styles.sessionFormItem__priceItem} ${duration==='45' && styles.activePrice}`}>
              45 Mins
          </div>

          <div onClick={()=>handleDuration('60')} className={`${styles.sessionFormItem__priceItem} ${duration==='60' && styles.activePrice}`}>
              60 Mins
          </div>

          <div onClick={()=>handleDuration('custom')} className={`${styles.sessionFormItem__priceItem} ${isCustomDuration && styles.activePrice}`}>
              Custom
          </div>

        </div>
       
      </div>

     { isCustomDuration && 
          <div  className={styles.sessionFormItem}>
          <label>
            Custom Duration
          </label>
          <TextField
         type="number"
          value={duration}
          onChange={e => setDuration (e.target.value)}
          placeholder="e.g. 40"
          size="small"
        />
          </div>}
      </>
        
      }

      {sessionType !=='package' &&
      <>
      <div className={styles.sessionFormItem}>
        <label>
          Price ({currency?.name})
        </label>
        <div className={styles.sessionFormItem__priceWrapper}>
          <div onClick={()=>handlePrice('100')} className={`${styles.sessionFormItem__priceItem} ${price==='100' && styles.activePrice}`}>
              {currency?.symbol} 100
          </div>

          <div onClick={()=>handlePrice('200')} className={`${styles.sessionFormItem__priceItem} ${price==='200' && styles.activePrice}`}>
              {currency?.symbol} 200
          </div>

          <div onClick={()=>handlePrice('500')} className={`${styles.sessionFormItem__priceItem} ${price==='500' && styles.activePrice}`}>
              {currency?.symbol} 500
          </div>

          <div onClick={()=>handlePrice('1000')} className={`${styles.sessionFormItem__priceItem} ${price==='1000' && styles.activePrice}`}>
              {currency?.symbol} 1000
          </div>

          <div onClick={()=>handlePrice('custom')} className={`${styles.sessionFormItem__priceItem} ${isCustomPrice && styles.activePrice}`}>
              Custom
          </div>

        </div>
       
      </div>

     {isCustomPrice && 
          <div  className={styles.sessionFormItem}>
          <label>
            Custom Price
          </label>
          <TextField
         type="number"
          value={price}
          onChange={e => setPrice (e.target.value)}
          placeholder="e.g. 350"
          size="small"
        />
          </div>}
      
      </>
        
      }

      
      <div className={styles.sessionFormItem}>
          <label><span>Invitee Questions</span> <span><Tooltip title="These questions will be asked from the client when they are booking this session"><HelpOutlined/></Tooltip></span></label>
          {inviteeQuestions && inviteeQuestions?.map((item)=>{
            return(
              <TextField style={{marginBottom:'13px'}} InputProps={{
            endAdornment: <Create style={{cursor:'pointer'}} onClick={()=>handleEditQuiz(item)} />,
          }} value={item.quiz} size="small" disabled />
            )
          })}
          <button className="secondaryBtn" onClick={handleAddQuestionModal} style={{boxShadow:'none',borderRadius:'5px',display:'flex',alignItems:'center',justifyContent:'center'}} > <PlusOneOutlined /> <span>Add Question</span></button>

        </div>

       {sessionType==='webinar' &&
        <div className={styles.sessionFormItemGroup}>
        <div className={styles.sessionFormItem}>
          <label>Date</label>
          <DatePicker defaultValue={dayjs(sessionDetails?.webinarDate, 'YYYY/MM/DD')} size='large' onChange={handleDate} />
        </div>
        <div className={styles.sessionFormItem}>
          <label>Time</label>
          <TimePicker  size='large' defaultValue={dayjs(sessionDetails?.webinarTime, 'HH:mm:ss')} onChange={handleTime} />
        </div>
        </div>
       }



      <button className='primaryBtn' disabled={!title || !sessionType || loading} style={{boxShadow:'none',borderRadius:'5px'}} onClick={handleUpdateSession}>{loading ? <CircularProgress color="inherit" /> : "Update"}</button>
    </div>

    
    <Modal title="Invitee Question"
       open={isAddingInviteeQuiz} onOk={handleAddInviteeQuestion} onCancel={handleCancelInviteeQuestion}>
      <div className={styles.sessionFormItem}>
      <label>
        Question
      </label>
      <TextField placeholder="e.g. What is the Call About?" size="small" value={currentInviteeQuestion} onChange={(e)=>setCurrentInviteeQuestion(e.target.value)} />
      </div>
     <div>
        <Checkbox checked={currentInviteeQuizRequired} onChange={(e)=>setCurrentInviteeQuizRequired(e.target.checked)} />
        <label>Required</label>
     </div>

      </Modal>

      <Modal title="Edit Invitee Question"
       open={isEditQuizModal} onOk={handleAddUpdatedQuiz} onCancel={handleCancelEditQuizModal}>
      <div className={styles.sessionFormItem}>
      <label>
        Edit Question
      </label>
      <TextField placeholder="e.g. What is the Call About?" size="small" value={editQuiz} onChange={(e)=>setEditQuiz(e.target.value)} />
      </div>
     <div>
        <Checkbox checked={isEditQuizRequired} onChange={(e)=>setIsEditQuizRequired(e.target.checked)} />
        <label>Required</label>
     </div>

      </Modal>
    
  </div>
  )
}

export default EditSession