import React, {useEffect,useState} from 'react';
import {useParams} from 'react-router';
import {toast} from 'react-toastify';
import {getErrorMessage} from '../../util/GetError';
import BookingServices from '../../services/booking.services';
import {Breadcrumb,Tag,Tooltip} from 'antd';
import {Link} from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import StyleOutlinedIcon from '@mui/icons-material/StyleOutlined';
import styles from './BookingDetailsView.module.css';
import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import AccessAlarmOutlinedIcon from '@mui/icons-material/AccessAlarmOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined';
import { Collapse } from 'antd';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import PaymentServices from '../../services/payments.services';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';

const { Panel } = Collapse;

function BookingDetailView () {
  const {id} = useParams ();
  const [bookingDetails,setBookingDetails] = useState("");
  const [paymentDetails,setPaymentDetails] = useState("");
  

  useEffect (
    () => {
      let isMounted = true;
        console.log(id);
        const weekdays = ['Sun','Mon','Tue','Wed','Thu','Fri','Sat'];
      const getSingleBooking = async () => {
        try {
          const response = await BookingServices.getSingleBooking (id);
          const response2 = await PaymentServices.getSinglePaymentByBookingId(id);
          console.log (response.data);
          console.log(response2.data);
          setPaymentDetails(response2.data);
         
          let date = new Date(response.data.date);

          let finalData = {
              ...response.data,
              dd:date.getDate(),
              day:weekdays[date.getDay()]
          }
          setBookingDetails(finalData);
        } catch (err) {
          console.log (err);
          toast.error (getErrorMessage (err));
        }
      };

      if (isMounted && id) {
        getSingleBooking ();
      }

      return () => {
        isMounted = false;
      };
    },[id]);

  const items = [
    {
      title:  <>
      <HomeIcon sx={{mr: 0.5}} fontSize="inherit" />
      <Link to="/dashboard">Dashboard</Link>
      </>,
      key: 'Dashboard',
    },
    {
        title: <>
        <StyleOutlinedIcon sx={{mr: 0.5}} fontSize="inherit"/>
        <Link to="/dashboard/bookings">
          Bookings
        </Link>
        </>,
        key:'Bookings'
    },
    {
        title: <span>Booking Details</span>,
        key:'details'
    },

  ]

  return (
    <div>
      <h1>Booking Details</h1>
      <Breadcrumb items={items} />

      <div className={styles.bookingDetails__wrapper}>
        <div className={styles.bookingDetails__col}>
            <div className={styles.bookingDetails__dateBadge}>
                <h2>{bookingDetails?.day}</h2>
                <h1>{bookingDetails?.dd}</h1>
            </div>
       </div>
       <div className={styles.bookingDetails__col2}>
        <div className={styles.bookingDetails__col2__header}>
            <h2>{bookingDetails?.Session?.title}<Tooltip title="View Session"><Link to={`/dashboard/sessions/edit/${bookingDetails?.Session?.id}`}><InsertLinkOutlinedIcon /></Link></Tooltip></h2>
            <div>
            <Tag style={{padding:'5px 10px',fontSize:'15px'}}>{bookingDetails?.Session?.type.toUpperCase()} {bookingDetails?.Session?.duration && bookingDetails?.Session?.duration + ' Mins'}</Tag>
            <Tag style={{padding:'5px 10px',fontSize:'15px'}} color={bookingDetails?.completed ? "#51e895" : 'red'}>{bookingDetails?.completed ? 'Completed' : 'Incomplete'}</Tag>
         
            </div>
        </div>
        <div className={styles.bookingDetails__col2__body}>
            <div className={styles.bookingDetails__col2Item}>
                <span className={styles.bookingDetails__col2ItemIconWrapper}><PersonOutlineOutlinedIcon className={styles.bookingDetails__col2Icon} /> <span>Booking By: </span></span>
                <span>{bookingDetails?.name}</span>
            </div>
            <div className={styles.bookingDetails__col2Item}>
                <span className={styles.bookingDetails__col2ItemIconWrapper}><EmailOutlinedIcon className={styles.bookingDetails__col2Icon} /> <span>Client Email: </span></span>
                <span>{bookingDetails?.email}</span>
            </div>
            <div className={styles.bookingDetails__col2Item}>
                <span className={styles.bookingDetails__col2ItemIconWrapper}><LocalPhoneOutlinedIcon className={styles.bookingDetails__col2Icon} /> <span>Phone Number: </span></span>
                <span>{bookingDetails?.phoneNumber}</span>
            </div>
            <div className={styles.bookingDetails__col2Item}>
                <span className={styles.bookingDetails__col2ItemIconWrapper}><CalendarTodayOutlinedIcon className={styles.bookingDetails__col2Icon} /> <span>Booking Date: </span></span>
                <span>{bookingDetails?.date?.slice(0,10)}</span>
            </div>
            <div className={styles.bookingDetails__col2Item}>
                <span className={styles.bookingDetails__col2ItemIconWrapper}><AccessAlarmOutlinedIcon className={styles.bookingDetails__col2Icon} /> <span>Booking Time: </span></span>
                <span>{bookingDetails?.time}</span>
            </div>
            <div className={styles.bookingDetails__col2Item}>
                <span className={styles.bookingDetails__col2ItemIconWrapper}><PublicOutlinedIcon className={styles.bookingDetails__col2Icon} /> <span>Timezone: </span></span>
                <span>{bookingDetails?.timezone}</span>
            </div>

            <div className={styles.bookingDetails__col2Item}>
                <span className={styles.bookingDetails__col2ItemIconWrapper}><MonetizationOnOutlinedIcon className={styles.bookingDetails__col2Icon} /> <span>Payment Status: </span></span>
                <Tag color={bookingDetails?.paymentCompleted ? 'green':'red'}>{bookingDetails?.paymentCompleted ? 'Completed' : 'Pending'}</Tag>
            </div>
            

            
        </div>
        {paymentDetails && 
        <div className={styles.bookingDetails__col2__footer}>
         <Link className="primaryBtn primaryBtn__plain" to={`/dashboard/payments/${paymentDetails?.id}`}>Payment Details</Link>
       </div>
      }
       </div>
    

      </div>
        {bookingDetails?.inviteeAnswers?.length > 0 && 
        <div className={styles.bookingAnswersWrapper}>
        <h3>Invitee Answers</h3>
        <div>
        <Collapse defaultActiveKey={['1']} style={{width:'80%'}}>
            {bookingDetails?.inviteeAnswers?.map(item=>{
                return(
                    <Panel header={item.quiz} key={item.id}>
                        <p>{item.answer}</p>
                    </Panel>
                )
            })}
        </Collapse>
        </div>
      </div>
        }
    </div>
  );
}

export default BookingDetailView;
