import axios from '../util/axios.js';
import AuthHeader from './authHeader.js';

const createNewBooking = data => {
  return axios.post ('/api/bookings/create-new-booking', data, {
    headers: AuthHeader (),
  });
};

const getAllBookings = () => {
  return axios.get ('/api/bookings/get-all-bookings', {
    headers: AuthHeader (),
  });
};

const getSingleBooking = (id) => {
  return axios.get ('/api/bookings/get-single-booking/'+id, {
    headers: AuthHeader (),
  });
};

const getUserBookings = data => {
  return axios.post ('/api/bookings/get-user-bookings', data, {
    headers: AuthHeader (),
  });
};

// const getBookingsByType = data => {
//   return axios.post ('/api/bookings/get-user-bookings-by-type', data, {
//     headers: AuthHeader (),
//   });
// };

const updateUserBookings = data => {
  return axios.post ('/api/bookings/update-user-booking', data, {
    headers: AuthHeader (),
  });
};

const deleteUserBooking = id => {
  return axios.post (
    '/api/bookings/delete-user-booking',
    {id: id},
    {
      headers: AuthHeader (),
    }
  );
};

const BookingServices = {
  createNewBooking,
  getAllBookings,
  getUserBookings,
  updateUserBookings,
  deleteUserBooking,
  // getBookingsByType,
  getSingleBooking
};

export default BookingServices;
