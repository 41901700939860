import { Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { toast } from 'react-toastify';
import UserServices from '../../services/user.services';
import { getErrorMessage } from '../../util/GetError';
import styles from  './PublicProfile.module.css';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import TwitterIcon from '@mui/icons-material/Twitter';
import SessionServices from '../../services/sessions.services';
import { Difference, DocumentScanner, East, QuestionAnswer, VideoCameraFront } from '@mui/icons-material';
import { currencyArr } from '../../util/currency';
import {Link} from 'react-router-dom';
import Footer from '../../components/Footer/Footer';
import Navbar from '../../components/Navbar/Navbar';


type userDetailsType = {
    id:string,
    username:string,
    email:string,
    userDetails:any
}

type socialLinkType = {
    type:string,
    link:string
}

type currencyType = {
    currency:string,
    name:string,
    symbol:string
}

function PublicProfile() {
    const params = useParams();
    const {username} = params;
    const [userDetails,setUserDetails] = useState<userDetailsType>();
    const [allSessions,setAllSessions] = useState([]);
    const [currency,setCurrency] = useState<currencyType>();
   
    useEffect(()=>{
        let isMounted = true;
        // console.log(username);
        document.title = `Book a Time with ${username}`;
        const getUserDetails = async ()=>{
            try{
                const response = await UserServices.getUserDetailsByUsername(username ? username : "");
                // console.log(response.data);
                setUserDetails(response.data);
                document.title = `Book a Time with ${response.data.userDetails.fullName ? response.data.userDetails.fullName :response.data.username}`;
            }catch(err){
                console.log(err);
                toast.error(getErrorMessage(err));
            }
        }

        if(isMounted && username){
            getUserDetails();
        }

        return ()=>{
            isMounted = false;
        }
    },[username]);


    useEffect(()=>{
        if(userDetails?.userDetails?.currency){
          let currencyItem = currencyArr.find((item)=>{
            return item.name === userDetails?.userDetails?.currency
          });
          if(currencyItem){
            setCurrency(currencyItem)
          }
        }
      },[userDetails])

    useEffect(()=>{
        let isMounted = true;
      
        const getUserSessions = async ()=>{
            try{
                const response = await SessionServices.getAllUserSessions(userDetails?.id);
                // console.log(response.data);
               setAllSessions(response.data);
            }catch(err){
                console.log(err);
                toast.error(getErrorMessage(err));
            }
        }

        if(isMounted && userDetails){
            getUserSessions();
        }

        return ()=>{
            isMounted = false;
        }
    },[userDetails]);



  return (
    <>
   
   <Navbar bgColor={false} textColor={"black"} />
    <div className={styles.main_wrapper}>
        <div className={styles.publicProfileWrapper}>
        <div className={styles.publicUserDetails}>
            <img src={userDetails?.userDetails?.profilePicture} alt="" />
        </div>
        <div className={styles.publicUserInfo}>
            <h1>{userDetails?.userDetails?.fullName}</h1>
            <p><b>{userDetails?.userDetails?.shortDescription}</b></p>
            <p>{userDetails?.userDetails?.about}</p>
            <div className={styles.socialLinkArr}>
                {userDetails?.userDetails?.socialLink?.map((item:socialLinkType,i:number)=>{
                  return (item.type ==='Linkedin' ? 
                   <a href={item.link} target="_blank" rel="noreferrer" key={i}>
                       <Tooltip title="Linkedin">
                        <LinkedInIcon className={styles.socialLinkIcon} />
                       </Tooltip>
                   </a>
                   :
                   item.type ==='Github' ? 
                   <a href={item.link} target="_blank" rel="noreferrer">
                       <Tooltip title="Github">
                        <GitHubIcon className={styles.socialLinkIcon} />
                       </Tooltip>
                   </a>
                   :
                   item.type ==='Twitter' ? 
                   <a href={item.link} target="_blank" rel="noreferrer">
                       <Tooltip title="Twitter">
                        <TwitterIcon className={styles.socialLinkIcon} />
                       </Tooltip>
                   </a>
                   :null)
                })}
            </div>    
        </div>
       
    </div>
     <div className={styles.skillsWrapper}>
     <h2>Skills</h2>
     <div className={styles.skillsList}>
         {userDetails?.userDetails?.skills?.map((item:string,i:number)=>{
             return(<div className={styles.skillItem} key={i}>{item}</div>)
         })}
     </div>    
    </div>

  
    </div>
      <div className={styles.sessions__wrapper}>
      {allSessions.map((item:any,i:number)=>{
          return(
              <div className={styles.sessions__card} key={i}>
                  <div className={styles.sessions__cardHeader}>
                  {item?.type==='video' || item?.type==='webinar' ? 
                  <VideoCameraFront className={styles.sessions__cardIcon} />
                  :
                  item?.type==='textQuery' ?
                  <QuestionAnswer className={`${styles.sessions__cardIcon} ${styles.sessions__cardIconBlue}`} />
                  :
                  item?.type==='document' ?
                  <DocumentScanner className={`${styles.sessions__cardIcon} ${styles.sessions__cardIconGreen}`}/>
                  :
                  item?.type==='package' ?
                  <Difference className={styles.sessions__cardIcon} />
                  : null
                 }
                 <Link to={`/${username}/${item.id}`} className={styles.sessionLink}>
                 <h3>{item?.title}</h3>
                 </Link>
                 </div>   
                 
                 <p>
                 {item?.type==='video' ? 
                     'Video Meeting'
                  :
                  item?.type==='textQuery' ?
                  'Text Query'
                  :
                  item?.type==='document' ?
                  'Document'
                  :
                  item?.type==='package' ?
                  'Package'
                  : 
                  item?.type==='webinar' ? 
                  'Webinar'
                  :null
                 }
                 </p>
                 <div className={styles.sessions__cardFooter}>
                
                    <span className={styles.sessions__cardPrice}>{currency?.symbol} {item?.price}</span>
                    <Link to={`/${username}/${item.id}`} className={styles.sessions__cardLinkIconWrapper}>
                    <East className={styles.sessions__cardLinkIcon}/>
                    </Link>
                    
                 </div>   

               </div>   
          )
      })}


     

 </div>
 <Footer />
    </>
  )
}

export default PublicProfile