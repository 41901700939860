/* eslint-disable no-extend-native */
import {CreateOutlined, EventOutlined, KeyboardBackspace, NorthEast} from '@mui/icons-material';
import React, {useState, useEffect, useRef} from 'react';
import Avatar from 'react-avatar';
import {useNavigate, useParams} from 'react-router';
import {Link} from 'react-router-dom';
import {toast} from 'react-toastify';
import SessionServices from '../../services/sessions.services';
import { currencyArr } from '../../util/currency';
import {getErrorMessage} from '../../util/GetError';
import styles from './BookingSession.module.css';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar } from "swiper";
import {MenuItem,Select, TextField} from '@mui/material';
// Import Swiper styles
import "swiper/css";
import "swiper/css/scrollbar";
import "swiper/css/navigation";
import {Select as SelectAnt} from 'antd';
import { Navigation } from "swiper";
import moment from 'moment';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import {timezones} from '../../util/timezone';
import { Input,Modal } from 'antd';
import { countries } from '../../util/countries';
import RECAPTCHA from "react-google-recaptcha"
import AuthServices from '../../services/auth.services';
import BookingServices from '../../services/booking.services';
import axios from '../../util/axios';
import PaymentServices from '../../services/payments.services';
const {Option} = SelectAnt;

const RAZORPAY_KEY_ID = 'rzp_test_G7DsD1qnqXM09V';
// const RAZORPAY_KEY_ID = 'rzp_live_U39HBuNMKnIr0n';

const loadRazorpay = (src)=>{
  return new Promise((resolve)=>{
      const script = document.createElement('script');
      script.src = src;
      
      script.onload = ()=>{
          resolve(true);
      }

      script.onerror = ()=>{
          resolve(false);
      }
      document.body.appendChild(script);
  })
}


function BookingSession () {
 
  const params = useParams ();
  const [sessionDetails, setSessionDetails] = useState ('');
  const [currency,setCurrency] = useState("");
  const [allSessions,setAllSessions] = useState([]);
  const today = new Date();
  const [currentDate,setCurrentDate] = useState(today);
  const [currentTime,setCurrentTime] = useState('');
  const [timeBlocks,setTimeBlocks] = useState([]);
  const [timezone,setTimezone] = useState("");
  const [isDetailsConfirmed,setIsDetailsConfirmed] = useState(false);
  const [clientName,setClientName] = useState("");
  const [clientEmail,setClientEmail] = useState("");
  const [inviteeAnswer,setInviteeAnswer] = useState([]);
  const [phoneNumber,setPhoneNumber] = useState("");
  const [countryCodePhone,setCountryCodePhone] = useState("+91");
  const [isOfflinePayment,setIsOfflinePayment] = useState(false);
  const [offlinePaymentMethod,setOfflinePaymentMethod] = useState("cash");
  const [offlineTransactionId,setOfflinePaymentTransactionId] = useState("");

  const captchaRef = useRef();
 
  const [loading,setLoading] = useState(false);
  const navigate = useNavigate();


  // useEffect(() => {
  //   // Create PaymentIntent as soon as the page loads
  
  // }, [params]);
  

 




  const siteKey = '6Le-6dolAAAAAANNLMFCA5Vdds1d7Vie4c1L6xRc';
  let countryCode = (
    <SelectAnt defaultValue="+91" onChange={(value)=>setCountryCodePhone(value)}>
      {countries.map((item)=>{
        return(<Option value={item.number} key={item.number}>
          <img src={item.flag} style={{width:'25px'}} alt={item.number} />
        </Option>)
      })}
    </SelectAnt>
  );


  const weekdays = ['Sun','Mon','Tue','Wed','Thu','Fri','Sat'];

  useEffect(()=>{
    const getTimeBlocks = (dateVal) => {
      // console.log(dateVal);
      dateVal = new Date(dateVal);
      const hours = [];
      for(let hour = 8; hour < 20; hour++) {
        
        // console.log(dateVal.getHours());
       if(dateVal.getHours() < hour){
        hours.push(moment({ hour }).format('hh:mm A'));
        hours.push(
            moment({
                hour,
                minute: 30
            }).format('hh:mm A')
        );
       }
    
       }
      // console.log(hours);
      setCurrentTime(hours[0]);
     setTimeBlocks(hours);
  };

 if(currentDate){
  getTimeBlocks(currentDate);
 }
  },[currentDate]);
  

  // useEffect(()=>{
  //   console.log(timeBlocks);
  // },[timeBlocks])

// console.log(getTimeBlocks());

  Date.prototype.addDays = function(days) {
    var dat = new Date(this.valueOf())
    dat.setDate(dat.getDate() + days);
    dat.setHours(8);
    dat.setMinutes(0);
    dat.setSeconds(0);
    return dat;
}

function getDates(startDate, stopDate) {
   var dateArray = [];
   var currentDate = startDate;
   while (currentDate <= stopDate) {
     dateArray.push(currentDate)
     currentDate = currentDate.addDays(1);
   }
   return dateArray;
 }

var dateArray = getDates(new Date(), (new Date()).addDays(45));

const defaultTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;


useEffect(()=>{
  if(defaultTimezone){
    
    timezones.forEach(item=>{
      if(item.utc.includes(defaultTimezone)){
        setTimezone(item.text);
      }
    })
  }
},[defaultTimezone]);

// useEffect(()=>{
//   console.log(timezone);
// },[timezone])

 
  useEffect (
    () => {
      //   console.log (params);
      let isMounted = true;
      const getSessionDetails = async () => {
        try {
          const response = await SessionServices.getSessionById (
            params.sessionId
          );
            console.log (response.data);
          setSessionDetails (response.data);
        } catch (err) {
          console.log (err);
          toast.error (getErrorMessage (err));
        }
      };
      if (params && isMounted) {
        getSessionDetails ();
      }

      return () => {
        isMounted = false;
      };
    },
    [params]
  );

  useEffect(()=>{
    if(sessionDetails?.User?.userDetails?.currency){
      let currencyItem = currencyArr.find((item)=>{
        return item.name === sessionDetails?.User?.userDetails?.currency
      });
      if(currencyItem){
        setCurrency(currencyItem)
      }
    }
  },[sessionDetails]);


  useEffect(()=>{
    let isMounted = true;
  
    const getUserSessions = async ()=>{
        try{
            const response = await SessionServices.getAllUserSessions(sessionDetails?.User?.id);
            // console.log(response.data);
           setAllSessions(response.data);
        }catch(err){
            console.log(err);
            toast.error(getErrorMessage(err));
        }
    }

    if(isMounted && sessionDetails){
        getUserSessions();
    }

    return ()=>{
        isMounted = false;
    }
},[sessionDetails]);

const handleCurrentBookingDate = (date)=>{
  setCurrentDate(date);
}

const handleCurrentBookingTime = (time)=>{
  setCurrentTime(time);
}

const handleConfirmDetails = ()=>{
  // console.log(currentDate,currentTime,timezone);
  setIsDetailsConfirmed(true);
}

const handleBackToConfirmDetails = ()=>{
  setIsDetailsConfirmed(false);
}

const handleOpenOfflineModal = ()=>{
  // console.log("hello")
  setIsOfflinePayment(true);
}

const handleCancelOfflinePayment = ()=>{
  setIsOfflinePayment(false);
}

const handleSubmitOfflinePayment = async ()=>{
  setLoading(true);
  try{
  
    let bookingData = {
      name:clientName,
      email:clientEmail,
      timezone:timezone,
      date:currentDate.toISOString(),
      time:currentTime,
      phoneNumber:countryCodePhone+" "+phoneNumber,
      inviteeAnswers:inviteeAnswer,
      SessionId:params.sessionId,
      completed:false,
      paymentCompleted:false,
      UserId:sessionDetails?.UserId
    }
    console.log(bookingData);
    const bookingResponse = await BookingServices.createNewBooking(bookingData);
    let finalPaymentData = {
      paymentMethod:offlinePaymentMethod,
      type:"offline",
      transaction_id:offlineTransactionId,
      amount:sessionDetails?.price,
      currency:currency?.currency,
      BookingId:bookingResponse.data?.id,
      UserId:sessionDetails?.UserId,
      status:false
    }
    const paymentResponse = await PaymentServices.createNewPayment(finalPaymentData);
    console.log(paymentResponse);
    setLoading(false);
    setIsOfflinePayment(false);
    toast.success("Payment Request made successfully!");
    toast.success("Your Booking will be confirmed in a short time!");
    navigate('/dashboard');
  }catch(err){
    console.log(err);
    toast.error(getErrorMessage(err));
    setLoading(false);
  }
  
}

const razorpayPaymentHandler = async (bookingId) => {
  setLoading(true);
 const res = await loadRazorpay("https://checkout.razorpay.com/v1/checkout.js");

 if(!res){
     toast.error('Razorpay Failed To Load!');
     setLoading(false);
     return;
 }
 
 try{

  const response = await axios.post('/create-razorpay-payment',{sessionId:sessionDetails.id});
  const { data } = response;
  console.log(data.id);
  const options = {
    key: RAZORPAY_KEY_ID,
    name: "Coderscolab",
    description: "Connect with your favorite mentors",
    // order_id: data.id, //uncomment this line in production
    handler: async (response) => {
      // console.log(response);
      try {
        const paymentId = response.razorpay_payment_id;
        const captureResponse = await axios.post(`/capture/${paymentId}`, {id:sessionDetails.id})
        console.log(captureResponse.data);
        let capturedData = JSON.parse(captureResponse.data)
        if(capturedData){
          let paymentData ={
            amount:parseFloat(capturedData?.amount)/100,
            currency:capturedData?.currency,
            transaction_id:capturedData?.id,
            paymentMethod:capturedData?.method,
            type:"Razorpay",
            BookingId:bookingId,
            UserId:sessionDetails?.UserId,
            status:true
           
          } 
          console.log(paymentData);
          const paymentResponse = await PaymentServices.createNewPayment(paymentData);
          console.log(paymentResponse.data);
          if(paymentResponse.status===200){
            const updatedBooking = await BookingServices.updateUserBookings({id:bookingId,updatedData:{paymentCompleted:true}});
           
            console.log(updatedBooking.data);
          }
          toast.success("Payment Successfully Completed!");
        }
        setLoading(false);

        navigate('/dashboard');
        
       } catch (err) {
         console.log(err);
       }
    },
    prefill:{
        name:clientName,
        email:clientEmail,
        contact:countryCodePhone+phoneNumber
    },
    theme: {
      color: "#101728"
    },
  };
  const rzp1 = new window.Razorpay(options);
  rzp1.open();

 }catch(err){
   setLoading(false);
   toast.error(getErrorMessage(err))
 }
};


const handleEmailChange = (value)=>{
  setClientEmail(value);
  
}

const handleInviteeAnswer = (value,item,index)=>{
  let copyArr = [...inviteeAnswer];
  copyArr[index] = {
    ...inviteeAnswer[index],
    answer:value,
    ...item
  };
  // console.log(copyArr);
  setInviteeAnswer(copyArr);

}

// console.log(sessionDetails);

const handleBookingSubmit = async ()=>{
  setLoading(true);

  try{
    const token = await captchaRef.current.executeAsync();
    captchaRef.current.reset();
    // console.log(token);
    const response = await AuthServices.verifyCaptcha(token);
    console.log(response.data);
    if(response.data==='human'){
      let data = {
        name:clientName,
        email:clientEmail,
        timezone:timezone,
        date:currentDate.toISOString(),
        time:currentTime,
        phoneNumber:countryCodePhone+" "+phoneNumber,
        inviteeAnswers:inviteeAnswer,
        SessionId:params.sessionId,
        completed:false,
        paymentCompleted:false,
        UserId:sessionDetails?.UserId
      }
      console.log(data);
      const bookingResponse = await BookingServices.createNewBooking(data);
      console.log(bookingResponse.data);
      if(currency.currency==="INR"){
        razorpayPaymentHandler(bookingResponse.data.id);
        setLoading(false);
        return;
      }
      const paymentResponse = await axios.post(`/create-payment-intent/${params.sessionId}`,data);
      console.log(paymentResponse.data);
    if(paymentResponse.data.clientSecret){
      navigate(`/payment/${sessionDetails.id}/${bookingResponse.data.id}/${paymentResponse.data.clientSecret}`);
    }
     
      
    }
    setLoading(false);
  }catch(err){
    console.log(err);
    setLoading(false);
    toast.error(getErrorMessage(err));
  }
}

// console.log(siteKey);


  if(isDetailsConfirmed){
    return(
      <div className={styles.finalForm}>
        <div className={styles.finalForm__header}>
        <KeyboardBackspace className={styles.finalForm__headerIcon} onClick={handleBackToConfirmDetails} />
        <div className={styles.finalForm__headerImg}>
        { sessionDetails?.User?.userDetails?.profilePicture ?  <Avatar size={"40"} round={true} src={sessionDetails?.User?.userDetails?.profilePicture} /> : <Avatar size={"40"} round={true} name={sessionDetails?.User?.username} /> }
            <h4>{sessionDetails?.User?.userDetails.fullName ? sessionDetails?.User?.userDetails.fullName : sessionDetails?.User?.username}</h4>
        </div>
        </div>

        <div className={styles.finalForm__body}>
            <div className={styles.finalForm__info}>
                <div>
                  <h3>{sessionDetails?.title}</h3>
                  <p>{sessionDetails?.type==='video' ? 
                     'Video Meeting'
                  :
                  sessionDetails?.type==='textQuery' ?
                  'Text Query'
                  :
                  sessionDetails?.type==='document' ?
                  'Document'
                  :
                  sessionDetails?.type==='package' ?
                  'Package'
                  : 
                  sessionDetails?.type==='webinar' ? 
                  'Webinar'
                  :null
                 } | {sessionDetails.duration && `${sessionDetails.duration}mins`} </p>
                </div>
                <div className={styles.finalForm__infoBadge}>
                  {currency?.symbol}{sessionDetails?.price}
                </div>

            </div>
            <div className={styles.finalForm__calender}>
                 <div className={styles.finalForm__calenderFirst}>
                 <EventOutlined className={styles.finalForm__calenderIcon}/>
              <span>
              {weekdays[currentDate.getDay()]} {currentDate.getDate()},{currentTime} {timezone.split(')')[0]}{')'}
              </span>
                 </div>
              <CreateOutlined style={{cursor:'pointer'}} onClick={handleBackToConfirmDetails} className={styles.finalForm__calenderIcon}/>
            </div>

            <div className={styles.finalFormItem}>
                <label><span>*</span>Your Name</label>
                <TextField required error={!clientName} helperText={!clientName && "Name is Required"} value={clientName} onChange={(e)=>setClientName(e.target.value)} placeholder="Enter your name" size="small" />
            </div>

            <div className={styles.finalFormItem}>
                <label><span>*</span>Email</label>
                <TextField required type="email" error={!clientEmail} helperText={!clientEmail && "Email is Required"} value={clientEmail} onChange={(e)=>handleEmailChange(e.target.value)} placeholder="Enter your email" size="small" />
            </div>

            {sessionDetails.inviteeQuestions && sessionDetails.inviteeQuestions?.map((item,key)=>{
              return(
                <div className={styles.finalFormItem} key={key}>
                <label>{item.isRequired && <span>*</span>}{item?.quiz}</label>
                <TextField error={!inviteeAnswer[key]?.answer && item.isRequired} helperText={(!inviteeAnswer[key]?.answer && item.isRequired) && "Field is Required"} value={inviteeAnswer[key]?.answer} onChange={(e)=>handleInviteeAnswer(e.target.value,item,key)} size="small" />
              </div>
              )
            })}

            <div className={styles.finalFormItem}>
              <label>Phone Number</label>
              <Input addonBefore={countryCode} value={phoneNumber} onChange={(e)=>setPhoneNumber(e.target.value)} />
            
            </div>

          

           


            
        </div>
        <RECAPTCHA
        sitekey={siteKey}
        ref={captchaRef}
        size="invisible"
        type="image"
         />

        <div className={styles.finalForm__footer}>
              <div className={styles.finalForm__footerText}>
                {currency?.symbol}{sessionDetails?.price}
              </div>
              <button disabled={!clientName || !clientEmail || loading} onClick={handleBookingSubmit} className='primaryBtn' style={{boxShadow:'none',borderRadius:'6px'}}>Confirm And Pay</button>
              <button disabled={!clientName || !clientEmail} className="primaryBtn" onClick={handleOpenOfflineModal} style={{boxShadow:'none',borderRadius:'6px'}}>Pay Offline</button>
        </div>


        <Modal
        title="Make Offline Payment"
        open={isOfflinePayment}
        onOk={handleSubmitOfflinePayment}
        onCancel={handleCancelOfflinePayment}
        okText="Submit"
        confirmLoading={loading}
        okButtonProps={{disabled:(!offlinePaymentMethod || !offlineTransactionId)}}
        cancelText="Cancel"
      >
      <div className={styles.modalFormItem}>
      <label>Transaction Id</label>
      <Input onChange={(e)=>setOfflinePaymentTransactionId(e.target.value)} placeholder='Enter Transaction Id' />
      </div>
      <div className={styles.modalFormItem}>
            <label>Payment Method: </label>
            <SelectAnt value={offlinePaymentMethod} style={{width:'100%'}} onChange={(value)=>setOfflinePaymentMethod(value)}>
            <Option value="cash">Cash</Option>
            <Option value="bank transfer">Bank Transfer</Option>
            <Option value="upi">UPI</Option>
          </SelectAnt>
      </div>
      </Modal>
    </div>
    )
  }

  return (
    <>
          <div className={styles.bookingWrapper}>
     <div className={styles.bookingDetails}>
     <div className={styles.bookingTopHeader}>
        <Link to={`/${params.username}`}>
          <KeyboardBackspace />
        </Link>
        <div className={styles.bookingTopImg}>
        { sessionDetails?.User?.userDetails?.profilePicture ?  <Avatar size={"40"} round={true} src={sessionDetails?.User?.userDetails?.profilePicture} /> : <Avatar size={"40"} round={true} name={sessionDetails?.User?.username} /> }
            <h2>{sessionDetails?.User?.userDetails.fullName ? sessionDetails?.User?.userDetails.fullName : sessionDetails?.User?.username}</h2>
        </div>
      </div>
      <div className={styles.bookingDetails__body}>
            <div className={styles.bookingDetails__card}>
                <h3>{sessionDetails?.title}</h3>
                <div className={styles.bookingDetails__cardBody}>
                    <h5>{currency.symbol}{sessionDetails?.price}</h5>
                    <h5> {sessionDetails?.duration} Mins</h5>
                </div>

            </div>  
            <p className={styles.bookingDetails__para}>{sessionDetails?.description}</p>
            <div className={styles.getConnected}> 
                <div className={styles.getConnected__header}>
                    <h1>Let's Connect!</h1>
                    { sessionDetails?.User?.userDetails?.profilePicture ?  <Avatar size={"80"} round={true} src={sessionDetails?.User?.userDetails?.profilePicture} /> : <Avatar size={"80"} round={true} name={sessionDetails?.User?.username} /> }
                </div>
               
                <div className={styles.other__sessions}>
                <Swiper
                  scrollbar={{
                    hide: false,
                  }}
                  modules={[Scrollbar]}
                  slidesPerView={3}
                  
               >
                {allSessions.map((item,key)=>{
                     return(
                      <SwiperSlide key={key}>
                        <div className={styles.other__sessionsCard}>
                        <h3>{item?.title}</h3>
                      
                        <p>{item.duration && `${item.duration}mins |`} {item?.type==='video' ? 
                     'Video Meeting'
                  :
                  item?.type==='textQuery' ?
                  'Text Query'
                  :
                  item?.type==='document' ?
                  'Document'
                  :
                  item?.type==='package' ?
                  'Package'
                  : 
                  item?.type==='webinar' ? 
                  'Webinar'
                  :null
                 }</p>
                        <div className={styles.other__sessionsCardFooter}> 
                            <Link to={`/${params.username}/${item.id}`} target="_blank">
                            <NorthEast className={styles.other__sessionsCardIcon} />
                            </Link>
                        </div>
                    </div>
                    </SwiperSlide>
                      )
                    })}
                 
                
                </Swiper>
                  
                </div>
            </div>
      </div>
     </div>
     <div className={styles.bookingSetup}>
        <div className={styles.bookingSetupHeader}>
            <h2>When Should we meet?</h2>
        </div>
        <div className={styles.bookingSetup__body}>
            <h3>Pick a Date</h3>
            <Swiper 
                   style={{paddingLeft:'2rem',boxSizing:'borderBox'}} slidesPerView={5} navigation={true} modules={[Navigation]}>
                  {dateArray.map((date,key)=>{
                    return(
                      <SwiperSlide key={key}>
                        <div onClick={()=>handleCurrentBookingDate(date)} className={`${styles.bookingDateItem} ${currentDate.getDate()===date.getDate() && styles.activeDateItem}`}>
                            <p>{weekdays[date.getDay()]}</p>
                            <h3>{date.getDate()}</h3>
                        </div>
                      </SwiperSlide>
                    )
                  })}
            </Swiper>
          
           <div className={styles.bookTimeArea}>
           <h3>Pick a Time</h3>
            <div className={styles.bookingTimeWrapper} >
                  {
                  timeBlocks.length > 0  ? timeBlocks.map((item,key)=>{
                    return(<div key={key} onClick={()=>handleCurrentBookingTime(item)} className={`${styles.bookingTimeItem} ${currentTime===item && styles.activeTimeItem}`}>
                      {item}
                    </div>)
                  }) : <div className={styles.noSlots}>
                  <SentimentVeryDissatisfiedIcon className={styles.noSlotsIcon}/>
                  <p>Sorry, No Time Slots Available Today!</p>
                  </div> 
                  }
            </div>
           </div>
           <div className={styles.settings__item}>
                  <label>
                  <LocationOnIcon />
                  <b>Timezone</b></label>
                  <div>
                  <Select
                value={timezone}
                onChange={(e)=>setTimezone(e.target.value)}
                size="small"
                fullWidth
              >
                
               {timezones.map((item,key)=>{
                 return(<MenuItem key={key} value={item.text}>{item.text}({item.abbr})</MenuItem>)
               })} 
               
              </Select>
                  </div>
           </div>
           <button onClick={handleConfirmDetails} className={'primaryBtn'} disabled={timeBlocks.length===0 || !currentTime || !currentDate || !timezone} style={{boxShadow:'none',borderRadius:'5px',width:'90%'}}>Confirm Details</button>
        </div>
      
     </div>
   
    </div>
    
    </>
  );
}

export default BookingSession;
