import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router';
import {getErrorMessage} from '../../util/GetError';
import {toast} from 'react-toastify';
import PaymentServices from '../../services/payments.services';
import {Breadcrumb,Tag} from 'antd';
import {Link} from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import styles from './PaymentDetails.module.css';
import { currencyArr } from '../../util/currency';

function PaymentDetails () {
  const params = useParams ();
  const [paymentDetails, setPaymentDetails] = useState ([]);

  const {id} = params;

  useEffect (
    () => {
      let isMounted = true;
      const getSinglePaymentDetails = async () => {
        try {
          const response = await PaymentServices.getSinglePaymentById (id);
          console.log (response.data);
          const currency = currencyArr.find(item=>item.currency.toLowerCase()===response.data?.currency?.toLowerCase());
          const date = new Date(response.data.createdAt);
          const dd = date.getDate();
          const mm = date.getMonth() + 1;
          const yy = date.getFullYear();
          const hh = date.getHours();
          const min = date.getMinutes();
          const ss = date.getSeconds();
          let paymentDate = yy+'-'+mm+'-'+dd;
          let paymentTime = hh + ':' + min + ':' + ss;
          let finalData = {
              ...response.data,
              amountWithCurrency: currency?.symbol + response.data.amount,
              paymentDate:paymentDate,
              paymentTime:paymentTime
              
          }
          setPaymentDetails (finalData);
          
        } catch (err) {
          console.log (err);
          toast.error (getErrorMessage (err));
        }
      };
      if (isMounted && id) {
        getSinglePaymentDetails ();
      }
      return () => {
        isMounted = false;
      };
    },
    [id]
  );

  const items = [
    {
      title:  <>
      <HomeIcon sx={{mr: 0.5}} fontSize="inherit" />
      <Link to="/dashboard">Dashboard</Link>
      </>,
      key: 'Dashboard',
    },
    {
        title: <>
        <MonetizationOnOutlinedIcon sx={{mr: 0.5}} fontSize="inherit"/>
        <Link to="/dashboard/payments">
          All Payments
        </Link>
        </>,
        key:'Payments'
    },
    {
        title: <span>Payment Details</span>,
        key:'details'
    },

  ]

  return (<div className={styles.paymentDetailsWrapper}>
    <h1>Payment Details</h1>
   <Breadcrumb items={items} />
   <div className={styles.paymentDetails__card}>
       <div className={styles.paymentDetails__cardLeft}>
       <h3>{paymentDetails?.amountWithCurrency}</h3>
       </div>
       <div className={styles.paymentDetails__cardRight}>
        <div className={styles.paymentDetails__item}>
            <b>Transaction Id: </b>
            <span>{paymentDetails?.transaction_id}</span>
        </div>
        <div className={styles.paymentDetails__item}>
            <b>Payment Date: </b>
            <span>{paymentDetails?.paymentDate}</span>
        </div>
        <div className={styles.paymentDetails__item}>
            <b>Payment Time: </b>
            <span>{paymentDetails?.paymentTime}</span>
        </div>
        <div className={styles.paymentDetails__item}>
            <b>Payment Method: </b>
            <Tag>{paymentDetails?.paymentMethod}</Tag>
        </div>
        <div className={styles.paymentDetails__item}>
            <b>Type: </b>
            <span>{paymentDetails?.type}</span>
        </div>
       </div>
       <div className={styles.cardBtnWrapper}>
           <Link to={`/dashboard/bookings/${paymentDetails?.BookingId}`} 
           className={"primaryBtn primaryBtn__plain"}>Booking Details</Link>
       </div>
   </div>
  </div>);
}

export default PaymentDetails;
