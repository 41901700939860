
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import SideNav from '../../components/SideNav/SideNav';
import { DashboardCustomizeOutlined, SupervisedUserCircleOutlined, VerifiedUserOutlined } from '@mui/icons-material';
import { Outlet } from 'react-router';
import DashboardNavbar from '../../components/Navbar/DashboardNavbar';
import Avatar from 'react-avatar';
import { useAppSelector } from '../../hooks';
import styles from './Dashboard.module.css';
import LaunchIcon from '@mui/icons-material/Launch';
import StyleOutlinedIcon from '@mui/icons-material/StyleOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import AvatarImg from '../../components/Avatar/Avatar';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
const drawerWidth = 240;



export default function ResponsiveDrawer() {
  const linkArr = [
    {label:"Dashboard",href:"/dashboard",icon:<DashboardCustomizeOutlined/>,isBoth:true},
  {label:"Profile",href:"/dashboard/profile",icon:<VerifiedUserOutlined/>,isBoth:true},
  {label:"Sessions",href:"/dashboard/sessions",icon:<BadgeOutlinedIcon/>},
  {label:"Bookings",href:"/dashboard/bookings",icon:<StyleOutlinedIcon/>},
  {label:"Payments",href:"/dashboard/payments",icon:<MonetizationOnOutlinedIcon/>},
  {label:"My Blogs",href:"/dashboard/blog",icon:<ArticleOutlinedIcon/>},
  {label:"All Users",href:"/dashboard/admin/all-users",icon:<SupervisedUserCircleOutlined/>,isAdmin:true},
  {label:"All Bookings",href:"/dashboard/admin/all-bookings",icon:<StyleOutlinedIcon/>,isAdmin:true},
  {label:"All Sessions",href:"/dashboard/admin/all-sessions",icon:<BadgeOutlinedIcon/>,isAdmin:true},
  {label:"All Payments",href:"/dashboard/admin/all-payments",icon:<MonetizationOnOutlinedIcon/>,isAdmin:true},
]
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const {userInfo} = useAppSelector (state => state.auth);
  const [profilePicture,setProfilePicture] = React.useState("");

  React.useEffect(()=>{
    setProfilePicture(userInfo?.userDetails?.profilePicture)
  },[userInfo])
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  // console.log(userInfo?.userDetails?.profilePicture)
  const handleOpenProfile = ()=>{
    window.open(`${window.location.origin}/${userInfo?.username}`,'_blank')
  
  }
  const drawer = (
    <div>
      <Toolbar>
        <div className={styles.userInfoSide}>
        {profilePicture 
          ?  <AvatarImg size="50" src={profilePicture} />
          : <Avatar
              size="50"
              round={true}
              name={userInfo?.username}
            />}
            <span className={styles.userInfoSide__text}>{userInfo?.username}</span>
            <LaunchIcon className={styles.userInfoSideIcon} onClick={handleOpenProfile}/>
        </div>
      </Toolbar>  
      <Divider />
    
    
      <SideNav linkArr={linkArr}/>
    </div>
  );


  return (
    <Box sx={{ display: 'flex' }}>
    <CssBaseline />
    <AppBar
      sx={{
        width: { sm: `calc(100% - ${drawerWidth}px)` },
        ml: { sm: `${drawerWidth}px` },
        backgroundColor:'white',
        boxShadow:'0px 0.233px 5px lightgray'
      }}
    >
      <Toolbar>
       
        <DashboardNavbar/>
      </Toolbar>
    </AppBar>
    <Box
      component="nav"
      sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      aria-label="mailbox folders"
    >
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Drawer
    
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>
    <Box
      component="main"
      sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
    >
       <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { sm: 'none' },mt:5 }}
        >
         <DashboardCustomizeOutlined/>
        </IconButton>
      <Toolbar />
        <Outlet />
    </Box>
  </Box>
  );
}