// type ErrorWithMessage = {
//     message: string
//   }
  
//   function isErrorWithMessage(error: unknown): error is ErrorWithMessage {
//     return (
//       typeof error === 'object' &&
//       error !== null &&
//       'message' in error &&
//       typeof (error as Record<string, unknown>).message === 'string'
//     )
//   }
  
//   function toErrorWithMessage(maybeError: unknown): ErrorWithMessage {
//     if (isErrorWithMessage(maybeError)) return maybeError
  
//     try {
//       return new Error(JSON.stringify(maybeError))
//     } catch {
//       // fallback in case there's an error stringifying the maybeError
//       // like with circular references for example.
//       return new Error(String(maybeError))
//     }
//   }
  
 export  function getErrorMessage(error: any) {
 const msg =
   (error.response &&
     error.response.data &&
     error.response.data.message) ||
   error.message ||
   error.toString();
  //  console.log(error);
  //  console.log(msg);
    return msg
  }