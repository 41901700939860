import {useState, useEffect} from 'react';
import BookingServices from '../../services/booking.services';
import {getErrorMessage} from '../../util/GetError';
import {toast} from 'react-toastify';
import {useAppSelector} from '../../hooks';
import styles from './UserBookings.module.css';
import {Box} from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import { Tag, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { timezones } from '../../util/timezone';
import empty from '../../assets/empty-box.png';
function TabPanel (props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index &&
        <Box sx={{p: 3}}>
          {children}
        </Box>}
    </div>
  );
}

function a11yProps (index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function UserBookings () {
  const {userInfo} = useAppSelector (state => state.auth);
  const [upcomingBookings, setUpcomingBookings] = useState ([]);
  const [completedBookings, setCompletedBookings] = useState ([]);
  const [filteredUpcomingBookings,setFilteredUpcomingBookings] = useState([]);
  const [filteredCompletedBookings,setFilteredCompletedBookings] = useState([]);
  const [currentTab, setCurrentTab] = useState ('all');
  const [value, setValue] = useState (0);

  useEffect (
    () => {
      let isMounted = true;

      const getUserBookings = async () => {
        try {
          const response = await BookingServices.getUserBookings ({
            userId: userInfo.id,
          });
          console.log (response.data);
          let upcoming = [];
          let completed = [];
          response.data.forEach (item => {
            let today = new Date ();
            let date = new Date (item.date);
            let isExpired = today.getDate () > date.getDate ();
            // console.log (today.getDate ());
            // console.log (date.getDate ());
            // console.log (isExpired);
            if (item.completed || isExpired) {
              
              let finalTimezone = timezones.find(timezoneVal=>{return timezoneVal.text===item.timezone})
                // console.log(finalTimezone);
              let finalData= {
                ...item,
                timezone:finalTimezone.abbr
              }
              completed.push(finalData);
            } else {
                let finalTimezone = timezones.find(timezoneVal=>{return timezoneVal.text===item.timezone})
                // console.log(finalTimezone);
              let finalData= {
                ...item,
                timezone:finalTimezone.abbr
              }
              upcoming.push (finalData);
            }
          });
          setUpcomingBookings (upcoming);
          setCompletedBookings (completed);
        } catch (err) {
          console.log (err);
          toast.error (getErrorMessage (err));
        }
      };

      if (isMounted && userInfo) {
        getUserBookings ();
      }

      return () => {
        isMounted = false;
      };
    },
    [userInfo]
  );

  const getUserBookings = async () => {
    try {
      const response = await BookingServices.getUserBookings ({
        userId: userInfo.id,
      });
      console.log (response.data);
      let upcoming = [];
      let completed = [];
      response.data.forEach (item => {
        let today = new Date ();
        let date = new Date (item.date);
        let isExpired = today.getDate () > date.getDate ();
        // console.log (today.getDate ());
        // console.log (date.getDate ());
        // console.log (isExpired);
        if (item.completed || isExpired) {
          
          let finalTimezone = timezones.find(timezoneVal=>{return timezoneVal.text===item.timezone})
            // console.log(finalTimezone);
          let finalData= {
            ...item,
            timezone:finalTimezone.abbr
          }
          completed.push(finalData);
        } else {
            let finalTimezone = timezones.find(timezoneVal=>{return timezoneVal.text===item.timezone})
            // console.log(finalTimezone);
          let finalData= {
            ...item,
            timezone:finalTimezone.abbr
          }
          upcoming.push (finalData);
        }
      });
      setUpcomingBookings (upcoming);
      setCompletedBookings (completed);
    
    } catch (err) {
      console.log (err);
      toast.error (getErrorMessage (err));
    }
  };

  const handleCurrentTab = (mainTab=value,tab) => {
    setCurrentTab (tab);
    if(mainTab===null){
        mainTab = value;
    }
   
    console.log(tab);
    console.log(mainTab);
    if(tab==='all'){
      getUserBookings();
      return;
    }
    if(mainTab===0){
        console.log('filtering upcoming');
        let filteredData = upcomingBookings.filter((item)=>{
            console.log(item?.Session?.type.toLowerCase(),tab.toLowerCase());
            // console.log(tab);
            return item?.Session?.type.toLowerCase()?.match(tab.toLowerCase());
        });
        console.log(filteredData);
        setFilteredUpcomingBookings(filteredData);
    }else if(mainTab===1){
        console.log('filtering completed');
        let filteredData = completedBookings.filter((item)=>{
            return item?.Session?.type.toLowerCase()?.match(tab.toLowerCase());
        });
        console.log(filteredData);
        setFilteredCompletedBookings(filteredData);
    }
  };

  const handleMainTabChange = (event, newValue) => {
    setValue (newValue);
    handleCurrentTab(newValue,currentTab);
   
  };

  const handleBookingCompleted = async (item,completed)=>{
      const data = {
          id:item.id,
          updatedData:{
              completed:completed
          }
      }
      try{
        const updatedBooking = await BookingServices.updateUserBookings(data);
        console.log(updatedBooking.data);
        getUserBookings();
        toast.success(`Booking is marked as ${completed ? 'Completed' : 'Upcoming'}!`);

      }catch(err){
          console.log(err);
          toast.error(getErrorMessage(err));
      }
  }

  return (
    <div>
      <h1> Bookings </h1>
      <div className={styles.tabsWrapper}>
        <div
          className={`${styles.tab} ${currentTab === 'all' && styles.activeTab}`}
          onClick={() => handleCurrentTab (null,'all')}
        >
          All
        </div>

        <div
          className={`${styles.tab} ${currentTab === 'video' && styles.activeTab}`}
          onClick={() => handleCurrentTab (null,'video')}
        >
          1:1 Call
        </div>
        <div
          className={`${styles.tab} ${currentTab === 'package' && styles.activeTab}`}
          onClick={() => handleCurrentTab (null,'package')}
        >
          Package
        </div>
        <div
          className={`${styles.tab} ${currentTab === 'webinar' && styles.activeTab}`}
          onClick={() => handleCurrentTab (null,'webinar')}
        >
          Webinar
        </div>
        <div
          className={`${styles.tab} ${currentTab === 'textQuery' && styles.activeTab}`}
          onClick={() => handleCurrentTab (null,'textQuery')}
        >
          Text Query
        </div>
        <div
          className={`${styles.tab} ${currentTab === 'document' && styles.activeTab}`}
          onClick={() => handleCurrentTab (null,'document')}
        >
          Document
        </div>
      </div>
      <br />
      <Tabs
        value={value}
        onChange={handleMainTabChange}
        textColor="inherit"
        indicatorColor="secondary"
        aria-label="basic tabs example"
      >
        <Tab label="Upcoming" {...a11yProps (0)} />
        <Tab label="Completed" {...a11yProps (1)} />

      </Tabs>

      <TabPanel value={value} index={0}>
        <h3>Upcoming Bookings</h3>
        { currentTab==="all" ?  (upcomingBookings.length > 0 ? upcomingBookings.map((item)=>{
            return(<div className={styles.card} key={item.id}>
               <div className={styles.cardHeader}>
               <h3>{item?.Session?.title}</h3>
               <div className={styles.cardHeader__right}>
               <div className={styles.timeBadge}>
                {item?.date.slice(0,10)} at {item?.time} {item?.timezone}
                </div>
                <Tag style={{marginLeft:'10px'}}>{item?.Session?.type}</Tag>
               </div>
               </div>
               
                <div className={styles.cardFooter}>
                <Tooltip title={item.completed ? "Mark as Upcoming": "Mark as Completed"}> <button onClick={()=>handleBookingCompleted(item,item.completed ? false : true)} className={styles.cardBtn}>{item.completed ? 'Upcoming' : 'Completed'}</button></Tooltip>
                    <Tooltip title="View Details">
                <Link to={`/dashboard/bookings/${item?.id}`}>
                    <ArrowForwardOutlinedIcon className={styles.cardIcon} />
                </Link>
                </Tooltip>
                </div>
               
            </div>)
        }):
        <div className={styles.notAvailable}>
            <img src={empty} alt="..." />
            <h4>Not Available</h4> 
        </div>)
        :
        filteredUpcomingBookings.length > 0 ? filteredUpcomingBookings.map((item)=>{
            return(<div className={styles.card} key={item.id}>
               <div className={styles.cardHeader}>
               <h3>{item?.Session?.title}</h3>
               <div className={styles.cardHeader__right}>
               <div className={styles.timeBadge}>
                {item?.date.slice(0,10)} at {item?.time} {item?.timezone}
                </div>
                <Tag style={{marginLeft:'10px'}}>{item?.Session?.type}</Tag>
               </div>
               </div>
               
                <div className={styles.cardFooter}>
                <Tooltip title={item.completed ? "Mark as Upcoming": "Mark as Completed"}> <button onClick={()=>handleBookingCompleted(item,item.completed ? false : true)} className={styles.cardBtn}>{item.completed ? 'Upcoming' : 'Completed'}</button></Tooltip>
                    <Tooltip title="View Details">
                <Link to={`/dashboard/bookings/${item?.id}`}>
                    <ArrowForwardOutlinedIcon className={styles.cardIcon} />
                </Link>
                </Tooltip>
                </div>
               
            </div>)
        }):
        <div className={styles.notAvailable}>
            <img src={empty} alt="..." />
            <h4>Not Available</h4> 
        </div>
        }
      </TabPanel>
      <TabPanel value={value} index={1}>
        <h3>Completed Bookings</h3>
        { currentTab==="all" ?  (completedBookings.length > 0 ? completedBookings.map((item)=>{
            return(<div className={styles.card} key={item.id}>
               <div className={styles.cardHeader}>
               <h3>{item?.Session?.title}</h3>
               <div className={styles.cardHeader__right}>
               <div className={styles.timeBadge}>
                {item?.date.slice(0,10)} at {item?.time} {item?.timezone}
                </div>
                <Tag style={{marginLeft:'10px'}}>{item?.Session?.type}</Tag>
               </div>
               </div>
               
                <div className={styles.cardFooter}>
                <Tooltip title={item.completed ? "Mark as Upcoming": "Mark as Completed"}> <button onClick={()=>handleBookingCompleted(item,item.completed ? false : true)} className={styles.cardBtn}>{item.completed ? 'Upcoming' : 'Completed'}</button></Tooltip>
                    <Tooltip title="View Details">
                <Link to={`/dashboard/bookings/${item?.id}`}>
                    <ArrowForwardOutlinedIcon className={styles.cardIcon} />
                </Link>
                </Tooltip>
                </div>
               
            </div>)
        }):
        <div className={styles.notAvailable}>
            <img src={empty} alt="..." />
            <h4>Not Available</h4> 
        </div>)
        :
        filteredCompletedBookings.length > 0 ? filteredCompletedBookings.map((item)=>{
            return(<div className={styles.card} key={item.id}>
               <div className={styles.cardHeader}>
               <h3>{item?.Session?.title}</h3>
               <div className={styles.cardHeader__right}>
               <div className={styles.timeBadge}>
                {item?.date.slice(0,10)} at {item?.time} {item?.timezone}
                </div>
                <Tag style={{marginLeft:'10px'}}>{item?.Session?.type}</Tag>
               </div>
               </div>
               
                <div className={styles.cardFooter}>
                <Tooltip title={item.completed ? "Mark as Upcoming": "Mark as Completed"}> <button onClick={()=>handleBookingCompleted(item,item.completed ? false : true)} className={styles.cardBtn}>{item.completed ? 'Upcoming' : 'Completed'}</button></Tooltip>
                    <Tooltip title="View Details">
                <Link to={`/dashboard/bookings/${item?.id}`}>
                    <ArrowForwardOutlinedIcon className={styles.cardIcon} />
                </Link>
                </Tooltip>
                </div>
               
            </div>)
        }):
        <div className={styles.notAvailable}>
            <img src={empty} alt="..." />
            <h4>Not Available</h4> 
        </div>
        }
      </TabPanel>
    </div>
  );
}

export default UserBookings;
