import React from 'react';
import {Link} from 'react-router-dom';
import styles from './Footer.module.css';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import HomeIcon from '@mui/icons-material/Home';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import {Typography} from '@mui/material';

function Footer () {
  return (
    <div className={`${styles.footer__wrapper}`}>

      <div className={styles.footer__about}>
        <Typography
          variant="h5"
          noWrap
          component="a"
          href="/"
          sx={{
            fontFamily: 'monospace',
            fontWeight: 800,
            color: 'white',
            textDecoration: 'none',
            fontSize: '2.5rem',
            marginTop: '-10px',
          }}
        >

          <span>CODERSCOLAB<span className={styles.logo__dot}>.</span></span>
        </Typography>
        <p>
          Your trusted source to find highly-vetted mentors & industry professionals to move your career ahead.
          {' '}
        </p>
        <div className={styles.footer__social}>
          <a href="/" target={'_blank'} rel="noreferrer">
            <FacebookIcon
              className={styles.footer__icon}
              sx={{
                fill: 'url(#linearColors)',
              }}
            />
          </a>
          <a href="/" target={'_blank'} rel="noreferrer">
            <InstagramIcon
              className={styles.footer__icon}
              sx={{
                fill: 'url(#linearColors)',
              }}
            />
          </a>
          <a href="/" target={'_blank'} rel="noreferrer">
            <LinkedInIcon
              className={styles.footer__icon}
              sx={{
                fill: 'url(#linearColors)',
              }}
            />
          </a>
        </div>
      </div>

      <div className={styles.footer__links}>
        <h3>Links</h3>
        <Link to="/">Home</Link>

        <Link to="/mentors">Mentors</Link>

      </div>

      <div className={styles.footer__links}>
        <h3>Products</h3>
        <a href="/">Codersarts</a>
        <a href="/">Sofstack</a>

      </div>

      <div className={styles.footer__links}>
        <h3>Company</h3>
        <Link to="/about">About</Link>
        <Link to="/privacy-policy">Privacy Policy</Link>
        <Link to="/terms-of-services">Terms of Services</Link>

      </div>

      <div className={styles.footer__links}>
        <h3>Contact</h3>

        <div className={styles.footer__contact}>
          <HomeIcon
            className={styles.footer__icon}
            sx={{
              fill: 'url(#linearColors)',
            }}
          />
          <span>G-69, Sector 63, Noida - 201301, India</span>
        </div>

        <div className={styles.footer__contact}>
          <EmailIcon
            className={styles.footer__icon}
            sx={{
              fill: 'url(#linearColors)',
            }}
          />
          <span>contact@coderscolab.com</span>
        </div>

        <div className={styles.footer__contact}>
          <LocalPhoneIcon
            className={styles.footer__icon}
            sx={{
              fill: 'url(#linearColors)',
            }}
          />
          <span>(+91) 011-408-45766</span>
        </div>

      </div>
    </div>
  );
}

export default Footer;
