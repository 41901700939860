import axios from '../util/axios.js';
type loginDataType = {
  email:string,
  password:string
}

type registerDataType = {
  username:string,
  email:string,
  password:string
}

type loginWithTokenType = {
  token:string
}

const login = (data:loginDataType) => {
  return axios.post ('/api/user/login', data)
};

const register = (data:registerDataType) => {
  return axios.post ('/api/user/sign-up', data);
};

const loginWithGoogle = ()=>{
  return axios.get ('/google');
}

const loginWithToken = (data:loginWithTokenType)=>{
  return axios.post ('/api/user/login-with-token', data)
}

const getDetailsWithToken = (data:loginWithTokenType)=>{
  return axios.post ('/api/user/get-details-with-token', data)
}

const verifyCaptcha = (token:string)=>{
  return axios.post('/verify-captcha', {token:token})
}


const AuthServices = {
  login,
  register,
  loginWithGoogle,
  loginWithToken,
  getDetailsWithToken,
  verifyCaptcha
};

export default AuthServices;
