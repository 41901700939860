import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import {Link} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../hooks';
import {authApi, useGetUserDetailsQuery} from '../../services/authApi';
import {setCredentials, logout} from '../../redux/features/authSlice';
import {useNavigate} from 'react-router';
import {Tooltip} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styles from './Navbar.module.css';
import Avatar from 'react-avatar';
import LanguageIcon from '@mui/icons-material/Language';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined';
import DesignServicesOutlinedIcon from '@mui/icons-material/DesignServicesOutlined';
const pages = [
  {label: 'All Mentors', href: '/mentors'},
  {label: 'Blog', href: '/blog'},
  {label: 'Contact', href: '/contact'},
  {label: 'About', href: '/about'},
];

const settings = ['Dashboard', 'Logout'];

function ResponsiveAppBar({bgColor,textColor}) {
  const [anchorElNav, setAnchorElNav] = React.useState (null);
  const {userInfo} = useAppSelector (state => state.auth);
  const [anchorElUser, setAnchorElUser] = React.useState (null);
  const [profilePicture,setProfilePicture] = React.useState("");
  const [isLogOutClick,setIsLogoutClick] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElSmall, setAnchorElSmall] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickSmall = (event) => {
    setAnchorElSmall(event.currentTarget);
  };
  const handleCloseSmall = () => {
    setAnchorElSmall(null);
  };



  React.useEffect(()=>{
    setProfilePicture(userInfo?.userDetails?.profilePicture)
  },[userInfo]);

  const navigate = useNavigate ();
  const dispatch = useAppDispatch ();
  // automatically authenticate user if token is found
  const {data} = useGetUserDetailsQuery ('userDetails', {
    // perform a refetch every 15mins
    pollingInterval: 900000,
  });
  // console.log (data);



  React.useEffect (
    () => {
      // console.log(data);
      if (data) dispatch (setCredentials (data));
    },
    [data, dispatch]
  );

  React.useEffect(()=>{
    if(userInfo===null && isLogOutClick){

      navigate('/login');
    }
  },[userInfo,isLogOutClick,navigate])

 

  const handleOpenNavMenu = event => {
    setAnchorElNav (event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav (null);
  };

  const handleOpenUserMenu = event => {
    setAnchorElUser (event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser (null);
  };

  const handleSettingClicked = setting => {
    // console.log(setting);
    if (setting === 'Logout') {
      dispatch(authApi.util.resetApiState());
     
      dispatch (logout ());
      setIsLogoutClick(true);
      
    } else if (setting === 'Dashboard') {
      navigate ('/dashboard');
    }
  };

  const handleChangeNav = (page)=>{
    // console.log(page);
    navigate(page.href);
    setAnchorElUser (null);
    
  }

  

  const handleMentorLinkClick = (category)=>{
    console.log(category);
    setAnchorEl(null);
    setAnchorElSmall(null);
    navigate('/mentors/filters/' + category);
  }

  return (
    <AppBar
      position="fixed"
      sx={{
        background: bgColor ? 'var(--bgColor)' : 'transparent',
        boxShadow: bgColor ? '0 0.234px 6px black' : 'none',
        color:textColor ? textColor : 'white'
      }}
    >
      <Container maxWidth="xl" >
        <Toolbar disableGutters sx={{justifyContent:'space-between'}}>
          {/* <AdbIcon
           
          /> */}
        

          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: {xs: 'none', md: 'flex'},
              fontFamily: 'monospace',
              fontWeight: 700,
              color:textColor ? textColor : 'white',
              textDecoration: 'none',
              fontSize:'1.5rem',
              marginTop:'-10px'

            }}
          >
           
            <span>CODERSCOLAB<span className={styles.logo__dot}>.</span></span>
          </Typography>

          <Box 
           sx={{
                display: {xs: 'none', md: 'block'},
              }}
          >
          <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        style={{ color:textColor ? textColor : 'white'}}
      >
        <span>Collab For</span>
        <ExpandMoreIcon />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={()=>handleMentorLinkClick("web-development")}>
          <span className={styles.dropdown__iconWrapper}>
            <LanguageIcon className={styles.dropdown__icon} />
          </span>
          <span className={styles.dropdown__link}>Web Development</span>
        </MenuItem>
        <MenuItem onClick={()=>handleMentorLinkClick("data-science")}>
        <span className={styles.dropdown__iconWrapper} style={{backgroundColor:'rgb(255, 195, 240)'}}>
            <InsertChartOutlinedIcon className={styles.dropdown__icon} />
          </span>
          <span className={styles.dropdown__link}>Data Science</span>
        </MenuItem>
        <MenuItem onClick={()=>handleMentorLinkClick("machine-learning")}>
        <span className={styles.dropdown__iconWrapper} style={{backgroundColor:'rgb(188, 236, 255)'}}>
            <SmartToyOutlinedIcon className={styles.dropdown__icon} />
          </span>
          <span className={styles.dropdown__link}>Machine Learning</span>
        </MenuItem>
        <MenuItem onClick={()=>handleMentorLinkClick("design")}>
        <span className={styles.dropdown__iconWrapper} style={{backgroundColor:'rgb(245, 236, 157)'}}>
            <DesignServicesOutlinedIcon className={styles.dropdown__icon} />
          </span>
          <span className={styles.dropdown__link}>UI/UX Design</span>
        </MenuItem>
       
      </Menu>
    </div>

          </Box>
         
          <Box sx={{display: {xs: 'flex', md: 'none'}}}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color={textColor ? textColor : 'white'}
            >
              <MenuIcon style={{ color:textColor ? textColor : 'white'}} />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean (anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: {xs: 'block', md: 'none'},
              }}
            >
                 <div>
      <Button
        id="basic-button"
        
        onClick={handleClickSmall}
        style={{ color:'black'}}
      >
        <span>Collab For</span>
        <ExpandMoreIcon />
      </Button>
      <Menu
        id="basic-menu-small"
        anchorEl={anchorElSmall}
        open={Boolean(anchorElSmall)}
        onClose={handleCloseSmall}
        anchorOrigin={
          {vertical:'top',horizontal:'right'}
        }
        
      >
     <MenuItem onClick={()=>handleMentorLinkClick("web-development")}>
          <span className={styles.dropdown__iconWrapper}>
            <LanguageIcon className={styles.dropdown__icon} />
          </span>
          <span className={styles.dropdown__link}>Web Development</span>
        </MenuItem>
        <MenuItem onClick={()=>handleMentorLinkClick("data-science")}>
        <span className={styles.dropdown__iconWrapper} style={{backgroundColor:'rgb(255, 195, 240)'}}>
            <InsertChartOutlinedIcon className={styles.dropdown__icon} />
          </span>
          <span className={styles.dropdown__link}>Data Science</span>
        </MenuItem>
        <MenuItem onClick={()=>handleMentorLinkClick("machine-learning")}>
        <span className={styles.dropdown__iconWrapper} style={{backgroundColor:'rgb(188, 236, 255)'}}>
            <SmartToyOutlinedIcon className={styles.dropdown__icon} />
          </span>
          <span className={styles.dropdown__link}>Machine Learning</span>
        </MenuItem>
        <MenuItem onClick={()=>handleMentorLinkClick("design")}>
        <span className={styles.dropdown__iconWrapper} style={{backgroundColor:'rgb(245, 236, 157)'}}>
            <DesignServicesOutlinedIcon className={styles.dropdown__icon} />
          </span>
          <span className={styles.dropdown__link}>UI/UX Design</span>
        </MenuItem>
      </Menu>
    </div>
              {pages.map (page => (
                <MenuItem key={page.label} onClick={handleCloseNavMenu}>
                  <Link
                    to={page.href}
                    style={{textDecoration: 'none', color: 'black'}}
                  >
                    {page.label}
                  </Link>
                </MenuItem>
              ))}
              {userInfo
                ? <MenuItem onClick={() => handleSettingClicked ('Logout')}>
                    Logout
                  </MenuItem>
                : <div>
                    <MenuItem onClick={handleCloseNavMenu}>
                      <Link
                        to={'/register'}
                        style={{
                          textDecoration: 'none',
                          color: 'var(--primary)',
                        }}
                      >
                        Register
                      </Link>

                    </MenuItem>
                    <MenuItem onClick={handleCloseNavMenu}>
                      <Link
                        to={'/login'}
                        style={{
                          textDecoration: 'none',
                          color: 'var(--primary)',
                        }}
                      >
                        Login
                      </Link>

                    </MenuItem>
                  </div>}

            </Menu>
          </Box>
         
         <Box  sx={{ display: {xs: 'flex', md: 'none'},}}>
         <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
             
              fontFamily: 'monospace',
              fontWeight: 700,
              color: textColor ? textColor : 'white',
              textDecoration: 'none',
              fontSize:'1.5rem',
              marginTop:'-10px',
              justifyContent:'center'
             

            }}
          >
           
            <span>CODERSCOLAB<span className={styles.logo__dot}>.</span></span>
          </Typography>
         </Box>
          <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>
            {pages.map (page => (
              <Button
                key={page.label}
                onClick={()=>handleChangeNav(page)}
                sx={{my: 2,  color:textColor ? textColor : 'white', display: 'block'}}
              >
                {page.label}
              </Button>
            ))}

          </Box>

          {userInfo
            ? <Box sx={{flexGrow: 0}}>
                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>
                    {profilePicture
                      ? <Avatar size="50" round={true} src={profilePicture} />
                      : <Avatar
                          size="50"
                          round={true}
                          name={userInfo.username}
                        />}

                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{mt: '45px'}}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean (anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {settings.map (setting => (
                    <MenuItem
                      key={setting}
                      onClick={() => handleSettingClicked (setting)}
                    >
                      <Typography textAlign="center">{setting}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            : <Box
                sx={{
                  display: {xs: 'none', md: 'flex', alignItems: 'center'},
                  flexGrow: 0,
                }}
              >
                <Link to="/register"  className={`${bgColor ? 'primaryBtn' : 'primaryBtn primaryBtn__plain'}`}>
                  Register
                </Link>
                <Link
                  to={'/login'}
                  style={{textDecoration: 'none', color: 'var(--primary)'}}
                >
                  Login
                </Link>
              </Box>}

        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
