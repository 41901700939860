import React, {useState,useEffect} from 'react';
import Navbar from '../../components/Navbar/Navbar';
import UserServices from '../../services/user.services';
import {getErrorMessage} from '../../util/GetError';
import {toast} from 'react-toastify';
import styles from './Mentors.module.css';
import {Input} from 'antd';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import BallotOutlinedIcon from '@mui/icons-material/BallotOutlined';
import CodeOutlinedIcon from '@mui/icons-material/CodeOutlined';
import Avatar from 'react-avatar';
import {Link} from 'react-router-dom';
import notfound from '../../assets/no-results.png';
import ManageHistoryOutlinedIcon from '@mui/icons-material/ManageHistoryOutlined';
import BrushOutlinedIcon from '@mui/icons-material/BrushOutlined';
import PieChartOutlinedIcon from '@mui/icons-material/PieChartOutlined';
import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined';
import {useLocation} from 'react-router';
import Footer from '../../components/Footer/Footer';

function Mentors () {
  const [colorChange, setColorchange] = useState (false);
  const [currentCategory, setCurrentCategory] = useState ('all');
  const [allMentors,setAllMentors] = useState([]);
  const [filteredMentors,setFilteredMentors] = useState([]);
  const location = useLocation();

  const changeNavbarColor = () => {
    if (window.scrollY >= 80) {
      setColorchange (true);
    } else {
      setColorchange (false);
    }
  };
  window.addEventListener ('scroll', changeNavbarColor);

  const handleCurrentCategory = value => {
    setCurrentCategory (value);
    let query = value;
    let filteredList = allMentors.filter((item)=>{
        return item?.userDomain?.toLowerCase()?.match(query.toLowerCase())
    });
    // console.log("list: ",filteredList);
    setFilteredMentors(filteredList);
    if(query==='all'){
        setFilteredMentors(allMentors);
    }
  };

  useEffect(()=>{
    let isMounted = true;

    const getAllMentors = async ()=>{
        try{
            const response = await UserServices.getAllMentors();
            console.log(response.data);
            setAllMentors(response.data);
            setFilteredMentors(response.data);
        }catch(err){
            console.log(err);
            toast.error(getErrorMessage(err));
        }
    }

    if(isMounted){
        getAllMentors();
    }

    return ()=>{
        isMounted = false;
    }
  },[]);


  useEffect(()=>{
    const query = location?.search?.split("=")[1];
    if(query){
      let filteredList = allMentors.filter((item)=>{
        return item?.userDomain?.toLowerCase()?.match(query.toLowerCase())
      });
    // console.log("list: ",filteredList);
    setFilteredMentors(filteredList);
    setCurrentCategory(query);
    
    }
   },[location,allMentors])


  const handleSearchChange = (e)=>{
    let query = e.target.value;
    let filteredList = allMentors.filter((item)=>{
        return item?.username?.toLowerCase()?.match(query.toLowerCase()) || item?.userDetails?.skills?.some(x => x.toLowerCase().match(query.toLowerCase()))
    });
    // console.log("list: ",filteredList);
    setFilteredMentors(filteredList);

    if(!query){
        setFilteredMentors(allMentors);
    }
  }

  return (
    <div>
      <Navbar bgColor={colorChange} />
      <div className={styles.mentor__wrapper}>
        <h2>Find Your Perfect Mentor</h2>
        <div className={styles.mentor__searchWrapper}>
          <Input
            className={styles.mentor__searchInput}
            size="large"
            onChange={handleSearchChange}
            placeholder="Search by Skill, Domain or Name"
            prefix={<SearchOutlinedIcon />}
          />
        </div>
        <div className={styles.mentor__categoryWrapper}>
          <div
            onClick={() => handleCurrentCategory ('all')}
            className={`${styles.mentor__category} ${currentCategory === 'all' && styles.mentor__categoryActive}`}
          >
            <BallotOutlinedIcon className={styles.mentor__categoryIcon} />
            <div>
              All
            </div>
          </div>
          <div
            onClick={() => handleCurrentCategory ('web-development')}
            className={`${styles.mentor__category} ${currentCategory === 'web-development' && styles.mentor__categoryActive}`}
          >
            <CodeOutlinedIcon className={styles.mentor__categoryIcon} />
            <div>
              Web Development
            </div>
          </div>
          <div
            onClick={() => handleCurrentCategory ('management')}
            className={`${styles.mentor__category} ${currentCategory === 'management' && styles.mentor__categoryActive}`}
          >
            <ManageHistoryOutlinedIcon className={styles.mentor__categoryIcon} />
            <div>
              Management
            </div>
          </div>

          <div
            onClick={() => handleCurrentCategory ('design')}
            className={`${styles.mentor__category} ${currentCategory === 'design' && styles.mentor__categoryActive}`}
          >
            <BrushOutlinedIcon className={styles.mentor__categoryIcon} />
            <div>
              UI/UX Design
            </div>
          </div>

          <div
            onClick={() => handleCurrentCategory ('data-science')}
            className={`${styles.mentor__category} ${currentCategory === 'data-science' && styles.mentor__categoryActive}`}
          >
            <PieChartOutlinedIcon className={styles.mentor__categoryIcon} />
            <div>
              Data Science
            </div>
          </div>

          <div
            onClick={() => handleCurrentCategory ('machine-learning')}
            className={`${styles.mentor__category} ${currentCategory === 'machine-learning' && styles.mentor__categoryActive}`}
          >
            <SmartToyOutlinedIcon className={styles.mentor__categoryIcon} />
            <div>
              Machine Learning
            </div>
          </div>
        </div>


        <div className={styles.mentor__cards}>
           {filteredMentors.length > 0 ? filteredMentors.map((item,key)=>{
               return(
                <div key={key} className={styles.mentor__card}>
                    <div className={styles.mentor__cardImg}>
                        {item?.userDetails?.profilePicture ? 
                        <Avatar src={item?.userDetails?.profilePicture} size="150" round={true} />
                        :
                        <Avatar name={item?.username} size="150" round={true} />
                        }
                        <h4>{item?.userDetails?.shortDescription}</h4>
                        <div className={styles.domainLabel}>{item?.userDomain}</div>
                    </div>
                    <div className={styles.mentor__cardInfo}>
                        <h2>{item?.userDetails?.fullName ? item?.userDetails?.fullName : item?.username }</h2>
                       
                        <p>{item?.userDetails?.about}</p>
                        <div className={styles.skillsWrapper}>
                            {item?.userDetails?.skills?.map((skill,i)=>{
                                return(<div key={i} className={styles.skillBadge}>{skill}</div>)
                            })}
                            
                        </div>
                    </div>
                    <div className={styles.mentor__cardAction}>
                        <Link to={`/${item?.username}`} className="primaryBtn primaryBtn__plain">View Details</Link>
                    </div>
                </div>
               )
           }):
           <div className={styles.notFound}>
                <img src={notfound} alt="..." />
                <h3>Not Found</h3>
           </div>
           }
        </div>

      </div>

      

      <Footer />

    </div>
  );
}

export default Mentors;
